import { API_USER } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { formatCompanyIdQueryString } from "../../helpers/formatCompanyId";
import { User, UserRoles, Users, getUserStatusId, mapUsers } from "../../types/User";

export const getUsers = (id: string | number): Promise<Users> => {
  return fetch(`${API_USER}/GetAll?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapUsers(res.payload));
}

export const getAdminUsers = (id: string | number): Promise<Users> => {
  return fetch(`${API_USER}/GetAllAdmins?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapUsers(res.payload));
}

export const getUser = (id: string | number, companyId: string | number): Promise<User> => {
  return fetch(`${API_USER}/Get?userId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapUsers([res.payload])[0]);
}

export const inviteUser = (id: string | number, email: string, userRoles?: UserRoles): Promise<User> => {
  return fetch(`${API_USER}/Invite`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      companyId: id,
      email: email,
      roleIds: userRoles?.map((item) => item.id)
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => checkErrors(res))
    .then((res) => mapUsers([res.payload])[0]);
}

export const updateUser = (id: string | number, user: User): Promise<User> => {
  return fetch(`${API_USER}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      id: user.id,
      status: getUserStatusId(user.status),
      roleIds: user.roles?.map((item) => item.id),
      companyId: id
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapUsers([res.payload])[0]);
}

export const getUserRoles = (): Promise<UserRoles> => {
  return fetch(`${API_USER}/GetAvailableRoles`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as UserRoles);
}

export const removeInvite = (id: string | number, companyId: string | number): Promise<boolean> => {
  return fetch(`${API_USER}/RevokeInvite?inviteId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success);
}

export const resendInvite = (id: string | number, companyId: string | number): Promise<boolean> => {
  return fetch(`${API_USER}/ResendInvite?inviteId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success);
}