import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { CreateNetZeroPlan, NetZeroPlan, NetZeroPlanAction, StatusType, statusType } from "../types/NetZeroPlan";
import { formatDate } from "../helpers/dateFormatter";
import { Action, Actions, AddAction, mapActions } from "../types/Action";
import { API_NET_ZERO_PLAN } from "../constants";
import { mapUsers } from "../types/User";
import { mapTasks } from "../types/Task";
import { dedupeFetch } from "./dedupeFetch";
import { formatCompanyIdQueryString } from "../helpers/formatCompanyId";
import { mapLocations } from "../types/Location";

export const mapNetZeroPlanAction = (data: {[key: string]: unknown}[], netZeroPlanActionId?: number): NetZeroPlanAction => {
  const netZeroPlanAction = data.find((item) => item.id === netZeroPlanActionId);
  const item = netZeroPlanAction && netZeroPlanActionId ? netZeroPlanAction : data[0];

  return {
    ...item,
    status: statusType[(item?.status || 0) as number - 1] as StatusType,
    targetCompletionDateFormatString: item?.targetCompletionDate ? formatDate(item.targetCompletionDate as string) : null,
    startDateFormatString: item?.startDate ? formatDate(item.startDate as string) : null,
    tagString: item?.tags,
    tags: item?.tags ? (item.tags as string).split("|") : null,
    user: item?.user ? mapUsers([item.user as any])[0] : null,
    tasks: item?.tasks ? mapTasks(item.tasks as any) : undefined,
    location: item.location ? mapLocations([item.location])[0] : undefined
  } as unknown as NetZeroPlanAction;
}

export const mapNetZeroPlan = (data: {[key: string]: unknown}, userId?: number): NetZeroPlan => {
  return {
    ...data,
    alignedToSBT: new Date(data["targetDate"] as string).getUTCFullYear() <= 2050 &&
      data["scope1ReductionTargetInterim"] as number >= 0.5 &&
      data["scope2ReductionTargetInterim"] as number >= 0.5 &&
      data["scope3ReductionTargetInterim"] as number >= 0.4,
    actions: data?.actions ? mapActions(data.actions as {[key: string]: unknown}[], userId) : undefined
  } as unknown as NetZeroPlan;
}

export const getNetZeroPlanId = (id: number | string): Promise<number> => {
  return dedupeFetch(`${API_NET_ZERO_PLAN}/GetNetZeroPlanIdByCompanyId?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then((res) => res.payload as number);
}

export const getNetZeroPlan = (id: number, companyId: string | number, userId?: number): Promise<NetZeroPlan> => {
  return dedupeFetch(`${API_NET_ZERO_PLAN}/GetNetZeroPlan?netZeroPlanId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then((res) => {
      return mapNetZeroPlan(res.payload, userId) as NetZeroPlan;
    });
}

export const addAction = (data: AddAction): Promise<NetZeroPlanAction> => {
  const {
    id,
    netZeroPlanId,
    uptake,
    completionDate,
    startDate,
    priority,
    estimatedCost,
    estimatedROI,
    estimatedEffort,
    estimatedReductionPercent,
    companyId,
    locations = []
  } = data;
  return fetch(`${API_NET_ZERO_PLAN}/AddActionToNetZeroPlan`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      netZeroPlanId,
      companyId,
      actionId: id,
      estimatedUptakePercent: uptake || 0,
      targetCompletionDate: completionDate,
      priority: priority || 0,
      startDate,
      estimatedCost,
      estimatedEffort,
      estimatedROI,
      estimatedReductionPercent,
      locationIds: locations.map((item) => item.id)
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      res.payload.status = statusType[res.payload.status - 1];
      return res.payload as NetZeroPlanAction;
    });
}

export const updateAction = (
  data: NetZeroPlanAction,
  companyId: string | number,
): Promise<NetZeroPlanAction> => {
  return fetch(`${API_NET_ZERO_PLAN}/UpdateAction`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      netZeroPlanId: data.netZeroPlanId,
      netZeroPlanActionId: data.id,
      status: statusType.findIndex((value) => value === data.status) + 1,
      targetCompletionDate: data.targetCompletionDate,
      actualCompletionDate: data.actualCompletionDate,
      actualUptakePercent: data.actualUptakePercent,
      estimatedUptakePercent: data.estimatedUptakePercent,
      userId: data.userId,
      tags: data.tags?.join("|"),
      startDate: data.startDate,
      notes: data.notes,
      estimatedCost: data.estimatedCost,
      estimatedROI: data.estimatedROI,
      estimatedEffort: data.estimatedEffort,
      estimatedReductionPercent: data.estimatedReductionPercent,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapNetZeroPlanAction([res.payload]);
    });
}

export const removeAction = (id: number, netZeroPlanId: number, companyId: string | number): Promise<number> => {
  return fetch(`${API_NET_ZERO_PLAN}/RemoveActionFromNetZeroPlan`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      netZeroPlanActionId: id,
      netZeroPlanId,
      companyId,
    }),
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then((res) => res.payload as number);
}

export const getAction = (id: number, netZeroPlanId: number, companyId: string | number, netZeroPlanActionId?: number): Promise<Action> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetActionInNetZeroPlan?netZeroPlanId=${netZeroPlanId}&actionId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then((res) => {
      return mapActions([{
        ...res.payload,
        netZeroPlanActionId
      }])[0] as Action;
    });
}

export const getAllActions = (id: number, companyId: string | number): Promise<Actions> => {
    return fetch(`${API_NET_ZERO_PLAN}/GetActionsInNetZeroPlan?netZeroPlanId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then((res) => {
      return mapActions(res.payload) as Actions;
    });
}

export const createNetZeroPlan = (data: CreateNetZeroPlan): Promise<NetZeroPlan> => {
  const {
    id,
    targetDate,
    interimTargetYear = 2030,
    scope1InterimReductionTarget = 0.5,
    scope2InterimReductionTarget = 0.5,
    scope3InterimReductionTarget = 0.4
  } = data;
  return fetch(`${API_NET_ZERO_PLAN}/CreateNetZeroPlan`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      targetDate: `${targetDate}-12-31`,
      interimTargetYear: interimTargetYear,
      companyId: id,
      scope1ReductionTargetInterim: scope1InterimReductionTarget,
      scope2ReductionTargetInterim: scope2InterimReductionTarget,
      scope3ReductionTargetInterim: scope3InterimReductionTarget
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapNetZeroPlan(res.payload) as NetZeroPlan;
    });
}

export const updateNetZeroPlan = (
  id: number,
  targetDate: number,
  companyId?: string,
): Promise<NetZeroPlan> => {
  return fetch(`${API_NET_ZERO_PLAN}/UpdateNetZeroPlan`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      netZeroPlanId: id,
      targetDate: `${targetDate}-12-31`,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapNetZeroPlan(res.payload) as NetZeroPlan;
    });
}

export const updateBaselineYear = (
  baselineYear: number,
  companyId?: number
): Promise<NetZeroPlan> => {
  return fetch(`${API_NET_ZERO_PLAN}/UpdateBaselineYear`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      baselineYear,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapNetZeroPlan(res.payload) as NetZeroPlan;
    });
}
