import './styles.scss';

import React, { FocusEventHandler } from 'react';
import { ChartArrowDownIcon, ChartArrowUpIcon, Checkbox, CircularProgress, IconButton, InfoIcon, Label, NotesIcon, NumberInput, TickIcon } from '@flotilla/component-library';

import { Activity, ActivityDataItem, ActivityNote } from '../../../../../../../types/DataPeriod';
import ScopeTag from '../../../../../../../components/ScopeTag';
import ActivityTooltip from './ActivityTooltip';
import { DATA_CHANGE_PERIOD_COMPARISON_TOOLTIP } from '../../../../../../../assets/content/Tooltips';
import NoteModal from './NoteModal';
import { useAppDispatch } from '../../../../../../../helpers/hooks';
import { addModal } from '../../../../../../../reducers/modal';
import { useCompanyId, useUserAccess } from '../../../../../../../context';
import numberFormatter from '../../../../../../../helpers/numberFormatter';
import { Measure } from '../../../../../../../types/Measure';
// import SupplierActivity from './SupplierActivity';

interface DataPeriodActivityProps {
  className?: string;
  tabName?: string;
  data: Activity;
  onChange?: (data: ActivityDataItem[]) => void;
  onChangeNote?: (data: ActivityNote) => void;
  submitAttempt?: boolean;
  disabled?: boolean;
};

const DataPeriodActivity: React.FC<DataPeriodActivityProps> = ({
  className,
  tabName = '',
  data: activity,
  onChange = () => {},
  onChangeNote = () => {},
  submitAttempt = false,
  disabled = false
}) => {
  const { userAccess } = useUserAccess();
  const { DATA_EDIT } = userAccess;
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const {
    activityNote,
    name,
    shortName,
    mandatory,
    scopeCategory,
    data,
  } = activity;

  const handleOnBlur = (dataItem: ActivityDataItem): FocusEventHandler<HTMLInputElement> => {
    return (event) => {
      const initialValue = event.currentTarget.value === '' ? undefined : Number(event.currentTarget.value.split(',').join(''));
      const value = typeof initialValue === "number" && !isNaN(initialValue) ? initialValue : undefined;
      if (dataItem.value !== value) {
        onChange([{
          ...dataItem,
          value: value,
        }]);
      }
    }
  }

  const handleOnClickNote = (note: ActivityNote) => {
    return () => {
      dispatch(addModal({
        id: 'note-modal',
        children: <NoteModal note={note} activity={activity} onChangeNote={onChangeNote} disabled={disabled} companyId={companyId}/>
      }));
    }
  }

  const handleOnClickEstimate = () => {
    const isEstimation = !data.some(item => item.isEstimation);
    onChange(data.map(d => {
      return {
        ...d,
        isEstimation
      }
    }));
  }

  // const handleOnSupplierActivityChange = (updatedItem: SupplierMeasureDataItem, activityData: ActivityDataItem) => {
  //   onChange({
  //     ...activityData,
  //     supplierMeasureData: [...(activityData.supplierMeasureData || []).filter((item) => item.supplierActivityId !== updatedItem.supplierActivityId), updatedItem]
  //   });
  // }

  const getMeasureProps = (measure: Measure) => {
    switch (true) {
      case measure.name.toLowerCase().includes('mile'):
        return {
          appendChild: 'Miles'
        };
      case measure.name.toLowerCase().includes('kg'):
        return {
          appendChild: 'kg'
        };
      case measure.name.toLowerCase().includes('tonnes/km'):
          return {
            appendChild: 'tkm'
          };
      case measure.name.toLowerCase().includes('tonnes'):
        return {
          appendChild: 'Tonnes'
        };
      case measure.name.toLowerCase().includes('kwh'):
        return {
          appendChild: 'kWh'
        };
      case measure.name.toLowerCase().includes('m3'):
        return {
          appendChild: 'm3'
        };
      case measure.name.toLowerCase().includes('m2'):
        return {
          appendChild: 'm2',
          readOnly: true,
          disabled: true
        };
      case measure.name.toLowerCase().includes('ltr'):
        return {
          appendChild: 'ltr'
        };
      case measure.name.toLowerCase().includes('capacity'):
        return {
          prependChild: '# desks',
          readOnly: true,
          disabled: true
        };
      case measure.name.toLowerCase().includes('nights'):
        return {
          appendChild: 'Nights'
        };
    }

    return {
      prependChild: measure.name
    }
  }

  const getDataQualityScore = () => {
    const isEstimation = data.some(item => item.isEstimation);
    let total = 0;
    let score = 0;

    data.forEach((item) => {
      const measureScore = item.measure.score;
      total += measureScore;
      const value = Number(item.value ?? -1);
      if ((data.length > 1 && data.findIndex((i) => Number(i.value || 0) > 0) >= 0) ? value > 0 : value >= 0) {
        score += measureScore;
      }
    });

    // This is added to cover off the addition of supplier data collection.
    if (total < 1 && tabName.toLowerCase() === 'supply chain') {
      total += 1;
    }
  
    return numberFormatter((score / total) * 100 * (isEstimation ? 0.75 : 1));
  }

  return (
    <section id="data-period-activity" className={className}>
      <header>
        <section id="name-container">
          <label title={shortName ?? name}>
            <span id="name">{shortName ?? name}</span>
            {mandatory && (
              <span className="required-asterisk">*</span>
            )}
          </label>
          {scopeCategory?.scopeId > 0 && <ScopeTag children={`Scope ${scopeCategory.scopeId}`} size="small" />}
        </section>
        <ActivityTooltip activity={activity}/>
      </header>
      <main>
        <section className="measure-inputs"> 
          {/* { data.supplierActivities.length > 0 && (
            <p>Supplier non-specific</p>
          )} */}
          { data?.map((activityDataItem) => {
            const measureProps = getMeasureProps(activityDataItem.measure);
            const percentPrev = activityDataItem.value !== undefined ? (activityDataItem.value || 0) / (activityDataItem.lastSubmittedValue || 0) * 100 : 100;
            return (
              <NumberInput
                key={activityDataItem.factorValueId}
                className="number-input"
                id={String(activityDataItem.factorValueId)}
                value={activityDataItem.value}
                onBlur={handleOnBlur(activityDataItem)}
                financial
                decimal
                disabled={disabled || !DATA_EDIT || (activityDataItem.submittedAt && (!activityDataItem.wasEstimation || activityDataItem.isEstimation) ? true : false)}
                min={activityDataItem.minValue ?? 0}
                max={activityDataItem.maxValue}
                required={submitAttempt && mandatory && !data.some((item) => item.value !== undefined && Number(item.value ?? -1) >= (activityDataItem.minValue ?? 0))}
                aria-required={mandatory}
                inputTooltip={activityDataItem.lastSubmittedValue ? {
                  tooltipHeader: activityDataItem.value === undefined ? 'Previous activity' : percentPrev > 120 ? 'Increase in activity' : percentPrev < 20 ? 'Decrease in activity' : 'Comparable activity',
                  tooltipText: `${DATA_CHANGE_PERIOD_COMPARISON_TOOLTIP.replace('%%VALUE%%', `${measureProps.prependChild || ''}${activityDataItem.lastSubmittedValue.toLocaleString()}${measureProps.appendChild || ''}`)} ${activityDataItem.value !== undefined ? `The value entered currently is ${Math.round(percentPrev)}% of the last value submitted.` : ''}`,
                  alignment: 'right',
                  position: 'top',
                  icon: percentPrev > 120 ? ChartArrowUpIcon : percentPrev < 20 ? ChartArrowDownIcon : InfoIcon
                } : undefined}
                {...measureProps}
              />
            )
          })}
        </section>
        <Checkbox
          className="estimate"
          onClick={handleOnClickEstimate}
          aria-label='Estimate flag'
          checked={data.some(item => item.isEstimation)} 
          disabled={disabled || !DATA_EDIT || (!data.some(item => item.isEstimation) && data.every(item => item.submittedAt))}
        />
        <span title="For further guidance on improving data quality, please contact your Flotilla consultant.">
          <CircularProgress
            className="activity-score"
            score={getDataQualityScore()}
            greenScore={75}
            amberScore={50}
          />
        </span>
        <IconButton
          id="note-modal-button"
          className={(!disabled || (activityNote.notes || activityNote.documentationUrl || activityNote.file)) ? 'note-modal-button' : 'note-modal-button--disabled'}
          icon={<NotesIcon variant='dark'/>}
          onClick={handleOnClickNote(activityNote)}
          variant='ghost'
          title="Add notes/evidence"
          small
          badge={activityNote.notes || activityNote.documentationUrl || activityNote.file ? {
            position: 'bottom',
            alignment: 'right',
            children: <TickIcon small variant='light' />,
            id: 'note-badge'
          } : undefined}
        />
      </main>
    </section>
  );
}

export default DataPeriodActivity;
