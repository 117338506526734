import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Button, Modal, TextInput, useAlert } from '@flotilla/component-library';

import { createCompanyQuestions } from '../../../../../api/surveys';
import { Company, CompanyQuestion, FullCompanyQuestion } from '../../../../../types/Company';

interface CreateQuestionModalProps {
  onClose: () => void;
  onSuccess: (companyQuestion: FullCompanyQuestion) => void;
  company: Company;
};

const CreateQuestionModal: React.FC<CreateQuestionModalProps> = ({
  onClose,
  onSuccess,
  company
}) => {
  const { addAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [questionText, setQuestionText] = useState("");
  const [answersList, setAnswersList] = useState("");

  useEffect(() => {
    company && setIsLoading(false)
  }, [company]);

  const handleSubmit = () => {
    createCompanyQuestions(
      company.identityCompanyId,
      [{ question: questionText, answers: answersList } as CompanyQuestion])
      .then((result) => {
        if(result) {
          onSuccess(result);
        } else {
          throw "Result returned empty.";
        }
      })
      .catch(() => {
        addAlert({
          id: `Create company question`,
          type: 'error',
          title: 'Error creating Company Question',
          content: 'There seems to be an error when creating the Company Question, please try again. If the problem continues to happen, please contact us.',
        });
      });
  };

  return (
    <Modal
      id="create-question-modal"
      className=""
      onClose={() => onClose()}
    >
      <h4>Create a new Company Question</h4>

      <main>
        <TextInput
          id="question-input"
          label="Question"
          aria-label="Question"
          value={questionText}
          onChange={(event) => setQuestionText(event?.currentTarget.value)}
          type='text'
          maxLength={450}
        />
  
        <TextInput
          id="answers-input"
          label="Answers (comma separated)"
          aria-label="Answers (comma separated)"
          value={answersList}
          onChange={(event) => setAnswersList(event?.currentTarget.value)}
          type='text'
          maxLength={450}
        />
      </main>

      <section className="button-container">
        <Button
          variant="ghost"
          onClick={() => onClose()}
          isLoading={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!questionText.length || !answersList.length}
        >
          Add
        </Button>
      </section>
    </Modal>
  );
}

export default CreateQuestionModal;
