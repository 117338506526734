import { useCallback, useEffect, useState } from "react";

import { Companies } from "../../types/Company";
import { getAllCompanies } from "../../api/companies";

const useChildCompanies = (id: string | number) => {
  const [companyId, setCompanyId] = useState<string | number>(id);
  const [loading, setLoading] = useState(true);
  const [childCompanies, setChildCompanies] = useState<Companies>();

  const fetchApi = useCallback(async () => {
    if (companyId) {
      setLoading(true);
      getAllCompanies(companyId)
        .then((res) => {
          res && setChildCompanies(res.sort((a, b) => a.name > b.name ? 1 : -1));
        })
        .finally(() => setLoading(false));
    }
  }, [companyId]);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    setCompanyId(id);
  }, [id]);

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {

    childCompanies,
    refresh,
    loading
  };
}

export default useChildCompanies;
