export const USER_ROLE_CUSTOMER_READ = "CUSTOMERREAD";
export const USER_ROLE_CUSTOMER_CREATE = "CUSTOMERCREATE";
export const USER_ROLE_CUSTOMER_UPDATE = "CUSTOMERUPDATE";
export const USER_ROLE_CUSTOMER_DELETE = "CUSTOMERDELETE";
export const USER_ROLE_CUSTOMER_WRITE = "CUSTOMERWRITE";
export const USER_ROLE_CUSTOMER_ADMIN = "CUSTOMERADMIN";
export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_SUPER_ADMIN = "SUPERADMIN";
export const COMPANY_TYPE_STANDARD = "STANDARD";
export const COMPANY_TYPE_LICENSOR = "LICENSOR";
export const COMPANY_TYPE_LICENSOR_ACCESS = "LICENSORREADONLY";
export const COMPANY_TYPE_PE = "PRIVATEEQUITY";
export const PRODUCT_TYPE_STANDARD = "STANDARD";
export const PRODUCT_TYPE_CARBON_REDUCTION_PLAN = "CARBONREDUCTIONPLAN";

const CUSTOMER_READ_ACCESS = {
  READ: true,
};

const CUSTOMER_CREATE_ACCESS = {
  CREATE: true,
};

const CUSTOMER_UPDATE_ACCESS = {
  DATA_EDIT: true,
  LOCATION_EDIT: true,
  SURVEY_EDIT: true,
  REPORT_EDIT: true,
  NET_ZERO_PLAN_EDIT: true,
  EDIT: true,
};

const CUSTOMER_DELETE_ACCESS = {
  DELETE: true,
};

const CUSTOMER_ADMIN_ACCESS = {
  ...CUSTOMER_READ_ACCESS,
  ...CUSTOMER_CREATE_ACCESS,
  ...CUSTOMER_UPDATE_ACCESS,
  ...CUSTOMER_DELETE_ACCESS,
  CUSTOMER_ADMIN: true,
  MANAGE_USER: true
};

const ADMIN_ACCESS = {
  ...CUSTOMER_ADMIN_ACCESS,
  ADMIN: true,
  INVITE_USER: true,
  REPORT_CREATE: true,
  DATA_EDIT: true,
  LOCATION_EDIT: true,
  SURVEY_EDIT: true,
  REPORT_EDIT: true,
  NET_ZERO_PLAN_EDIT: true,
  CAN_RECOMMEND: true,
  MANAGE_USER: true,
  CREATE_COMPANIES: true,
  EDIT_COMPANIES: true,
  CREATE_QUESTIONS: true,
  MANAGE_CUSTOM_ACTIONS: true,
  REOPEN_DATAPERIOD: true,
};

const SUPER_ADMIN_ACCESS = {
  ...ADMIN_ACCESS,
  SUPER_ADMIN: true,
};

const STANDARD_ACCESS = {
  STANDARD: true,
};

const LICENSOR_ACCESS = {
  LICENSOR: true,
  CAN_RECOMMEND: true,
  MANAGE_USER: true,
  INVITE_USER: true,
  CREATE_COMPANIES: true,
  EDIT_COMPANIES: true,
  REPORT_CREATE: true,
  DATA_EDIT: true,
  LOCATION_EDIT: true,
  SURVEY_EDIT: true,
  REPORT_EDIT: true,
  NET_ZERO_PLAN_EDIT: true,
  CREATE_QUESTION: true,
  MANAGE_CUSTOM_ACTIONS: true,
  REOPEN_DATAPERIOD: true,
};

const LICENSOR_ACCESS_ACCESS = {
  LICENSOR_ACCESS: true,
  CAN_RECOMMEND: true,
  MANAGE_USER: true,
};

const PE_ACCESS = {
  PE: true,
  CAN_RECOMMEND: true,
  MANAGE_USER: true
};

const STANDARD_PRODUCT_ACCESS = {
  STANDARD_PRODUCT_ACCESS: true,
  CARBON_REDUCTION_PLAN_PRODUCT_ACCESS: false,
  MANAGE_CUSTOM_ACTIONS: true,
};

const CARBON_REDUCTION_PLAN_PRODUCT_ACCESS = {
  CARBON_REDUCTION_PLAN_PRODUCT_ACCESS: true,
  STANDARD_PRODUCT_ACCESS: false,
  MANAGE_USER: false
};

const userRoleAccess: AccessGroupType  = {
  [USER_ROLE_CUSTOMER_READ]: CUSTOMER_READ_ACCESS,
  [USER_ROLE_CUSTOMER_CREATE]: CUSTOMER_CREATE_ACCESS,
  [USER_ROLE_CUSTOMER_UPDATE]: CUSTOMER_UPDATE_ACCESS,
  [USER_ROLE_CUSTOMER_DELETE]: CUSTOMER_DELETE_ACCESS,
  [USER_ROLE_CUSTOMER_ADMIN]: CUSTOMER_ADMIN_ACCESS,
  [USER_ROLE_ADMIN]: ADMIN_ACCESS,
  [USER_ROLE_SUPER_ADMIN]: SUPER_ADMIN_ACCESS,
};

const companyTypeAccess: AccessGroupType  = {
  [COMPANY_TYPE_STANDARD]: STANDARD_ACCESS,
  [COMPANY_TYPE_LICENSOR]: LICENSOR_ACCESS,
  [COMPANY_TYPE_LICENSOR_ACCESS]: LICENSOR_ACCESS_ACCESS,
  [COMPANY_TYPE_PE]: PE_ACCESS,
};

const productTypeAccess: AccessGroupType  = {
  [PRODUCT_TYPE_STANDARD]: STANDARD_PRODUCT_ACCESS,
  [PRODUCT_TYPE_CARBON_REDUCTION_PLAN]: CARBON_REDUCTION_PLAN_PRODUCT_ACCESS,
};

const accessItemHandler = {
  get: (target: AccessType, prop: string) => {
    return prop in target ? target[prop] : false;
  }
};

const userRoleAccessHandler = {
  get: (target: AccessGroupType, prop: string) => {
    const access = prop in target ? target[prop] : target[USER_ROLE_CUSTOMER_READ];
    return new Proxy(access, accessItemHandler);
  }
};

const companyTypeAccessHandler = {
  get: (target: AccessGroupType, prop: string) => {
    const access = prop in target ? target[prop] : target[COMPANY_TYPE_STANDARD];
    return new Proxy(access, accessItemHandler);
  }
};

const productTypeAccessHandler = {
  get: (target: AccessGroupType, prop: string) => {
    const access = prop in target ? target[prop] : target[PRODUCT_TYPE_STANDARD];
    return new Proxy(access, accessItemHandler);
  }
};

export type AccessType = { [key: string]: boolean };
export type AccessGroupType = { [key: string]: AccessType };
export const USER_ROLE_ACCESS = new Proxy(userRoleAccess, userRoleAccessHandler);
export const COMPANY_TYPE_ACCESS = new Proxy(companyTypeAccess, companyTypeAccessHandler);
export const PRODUCT_TYPE_ACCESS = new Proxy(productTypeAccess, productTypeAccessHandler);

export const getUserRoleAccess = (roles: string[], companyType: string, productType: string) => {
  let ROLE_ACCESS = COMPANY_TYPE_ACCESS[companyType];
  ROLE_ACCESS = {
    ...ROLE_ACCESS,
    ...PRODUCT_TYPE_ACCESS[productType]
  };
  
  roles.forEach((role) => {
    ROLE_ACCESS = {
      ...ROLE_ACCESS,
      ...USER_ROLE_ACCESS[role]
    }
  });

  return ROLE_ACCESS;
}

export const getChildUserRoleAccess = (accessType: AccessType, productType: string) => {
  let ROLE_ACCESS = COMPANY_TYPE_ACCESS[accessType.LICENSOR_ACCESS ? COMPANY_TYPE_LICENSOR_ACCESS : COMPANY_TYPE_PE];
  
  ROLE_ACCESS = {
    ...ROLE_ACCESS,
    ...USER_ROLE_ACCESS[USER_ROLE_CUSTOMER_READ],
    CUSTOMER_ADMIN: true,
    ...PRODUCT_TYPE_ACCESS[productType.replaceAll(' ', '').toUpperCase()],
    MANAGE_USER: true,
  }

  return ROLE_ACCESS;
}

export const getAdminChildUserRoleAccess = (accessType: AccessType, productType: string) => {
  const ROLE_ACCESS = {
    ...accessType,
    ...PRODUCT_TYPE_ACCESS[productType.replaceAll(' ', '').toUpperCase()],
    MANAGE_USER: true
  }

  return ROLE_ACCESS;
}
