import { API_ACTION, API_NET_ZERO_PLAN } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Action, Actions, actionTypes, mapActions, potentialType, RecommendAction, RecommendSingleAction } from "../../types/Action";
import { dedupeFetch } from "../dedupeFetch";

export const getActions = (): Promise<Actions> => {
  return dedupeFetch(`${API_NET_ZERO_PLAN}/GetAllActions`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActions(res.payload) as Actions;
    });
}

export const getActionsCSV = async (): Promise<Blob> => {
  return fetch(`${API_ACTION}/GetActionsAsCSV`, {
      headers: { "X-CSRF": "1" },
      method: 'GET',
  })
  .then((res) => res.blob());
}

export const getAction = (id: number): Promise<Action> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetAction?actionId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActions([res.payload])[0] as Action;
    });
}

export const createAction = (action: Action): Promise<number> => {
  return fetch(`${API_ACTION}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: getActionFormBody(action),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as number;
    });
}

export const updateAction = (action: Action): Promise<number> => {
  return fetch(`${API_ACTION}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: getActionFormBody(action, true),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as number;
    });
}

export const deleteAction = (companyId: number | string, actionId: number, netZeroPlanId: number): Promise<number> => {
  return fetch(`${API_ACTION}/Delete?companyId=${companyId}&actionId=${actionId}&netZeroPlanId=${netZeroPlanId}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'DELETE'
  })
    .then((res) => checkAuthorised(res));
}

export const getActionTags = (): Promise<string[]> => {
  return fetch(`${API_ACTION}/GetTags`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as string[];
    });
}

export const recommendAction = (data: RecommendAction): Promise<boolean> => {
  return fetch(`${API_ACTION}/RecommendActionToCompanies`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.success as boolean;
    });
}

export const recommendSingleAction = (data: RecommendSingleAction): Promise<boolean> => {
  if (data.recommend) {
    return fetch(`${API_ACTION}/RecommendActionToCompany`, {
      headers: {
        "X-CSRF": "1",
        "Content-Type": "application/json"
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => checkAuthorised(res))
      .then(checkErrors)
      .then((res) => {
        return res.success as boolean;
      });
  } else {
    return fetch(`${API_ACTION}/UnRecommendActionForCompany?CompanyId=${data.companyId}&RecommendingCompanyId=${data.recommendingCompanyId}&ActionId=${data.actionId}`, {
      headers: {
        "X-CSRF": "1",
        "Content-Type": "application/json"
      },
      method: 'DELETE',
    })
      .then((res) => checkAuthorised(res))
      .then(checkErrors)
      .then((res) => {
        return res.success as boolean;
      });
    
  }
}

const getActionFormBody = (action: Action, includeId: boolean = false) => {
  const departmentIds = action.departments.map((value) => value.id);
  const sdtIds = action?.sdts?.map((value) => value.id || []);
  potentialType.findIndex((value) => value === action.carbonSavingsPotential);

  return JSON.stringify({
    impactAreaId: action.impactAreaId,
    focusGroupId: action.focusGroupId,
    activityIds: action.activityIds,
    parentActionId: action.parentAction?.id,
    title: action.title,
    description: action.description,
    defaultPriority: action.defaultPriority,
    supportNotes: action.supportNotes,
    flotillaWorldUrl: action.flotillaWorldUrl,
    avoidancePercent: action.avoidancePercent,
    carbonSavingsPotential: getPotentialTypeId(action.carbonSavingsPotential),
    coBenefitsPotential: getPotentialTypeId(action.coBenefitsPotential),
    costSavingsPotential: getPotentialTypeId(action.costSavingsPotential),
    type: getTypeId(action.type),
    departmentIds: departmentIds,
    sustainableDevelopmentTargetIds: sdtIds,
    tags: action.actionTagsString,
    estimatedUptakeRequired: action.estimatedUptakeRequired || false,
    quickWin: action.quickWin,
    isActive: action.isActive,
    employeeSurveyEnabled: action.employeeSurveyEnabled,
    companyId: action.companyId,
    childActionIds: action.childActions?.map((item) => item.id),
    parentActionIds: action.parentActions?.map((item) => item.id),
    products: action.products?.map((item) => item.id),
    isRecurring: action.isRecurring,
    ...(includeId ? {actionId: action.id} : {})
  });
}

const getPotentialTypeId = (value: string = "Low"): number => {
  return potentialType.findIndex((type) => type === value);
}

const getTypeId = (value: string): number => {
  return actionTypes.findIndex((type) => type === value) + 1;
}
