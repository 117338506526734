import LocationNameSection from "./Sections/LocationName";
import LocationStartDateSection from "./Sections/LocationStartDate";
import LocationAddressSection from "./Sections/LocationAddress";
import LocationTypeSection from "./Sections/LocationType";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";
import LocationExtrasSection from "./Sections/LocationExtras";
import LocationCountrySection from "./Sections/LocationCountry";

const getPages = (values?: {[key: string]: any}): CreateWizardPages => ([
  {
    name: 'Name',
    title: 'Location Name',
    subtitle: "Please give this location a name. This will be used throughout the platform and your reports when ever location related data is displayed.",
    children: LocationNameSection,
    isNextDisabled: (values) => values?.["name"] === undefined || values?.["name"].trim() === ''
  },
  {
    name: 'Type',
    title: 'Location Type',
    subtitle: "Please select the type of location that best describes this locations use, this should be its main use and not secondary uses a building may or may not have. If the location is being setup for a non-physical location e.g. Remote Workers, please chose the Non-Physical option.",
    children: LocationTypeSection,
    isNextDisabled: (values) => values?.["type"] === undefined || values?.["type"] === ''
  },
  {
    name: 'Start Date',
    title: 'Location Start Date',
    subtitle: "This is the date that you first started occupying this location.",
    children: LocationStartDateSection,
    isNextDisabled: (values) => values?.["startDate"] === undefined || values?.["startDate"] === ''
  },
  (values?.["type"] === 'Non-physical' || values?.type === 10) && {
    name: 'Country',
    title: 'Location Country',
    subtitle: "Due to homeworking emissions varying by country, a non-physical location must still be associated with a country.",
    children: LocationCountrySection,
    isNextDisabled: (values) => values?.['address']?.['countryId'] === undefined,
    disabledButtonTitle: 'A country must be selected to continue.',
    shouldSkip: (values) => values?.["type"] !== 'Non-physical' || values?.type === 10
  },
  values?.["type"] !== 'Non-physical' && values?.type !== 10 && {
    name: 'Address',
    title: 'Location Address',
    subtitle: "Please search for or enter this locations address below.",
    children: LocationAddressSection,
    isNextDisabled: (values) => values?.["address"]?.['firstLine'] === undefined || values?.["address"]?.['firstLine'].trim() === '' || values?.["address"]?.['postcode'] === undefined || values?.["address"]?.['postcode'].trim() === '',
    disabledButtonTitle: 'The first line and postcode are required to continue.',
    shouldSkip: (values) => !values?.type || values?.["type"] === 'Non-physical' || values?.type === 10
  },
  values?.["type"] !== 'Non-physical' && values?.type !== 10 && {
    name: 'Extras',
    title: 'Extra Information',
    subtitle: "The question below are all the mandatory information we need to create your location.",
    children: LocationExtrasSection,
    isNextDisabled: (values) => values?.["extras"] === undefined,
    shouldSkip: (values) => !values?.type || values?.["type"] === 'Non-physical' || values?.type === 10
  },
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;

export default getPages;