import './styles.scss';

import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AddIcon, Button, IconButton, Menu, MenuItem, MoreIcon, Table, useAlert } from '@flotilla/component-library';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';

import { ReactComponent as BookIcon } from '../../../../assets/icons/Book.svg';
import Header from '../../../../components/HeaderV2';
import useLocations from '../../../../hooks/Location/useLocations';
import { Location } from "../../../../types/Location";
import { DATA_COLLECTION_LOCATION_SUBTITLE } from '../../../../assets/content/Subtitles';
import { DATA_COLLECTION_LOCATION_STEPS, TOUR_STYLES } from '../../../../constants/SiteTours';
import ActiveTag from '../../../../components/ActiveTag';
import { useCompanyId, useUserAccess } from '../../../../context';
import { useAppDispatch } from '../../../../helpers/hooks';
import { addModal } from '../../../../reducers/modal';
import ConfirmDeleteModal from '../../../../components/Modal/ConfirmDeleteModal';

interface LocationListProps {
  className?: string;
};

const LocationList: React.FC<LocationListProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const { userAccess } = useUserAccess();
  const { CREATE, PE, DELETE, DATA_EDIT } = userAccess;
  const navigate = useNavigate();
  const [isTourActive, setIsTourActive] = useState(false);
  const [showMenu, setShowMenu] = useState<Location>();
  const {
    locations,
    loading,
    deleteLocation
  } = useLocations();

  useEffect(() => {
    !loading && window.dispatchEvent(new Event('resize'));
  }, [loading]);

  const handleAddLocation = () => {
    navigate('./create');
  }

  const handleClickLocation = (id: number) => {
    DATA_EDIT && navigate(`./${id}`);
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if(status === STATUS.SKIPPED || action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      setIsTourActive(false);
    }
  }

  const handleOnClickMore = (location: Location): MouseEventHandler<HTMLButtonElement> => {
    return (event) => {
      event.stopPropagation();
      setShowMenu(location);
    }
  }

  const handleOnClickDelete = (location: Location): MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      if (!location.isInUse) {
        dispatch(addModal({
          id: 'delete-location-modal',
          children: (
            <ConfirmDeleteModal
              header="Delete Location"
              deleteItem={() => deleteLocation(location.id)}
            />
          )
        }));
      } else {
        addAlert({ id: `Location Delete Not Allowed ${companyId}`, type: "error", title: "Delete Location Unavailable", content: "Unable to delete location because you have already provided usage data such as electricity or fuel against it." });
      }
    }
  }

  return (
    <section id="location-list-page" className={className}>
      <Joyride
        callback={handleJoyrideCallback}
        run={isTourActive}
        showSkipButton={true}
        continuous={true}
        steps={DATA_COLLECTION_LOCATION_STEPS}
        disableScrolling={true}
        styles={TOUR_STYLES}
        showProgress={true}
      />
      <Header
        rightChildren={
          () => CREATE && !(PE && companyId !== "0") && (
            <IconButton
              id="add-location"
              onClick={handleAddLocation}
              icon={<AddIcon />}
              small
            />
          )
        }
        subtitle={DATA_COLLECTION_LOCATION_SUBTITLE}
      >
        {!loading ? <span className='btn-title'>
          Locations
          <IconButton 
            icon={<BookIcon />}
            variant='ghost'
            onClick={() => { setIsTourActive(true) }}/>
        </span> : 'Locations'}
      </Header>
      <main>
        <Table
          key={locations?.length}
          id="location-list-table"
          data={locations ?? []}
          quickFilters={[
            {
              key: 'type',
              label: 'Type'
            },
            {
              key: 'activeStatus',
              label: 'Status'
            }
          ]}
          headers={[
            {
              label: 'Name',
              key: 'name'
            },
            {
              label: 'Type',
              key: 'type'
            },
            {
              label: 'Address',
              key: 'address'
            },
            {
              label: 'Status',
              key: 'activeStatus'
            },
            {
              label: '',
              key: ''
            }
          ]}
          rowComponent={(item) => {
            const location = item as unknown as Location;
            return (
              <tr className={DATA_EDIT ? "" : "cursor-default"} onClick={() => handleClickLocation(location.id)}>
                <td id="name" style={{ borderColor: location.colorHex || 'transparent' }} >
                  <section className="container" title={location.name}>
                    { DATA_EDIT ?
                      <Link className='title title-link' to={`${location.id}`} onClick={(e) => e.stopPropagation()}>{location.name}</Link> :
                      <span className="title title-link">{location.name}</span>}
                  </section>
                </td>
                <td>{location.type}</td>
                <td>{location.addressString}</td>
                <td><ActiveTag size="small">{location.activeStatus}</ActiveTag></td>
                <td id="actions">
                  {DATA_EDIT && <section className="container">
                    <section id="actions">
                      <IconButton 
                        variant='ghost'
                        icon={<MoreIcon variant='dark' />}
                        onClick={handleOnClickMore(location)}
                      />
                      { showMenu?.id === location.id && (
                        <Menu
                          id="list-item-menu"
                          onClose={() => setShowMenu(undefined)}
                        >
                          <MenuItem onClick={() => handleClickLocation(location.id)}>View</MenuItem>
                          {DELETE ? <MenuItem onClick={handleOnClickDelete(location)}>Delete</MenuItem> : <></>}
                        </Menu>
                      )}
                    </section>
                  </section>}
                </td>
              </tr>
            )
          }}
          isLoading={loading}
          stickyHeader
        />
        { !loading && (locations?.length || 0) < 1 && (
          <section id="empty-list-helper">
            <h3>Let's Add Your First Location!</h3>
            <p>It looks like there aren't any locations yet, but that's a great place to start! Adding a location helps define where your company's operations happen.</p>
            <Button
              onClick={handleAddLocation}
              disabled={!(CREATE && !(PE && companyId !== "0"))}
            >
              Add Location
            </Button>
          </section>
        )}
      </main>
    </section>
  );
}

export default LocationList;
