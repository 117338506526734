import './styles.scss';

import React, { useState } from 'react';
import { Button, DatalistMulti, MenuItem, Modal, NumberInput, Options, Tag, TextInput, TickIcon, useAlert } from '@flotilla/component-library';

import { addAction } from '../../../api/netZeroPlan';
import { ESTIMATED_UPTAKE_TOOLTIP } from '../../../assets/content/Tooltips';
import { Action } from '../../../types/Action';
import { useCompanyId } from '../../../context';
import useLocations from '../../../hooks/Location/useLocations';
import { Locations } from '../../../types/Location';
import numberFormatter from '../../../helpers/numberFormatter';

interface AddActionModalProps {
  className?: string;
  onClose: () => void;
  onSubmit?: () => void;
  action: Action;
  netZeroPlanId: number,
  header?: string;
};

const AddActionModal: React.FC<AddActionModalProps> = ({
  className = "",
  onClose = () => {},
  onSubmit = () => {},
  action,
  netZeroPlanId,
  header = 'Add Action to your Net Zero Plan'
}) => {
  const { addAlert } = useAlert(); 
  const {
    id,
    title,
    defaultPriority: priority,
    estimatedUptakeRequired: requiredUptake = false,
    impactAreaName = ''
  } = action;
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const {
    locations,
    loading: locationsLoading
  } = useLocations();
  // Adding 7 days to todays date as a rough guide for how long the survey should take
  const [completionDate, setCompletionDate] = useState<string>(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10));
  const [startDate, setStartDate] = useState<string>(new Date(new Date().getTime()).toISOString().substring(0, 10));
  const [uptake, setUptake] = useState<number>();
  const [selectedLocations, setSelectedLocations] = useState<Options>([]);

  const handleSubmit = async () => {
    setLoading(true);
    completionDate && (requiredUptake ? uptake : true) &&
      await addAction({
          id,
          netZeroPlanId,
          uptake,
          completionDate,
          startDate,
          priority,
          companyId: action.companyId || companyId || 0,
          locations: (selectedLocations.length > 0 ? selectedLocations : []) as unknown as Locations
        })
        .then(() => {
          onSubmit();
          addAlert({
            id: `Added Action ${id}`,
            type: 'success',
            title: 'Success, Action added to your plan!',
            content: 'Well done you have added an Action to your plan, one step closer to Net Zero.',
          });
        })
        .catch(() => {
          addAlert({
            id: `Added Action ${id}`,
            type: 'error',
            title: 'Error Adding Action to your Plan',
            content: 'There seems to be an error when adding an action to your plan, please try again. If the problem continues to happen, please contact us.',
          });
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
  };

  const handleOnChangeLocation = (_locations?: Options) => {
    setSelectedLocations(_locations || []);
  }

  return (
    <Modal
      id="add-action-modal"
      className={className}
      onClose={onClose}
    >
      <header>
        <h4>{header}</h4>
        <p>{title}</p>
      </header>

      <main>
        <TextInput
          id="start-date"
          label="Start Date"
          aria-label="Start Date"
          value={startDate}
          onChange={(event) => setStartDate(event?.currentTarget.value)}
          type='date'
        />
  
        <TextInput
          id="completion-date"
          label="Target Completion Date"
          aria-label="Target Completion Date"
          value={completionDate}
          onChange={(event) => setCompletionDate(event?.currentTarget.value)}
          type='date'
        />

        { requiredUptake &&
          <NumberInput
            id="uptake"
            label="Estimated Uptake (%)"
            aria-label="Estimated Uptake (%)"
            value={uptake}
            onChange={(value) => setUptake(value)}
            min={0}
            max={100}
            decimal
            tooltipText={ESTIMATED_UPTAKE_TOOLTIP}
          />
        }

        { (impactAreaName.toLowerCase() === 'buildings') && (locations?.length || 0) > 0 && (
          <article id="locations-selector-container">
            <DatalistMulti
              id="locations"
              label="Locations"
              options={locations?.map((item) => ({  ...item, label: item.name, value: String(item.id) })) || []}
              onChange={handleOnChangeLocation}
              values={selectedLocations?.map((item) => ({  ...item, label: item.name, value: String(item.id) })) && undefined}
              disabled={locationsLoading}
              optionComponent={({ option, checked, ...props }) => (
                <MenuItem className="location-option" {...props}>
                  <>
                    <p id="name">{option.label}</p>
                    <article className="est-savings">
                      <p id="value">{numberFormatter(action?.locationEmissions?.find((item) => item.locationId === Number(option.value))?.estimatedtCO2eSavings || 0)}</p>
                      <p id="label">Est. Savings</p>
                    </article>
                    <article className="checked">
                      {checked ? <TickIcon variant="dark" /> : undefined}
                    </article>
                  </>
                </MenuItem>
              )}
            />
            { selectedLocations.length === 0 && <Tag grey>All</Tag>}
          </article>
        )}
      </main>

      <section className="button-container">
        <Button
          variant="ghost"
          onClick={() => onClose()}
          isLoading={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={loading}
          disabled={!completionDate || (requiredUptake ? !uptake : false)}
        >
          Add
        </Button>
      </section>
    </Modal>
  );
}

export default AddActionModal;
