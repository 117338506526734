import './styles.scss';

import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AddIcon, CloseIcon, IconButton, Menu, MenuItem, Tag, useAlert } from "@flotilla/component-library";

import ConfirmActionDeleteModal from '../../ActionDetail/ConfirmActionDeleteModal';
import { recommendSingleAction } from '../../../../../api/Action';
import { ReactComponent as TickIcon } from "../../../../../assets/icons/Tick.svg";
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/More.svg';
import ImpactAreaTag from "../../../../../components/ImpactAreaTag";
import EmployeeSuggestedTag from "../../../../../components/EmployeeSuggestedTag";
import QuickWinTag from "../../../../../components/QuickWinTag";
import CustomActionTag from "../../../../../components/CustomActionTag";
import RecommendedTag from '../../../../../components/RecommendedTag';
import RecommendActionModal from '../../../../../components/Modal/RecommendActionModal';
import { useCompanyId, useCompanyType, useUserAccess } from '../../../../../context';
import getNZPTableCell from "../../../../../helpers/getNZPTableCell";
import { useAppDispatch } from '../../../../../helpers/hooks';
import isParentCompany from '../../../../../helpers/isParentCompany';
import { useCompany } from '../../../../../hooks';
import { addModal, removeModal } from '../../../../../reducers/modal';
import { Action } from "../../../../../types/Action";

interface ActionRowProps {
  item: Action;
  headers: { key: string }[] | null | undefined;
  netZeroPlanId: number | undefined;
  handleClickAction: (
    id: string | number
  ) => React.MouseEventHandler<HTMLTableRowElement>;
  handleAddActionButtonClick: (action: Action) => void;
  onRefresh: () => void;
}

const ActionRow: React.FC<ActionRowProps> = ({
  item,
  headers,
  netZeroPlanId,
  handleClickAction,
  handleAddActionButtonClick,
  onRefresh
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const companyType = useCompanyType();
  const dispatch = useAppDispatch();
  const { userAccess } = useUserAccess();
  const { CAN_RECOMMEND, NET_ZERO_PLAN_EDIT, DELETE, MANAGE_CUSTOM_ACTIONS } = userAccess;
  const navigate = useNavigate();
  const impactArea = item.impactArea?.name;
  const [showMenu, setShowMenu] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMoreClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    !showMenu && setShowMenu(true);
  }

  const handleDeletion = () => {
    setDeleting(false);
    onRefresh();
  }

  const handleOnClickRecommend = async () => {
    setShowMenu(false);
    if (isParentCompany(companyType)) {
      dispatch(addModal({
        id: 'recommend-action',
        children: (
          <RecommendActionModal
            companyId={companyId}
            data={item}
            onClose={(change) => {
              change && onRefresh();
              dispatch(removeModal());
            }}
          />
        )
      }));
    } else {
      setLoading(true);
      await recommendSingleAction({
        actionId: item.id,
        recommendingCompanyId: company.parentId || 0,
        companyId: company.id,
        recommend: !item.recommended
      })
        .then(() => {
          onRefresh();
          addAlert({ id: `${item.recommended ? 'Unrecommend' : 'Recommend'} Action Success`, type: "success", title: `Successfully ${item.recommended ? 'unrecommend' : 'recommend'} action` });
        })
        .catch(() => {
          addAlert({ id: `${item.recommended ? 'Unrecommend' : 'Recommend'} Action Failed`, type: "error", title: `Failed to ${item.recommended ? 'unrecommend' : 'recommend'} action` });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <tr className="action-row">
      {headers?.map((h, index) => {
        if (h.key === "title") {
          return (
            <td id="name" key={h.key} className={`action--${impactArea?.toLowerCase().replaceAll(" ", "-")}`}>
              <article onClick={handleClickAction(item.id)} title={item.title}>
                <Link className='title-link' to={`${item.id}`} onClick={(e) => e.stopPropagation()}>{item.title}</Link>
                <section id="tag-container">
                  {item.companyId && <CustomActionTag size='small' />}
                  {item.quickWin && <QuickWinTag size='small' />}
                  {item.recommended && <RecommendedTag size='small' />}
                  {item.employeeSuggested && <EmployeeSuggestedTag  size='small' />}
                  {impactArea && <ImpactAreaTag className="tag" size="small" extraText={item.activityName}>{impactArea}</ImpactAreaTag>}
                  {item.actionTags && item.actionTags.map((value, index) =>
                    <Tag key={index} className="tag" size="small" title={value}>{value}</Tag>)}
                </section>
              </article>
            </td>
          );
        }

        if (h.key === 'isRecurring') {
          return (
            <td id="recurring">
              <article>
                {item.isRecurring ? (
                  <>
                    <TickIcon />
                    { (item?.netZeroPlanActions?.length || 0) > 0 && (
                      <Tag id="recurring-times" size='small'>{String(item?.netZeroPlanActions?.length) + ' times'}</Tag>
                    )}
                  </>
                ) : (
                  <CloseIcon variant='dark' />
                )}
              </article>
            </td>
          )
        }
      
        if (h.key === 'locationsCount') {
          return (
            <td id="locations">
              <article>
                {item.impactAreaName.toLowerCase() === 'buildings' ? (
                  <>
                    <TickIcon />
                    { (item?.netZeroPlanActions?.length || 0) > 0 && (
                      <Tag id="location-times" size='small' grey>{String(item?.netZeroPlanActions?.length) + ' locations'}</Tag>
                    )}
                  </>
                ) : (
                  <CloseIcon variant='dark' />
                )}
              </article>
            </td>
          )
        }
    
        if (h.key === "add") {
          var menuItems = [];

          if(!item.netZeroPlanAction && NET_ZERO_PLAN_EDIT) {
            menuItems.push(
              <MenuItem onClick={() =>
                !item.netZeroPlanAction &&
                handleAddActionButtonClick(item as unknown as Action)
              }>
                Add to plan
              </MenuItem>
            );
          }

          if(CAN_RECOMMEND) {
            menuItems.push(
              <MenuItem onClick={handleOnClickRecommend}>
                { item.recommended ? 'Unrecommend' : 'Recommend' }
              </MenuItem>
            );
          }

          if(NET_ZERO_PLAN_EDIT && MANAGE_CUSTOM_ACTIONS) {
            if(item.companyId) {
              menuItems.push(
                <MenuItem onClick={() => navigate(`${item.id}/edit`)}>Edit</MenuItem>
              );
            }

            menuItems.push(
              <MenuItem onClick={() => navigate('new', { state: { actionDuplicate: item } })}>Duplicate</MenuItem>
            );
          }

          if(item.companyId && DELETE && MANAGE_CUSTOM_ACTIONS) {
            menuItems.push(
              <MenuItem onClick={() => { setDeleting(true) }}>Delete</MenuItem>
            );
          }
          
          return (
            <td key={index}>
              {deleting && <ConfirmActionDeleteModal action={item} onDelete={handleDeletion} onClose={() => setDeleting(false)}/>}
              <section className="actions">
                {NET_ZERO_PLAN_EDIT && <IconButton
                  className="add-btn"
                  icon={
                    (item.netZeroPlanAction && !item.isRecurring && item.impactAreaName.toLowerCase() !== 'buildings') ? <TickIcon stroke="#FBFAF8" /> : <AddIcon />
                  }
                  onClick={() =>
                    (item.isRecurring || !item.netZeroPlanAction || item.impactAreaName.toLowerCase() === 'buildings') &&
                    handleAddActionButtonClick(item as unknown as Action)
                  }
                  isLoading={netZeroPlanId === undefined}
                  small
                />}
                {menuItems.length > 0 && 
                <>
                  <IconButton
                    variant="ghost"
                    aria-label="More"
                    icon={<MoreIcon />}
                    onClick={handleMoreClick}
                    isLoading={loading}
                  />
                  { showMenu && (
                    <Menu
                      className="list-item-menu"
                      onClose={() => setShowMenu(false)}
                    >
                      {menuItems}
                    </Menu>
                  )}
                </>}
              </section>
            </td>
          )
        }

        return getNZPTableCell(h.key, item);
      })}
    </tr>
  );
};

export default ActionRow;
