import './styles.scss';

import React, { ChangeEventHandler, useState } from 'react';
import { IconButton, MagicWandIcon, TextArea } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../../../../../hooks';
import { OPENAI_API_KEY } from '../../../../../../constants';

const ReportDescriptionSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { companyId = "0" } = useParams();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(false);

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.currentTarget.value;
    onChange({ description: value });
  }

  const handleOnClickAskChatGPT = async () => {
    const question = `Use this URL ${company.url} to learn about ${company.name}, we want a short blurb explaining this company and what they do, it should be a short paragraph. Start the paragraph with the company name and don't add there website back into the description.`;
    setLoading(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: question }]
      })
    })
      .then((res) => res.json())
      .then((res) => {
        onChange({ description: res.choices[0].message.content });
      })
      .catch((error) => {
        console.error('Error communicating with ChatGPT:', error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section id="report-description-section" className={className}>
      <TextArea
        id="company-description"
        label='Company description'
        value={values?.["description"]}
        onChange={handleDescriptionChange}
        disabled={loading}
        maxLength={5000}
      />
      <IconButton
        id="ask-chat-gpt"
        icon={<MagicWandIcon />}
        onClick={handleOnClickAskChatGPT}
        aria-label='Ask ChatGPT'
        label='Ask ChatGPT'
        small
        isLoading={loading}
        disabled={company?.url === undefined || company?.url === ''}
      />
    </section>
  );
}

export default ReportDescriptionSection;
