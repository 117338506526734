import { Card } from "@flotilla/component-library"
import { NetZeroPlan } from "../../../../types/NetZeroPlan"

const InterimToolTip: React.FC<{plan?: NetZeroPlan}> = ({ plan }) => {
    return (
      <Card id="interim-tooltip">
          <p>{`Near-term targets have been set to ${Math.round((plan?.scope1ReductionTargetInterim || 0) * 100)}% for scope 1, ${Math.round((plan?.scope1ReductionTargetInterim || 0) * 100)}% for scope 2 and ${Math.round((plan?.scope3ReductionTargetInterim || 0) * 100)}% for scope 3.`}</p>
      </Card>
    )
}

export default InterimToolTip;