import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";
import DataPeriodSection from "./Sections/BaselineYear";
import ReportTitleSection from "./Sections/Title";
import ReportTypeSection from "./Sections/Type";
// import ReportCRPTypeSection from "./Sections/CRPType";
import ReportDescriptionSection from "./Sections/Description";
import ReportOutputSection from "./Sections/Output";

const getCarbonReductionPlanPages = (isAdmin: boolean = false) => [
  // Note: For now this isn't needed as there is only one option,
  // but is here to ease the process of adding it back in
  // {
  //   name: 'Type',
  //   title: 'Report Type',
  //   subtitle: "Please select the type of report that you would like to create.",
  //   children: ReportCRPTypeSection,
  //   isNextDisabled: (values) => values?.["crpType"] === -1 || values?.["crpType"] === undefined
  // },
  {
    name: 'Description',
    title: 'Company Description',
    subtitle: "Please provide some introductory copy about your company. This will be used within the report. Click the magic wand to use AI and get started.",
    children: ReportDescriptionSection,
    isNextDisabled: (values) => values?.["description"] === undefined || values?.["description"] === ""
  },
  isAdmin && {
    name: 'Output',
    title: 'Output Type',
    subtitle: "This is the type of document that will be generated for you.",
    children: ReportOutputSection,
    isNextDisabled: (values) => values?.["outputType"] === -1 || values?.["outputType"] === undefined
  },
] as (CreateWizardPage | boolean)[];

const NET_ZERO_SOLUTIONS = [
  {
    name: 'Title',
    title: 'Report Title',
    subtitle: "Please enter the title for this report.",
    children: ReportTitleSection,
    isNextDisabled: (values) => values?.["title"] === undefined || values?.["title"].trim() === ''
  },
  {
    name: 'Baseline Year',
    title: 'Report Baseline Year',
    subtitle: "A baseline year refers to a specific year against which a company's GHG emissions are tracked over time. Setting a baseline year is crucial because it allows organisations to quantify their emissions reductions and track their progress towards achieving their Net Zero goals. Please select a baseline year for this report:",
    children: DataPeriodSection,
    isNextDisabled: (values) => (values?.["baselineYear"] || 0) === 0 || values?.["baselineDataPeriodIds"] === undefined
  }
] as (CreateWizardPage | boolean)[];

const getPages = (carbonReductionReport: boolean = false, reportType: number = -1, isAdmin: boolean = false): CreateWizardPages => (
  ( carbonReductionReport ? getCarbonReductionPlanPages(isAdmin) : (
    [
      {
        name: 'Type',
        title: 'Report Type',
        subtitle: "Please choose a type for this report.",
        children: ReportTypeSection,
        isNextDisabled: (values) => values?.["reportType"] === -1 || values?.["reportType"] === undefined
      },
      ...(reportType === 2 ? getCarbonReductionPlanPages(isAdmin) : NET_ZERO_SOLUTIONS)
    ]
  )) as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;