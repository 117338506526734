import { API_NET_ZERO_PLAN } from "../constants";
import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { Scopes, mapScopes } from "../types/Scope";

export const getAllScopes = (id: number | null, companyId: number, comparisonYear?: number): Promise<Scopes> => {
    return fetch(`${API_NET_ZERO_PLAN}/GetScopes?netZeroPlanId=${id ?? ''}&companyId=${companyId}&comparisonYear=${comparisonYear}`, {
      headers: { "X-CSRF": "1" },
      method: 'GET',
    })
      .then((res) => checkAuthorised(res))
      .then(checkErrors)
      .then((res) => {
        return mapScopes(res.payload).sort((a, b) => a.scope - b.scope);
      });
  }