import './styles.scss';

import { ChangeEventHandler } from 'react';
import { Button, DeleteIcon, IconButton, TextArea, TextInput } from "@flotilla/component-library";

import { Activity, ActivityNote } from "../../../../../../../../types/DataPeriod";
import useActivityNote from '../../../../../../../../hooks/ActivityNote/useActivityNote';
import { removeModal } from '../../../../../../../../reducers/modal';
import { useAppDispatch } from '../../../../../../../../helpers/hooks';
import { NOTE_TEXTAREA_PLACEHOLDER } from '../../../../../../../../assets/content/Placeholders';

interface NoteModalProps {
  note: ActivityNote;
  activity: Activity;
  onChangeNote?: (data: ActivityNote) => void;
  disabled?: boolean;
  companyId: number | string;
};
  
const NoteModal: React.FC<NoteModalProps> = ({
  note,
  activity,
  onChangeNote = () => ({}),
  disabled = false,
  companyId = "0"
}) => {
  const dispatch = useAppDispatch();
  const {
    activityNote,
    updateActivityNote,
    saveActivityNote,
    loading,
    updated
  } = useActivityNote(note);
  const {
    notes = '',
    documentName,
    documentationUrl
  } = activityNote;
  
  const handleSaveNote = () => {
    saveActivityNote(companyId)
      .then((res) => {
        onChangeNote(res || activityNote);
        dispatch(removeModal());
      });
  };

  const handleOnChangeNotes: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.currentTarget.value;
    updateActivityNote({
      notes: value
    });
  }

  const handleOnChangeNotesFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.currentTarget?.files?.[0] || undefined;
    updateActivityNote({
      file: file,
      documentName: file?.name || '',
      documentationUrl: undefined,
    });
  }

  const handleClearFile = () => {
    updateActivityNote({
      file: undefined,
      documentName: undefined,
      documentationUrl: undefined
    });
  }

  const handleUploadDocumentClick = () => {
    document.getElementById("upload-document-input")?.click();
  }

  return (
    <section
      id="note-modal"
    >
      <h4>Notes</h4>
      <p id="subtitle">{activity.focusGroup?.name ? `${activity.focusGroup?.name} - ` : ''}{activity.shortName}</p>
      <TextArea
        id="notes"
        defaultValue={notes}
        onChange={handleOnChangeNotes}
        placeholder={NOTE_TEXTAREA_PLACEHOLDER}
        disabled={disabled}
        maxLength={5000}
      />
      { documentName ? (
        <section id="uploaded-file">
          { documentationUrl ? (
            <a href={documentationUrl}>
              {documentName}
            </a>
          ) : (
            <p>{documentName}</p>
          )}
          { !disabled && (
            <IconButton
              small
              variant='ghost'
              icon={<DeleteIcon variant='dark' />}
              onClick={handleClearFile}
            />
          )}
        </section>
      ) : !disabled && (
        <>
          <Button
            id="upload-document"
            variant="outline"
            onClick={handleUploadDocumentClick}
            title="Upload Document"
          >
            Upload Document
          </Button>
          <TextInput
            id="upload-document-input"
            onChange={handleOnChangeNotesFile}
            type="file"
          />
        </>
      )}
      { !disabled && (
        <Button
          id="save"
          onClick={handleSaveNote}
          isLoading={loading}
          disabled={!updated}
        >
          Save
        </Button>
      )}
    </section>
  );
}

export default NoteModal;