import './styles.scss';

import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';

import { useCompanyId, useUserAccess } from '../../../context';
import useNetZeroPlanId from '../../../hooks/NetZeroPlan/useNetZeroPlanId';
import {
  ActionsSummary,
  CarbonFootprint,
  CompanyTasksWidget,
  IntensitySummary,
  NetZeroSummary,
  ReductionChart,
  ScopeSummary,
  SurveySummary
} from '../../../components/DashboardWidgets';
import EmissionHotspots from '../../../components/DashboardWidgets/EmissionHotspots';
import Header from '../../../components/HeaderV2';
import { TOUR_STYLES, POST_ONBOARDING_STEPS } from '../../../constants/SiteTours';
import { useCompany } from '../../../hooks';

interface CompanyHomeProps {
  className?: string;
};

const CompanyHome: React.FC<CompanyHomeProps> = ({
  className = ""
}) => {
  const hash = window.location.hash;
  const { userAccess } = useUserAccess();
  const { STANDARD_PRODUCT_ACCESS, CARBON_REDUCTION_PLAN_PRODUCT_ACCESS, SURVEY_EDIT } = userAccess;
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [netZeroPlanId, setNetZeroCompanyId] = useNetZeroPlanId(companyId as unknown as number);
  const [netZeroSummaryKey, setNetZeroCompanyKey] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    companyId && setNetZeroCompanyId(companyId as unknown as number);
  }, [companyId, setNetZeroCompanyId]);

  useEffect(() => {
    company && setLoading(false);
  }, [company]);

  const handleOnRefresh = () => {
    setNetZeroCompanyKey(netZeroSummaryKey + 1);
  }

  if(loading) return <></>;

  return (
    <section id="company-home-page" className={`${CARBON_REDUCTION_PLAN_PRODUCT_ACCESS ? 'reduction-plan-dashboard' : ''} ${className}`}>
      <Header>
        Welcome to Flotilla
      </Header>
      <section id="relative-wrapper">
          <main>
            <section id="first-column">
              <CarbonFootprint variant='card' />
              <ScopeSummary />
              { STANDARD_PRODUCT_ACCESS && SURVEY_EDIT && (
                <SurveySummary variant='card' />
              )}
              <ActionsSummary variant='card' />
              { STANDARD_PRODUCT_ACCESS && (
                <IntensitySummary />
              )}
              { netZeroPlanId > 0 && CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && (
                <ReductionChart />
              )}
            </section>
            <section id="second-column">
              { STANDARD_PRODUCT_ACCESS && (
                <>
                  <NetZeroSummary key={netZeroSummaryKey}/>
                  <EmissionHotspots onRefresh={handleOnRefresh}/>
                </>
              )}
              { CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && (
                <CompanyTasksWidget id="tasks" />
              )}
            </section>
          </main>
      </section>
      { hash === '#onboarded' && (
        <Joyride
          showSkipButton={true}
          continuous={true}
          steps={POST_ONBOARDING_STEPS}
          disableScrolling={true}
          styles={TOUR_STYLES}
          showProgress={true}
        />
      )}
    </section>
  );
}

export default CompanyHome;
