import './styles.scss';

import React, { useState } from 'react';
import { Button, CheckboxButtonV2, Datalist, Label, Skeleton, TextInput, useAlert } from '@flotilla/component-library';

import { createCompany } from '../../../api/companies';
import { useNavigate } from 'react-router-dom';
import { useCompanyId, useUserAccess } from '../../../context';
import useCompanies from '../../../hooks/Company/useCompanies';
import useProductTypes from '../../../hooks/Lookup/useProductTypes';
import { Lookup } from '../../../types/Lookup';
import useCompanyTypes from '../../../hooks/Lookup/useCompanyTypes';
import { COMPANY_TYPE_STANDARD_ID } from '../../../constants';
import useAdminUsers from '../../../hooks/User/useAdminUsers';

interface CreateCustomerModalProps {
  className?: string;
  parentCompany?: Option;
  onClose: (change?: boolean) => void;
};

interface Option {
  value: string;
  [key: string]: unknown;
}

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  className = "",
  parentCompany: initialParentCompany,
  onClose = () => {},
}) => {
  const { userAccess } = useUserAccess();
  const { LICENSOR } = userAccess;
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const {
    companies = []
  } = useCompanies();
  const {
    productTypes = [],
    loading: productTypesLoading
  } = useProductTypes();
  const {
    companyTypes = [],
    loading: companyTypesLoading
  } = useCompanyTypes();
  const {
    users: adminUsers = [],
    loading: adminUsersLoading
  } = useAdminUsers();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [parentCompany, setParentCompany] = useState<Option | undefined>(initialParentCompany);
  const [productType, setProductType] = useState<Lookup>();
  const [companyType, setCompanyType] = useState<Lookup | undefined>(initialParentCompany || LICENSOR ? { id: COMPANY_TYPE_STANDARD_ID, label: '' } : undefined);
  const [consultant, setConsultant] = useState<Option | undefined>();

  const handleSubmit = async () => {
    setIsLoading(true);
    await createCompany(
      parentCompany?.id ? parseInt(parentCompany.id as string) : parseInt(companyId),
      name,
      companyType,
      productType,
      Number(consultant?.value)
    )
      .then((res) => {
        onClose(true);
        res?.id && navigate(`/${res.id}`);
        addAlert({ id: `Create Company Success`, type: "success", title: "Successfully create a new company" });
      })
      .catch((err) => {
        setIsLoading(false);
        if(err?.message.includes('already exists')) {
          addAlert({ id: `Create Company Failed`, type: "error", title: "Failed to create company", content: err.message });
        }
        else {
          addAlert({ id: `Create Company Failed`, type: "error", title: "Failed to create company", content: "Something went wrong, please try again." });
        }
      });
  };

  const handleOnChangeParent = (option: Option | unknown | undefined) => {
    setParentCompany(option as Option | undefined);
    if(option) {
      setCompanyType({ id: COMPANY_TYPE_STANDARD_ID, label: '' });
    }
  }

  const handleOnChangeProductType = (option: Lookup) => {
    return (checked: boolean) => {
      setProductType(checked ? option : undefined);
    }
  }


  const handleOnChangeCompanyType = (option: Lookup) => {
    return (checked: boolean) => {
      setCompanyType(checked ? option : undefined);
      if (option?.id !== COMPANY_TYPE_STANDARD_ID) {
        setParentCompany(undefined);

        if(productType?.id !== 2) {
          setProductType(undefined);
        }
      }
    }
  }

  const handleOnChangeConsultant = (option: Option | undefined) => {
    setConsultant(option);
  }

  return (
    <section
      id="create-customer-modal"
      className={`create-customer-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <h4>Create New Customer</h4>
      <main>
        <TextInput
          id="company-name"
          label="Company Name*"
          placeholder="Enter your company name..."
          aria-label="Company name"
          value={name}
          maxLength={100}
          onChange={(event) => setName(event?.currentTarget.value)}
        />
        <section id="company-type" className="option-container">
          <Label>Company type*</Label>
          <article id="options">
            { companyTypes.map((item, index) => (
              <CheckboxButtonV2
                key={index}
                checked={item.id === companyType?.id}
                onToggle={initialParentCompany !== undefined ? undefined : handleOnChangeCompanyType(item)}
                disabled={initialParentCompany !== undefined || LICENSOR}
              >
                {item.label}
              </CheckboxButtonV2>
            ))}
            { companyTypes.length <= 0 && companyTypesLoading && (
              <>
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
              </>
            )}
          </article>
        </section>
        <section id="product-type" className="option-container">
          <Label>Product type*</Label>
          <article id="options">
            { productTypes
              .filter(p => !companyType || companyType?.id === COMPANY_TYPE_STANDARD_ID || p?.id === 2)
                .map((item, index) => (
                <CheckboxButtonV2
                  key={index}
                  checked={item.id === productType?.id}
                  onToggle={handleOnChangeProductType(item)}
                >
                  {item.label}
                </CheckboxButtonV2>
              ))
            }
            { productTypes.length <= 0 && productTypesLoading && (
              <>
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
                <Skeleton className="checkbox-loader" />
              </>
            )}
          </article>
        </section>
        { !LICENSOR && companyType?.id === COMPANY_TYPE_STANDARD_ID && (
          <Datalist
            key="parent-company"
            id="parent-company"
            className="parent-company"
            options={companies?.filter(c => ['Private Equity', 'Licensor', 'Licensor Read Only'].includes(c.companyType?.label || '')).map((item => ({ value: item.name, ...item })))}
            onChange={handleOnChangeParent}
            value={parentCompany}
            searchKeys={['value']}
            label='Parent Company'
            disabled={initialParentCompany !== undefined || LICENSOR}
          />
        )}
        <Datalist
          key="consultant"
          id="consultant"
          className="consultant"
          options={adminUsers?.map((item) => ({ ...item, value: String(item.id), label: item.fullName || item.username || '' })) || []}
          onChange={handleOnChangeConsultant}
          value={consultant}
          searchKeys={['fullName', 'username']}
          label='Assign Consultant'
          disabled={adminUsersLoading}
        />
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          isLoading={isLoading} 
          disabled={!(name.trim()) || !productType || !companyType}>
            Create
        </Button>
      </section>
    </section>
  );
}

export default CreateCustomerModal;
