import { Charts } from "./Chart";
import { onContentUpdate } from "./ContentUpdate";

export const documentTypes = [
  "Carbon Report",
  "Commitment Statement",
  "Progress Report",
  "Route To Net Zero",
  "Responsible Business Report",
  "Certificate",
  "Other"
];
export type DocumentType = typeof documentTypes[number];

export type CompanyReport = {
  reportId: number;
  title: string;
  status: string;
  totaltCO2e: number;
  yearEnds: string;
  hidden: boolean;
  reportTypeString: string;
};

export type CompanyDocument = {
  id: number | null;
  title: string;
  carbonReportUrl: string;
  reportType: number;
  reportTypeString: string;
  file?: File,
  fileType?: string,
};

export type CompanyReports = CompanyReport[];
export type CompanyDocuments = CompanyDocument[];

export type CompanyReportAndDocument = CompanyReport | CompanyDocument;
export type CompanyReportsAndDocuments = CompanyReportAndDocument[];

export interface Page {
  pageId: number;
  pageNumber: number;
  pageTitle: string;
  pageSection: string;
  pageSubTitle: string;
  type: string;
  active: boolean;
  details: Detail[];
  charts: Charts;
  footer: EditorContent;
}

export interface ReportExtras {
  totaltCo2e: number;
  totalEquivalentDriving: number;
  totalEquivalentFlying: number;
  tCO2ePerFTE: number;
  averageCommuteMiles: number;
  scope1Percent: number;
  scope1tCO2e: number;
  scope2Percent: number;
  scope2tCO2e: number;
  scope3Percent: number;
  scope3tCO2e: number;
  carTravelEmissions: number;
  averagePerFTE: number;
  latest: ReportExtras;
}

export interface Report {
  id: number;
  surveyIds: number[];
  companyName: string;
  companyLogoUrl: string;
  title: string;
  status: number;
  dateCreated: Date;
  pages: Page[];
  extras: ReportExtras;
}

export type CreateReport = {
  companyId?: string | number;
  title?: string;
  reportType?: number;
  baselineYear?: number;
  baselineDataPeriodIds?: (number | string)[]

  // TODO: Might need to be new type
  crpType?: number;
  description?: string;
  outputType?: number;
};

export type EditorContentType =
  | 'text'
  | 'ordered-list'
  | 'unordered-list'
  | 'custom';

export interface EditorContent {
  boxAccent?: boolean;
  boxAccentColor?: string;
  header?: string;
  content?: string[];
  type?: EditorContentType;
}

export interface Detail {
  id: number;
  comments: EditorContent;
  imageUrl: string;
}

export interface ReportPageProps {
  report: Report;
  page: Page;
  onContentUpdate: onContentUpdate;
  className?: string;
  downloadVersion?: boolean;
  editable?: boolean;
};

type YearEndsWithReport = {
  id: number;
  title: string;
  status: number;
  dateCreated: Date;
  lastUpdatedDate: Date;
  lastUpdatedBy: number;
  companyID: number;
};

export type YearEndsWithReportStatus = {
  companyInfoId: number;
  companyId: number;
  office: string;
  dateFrom: Date;
  dateTo: Date;
  reports: YearEndsWithReport[];
};

export type SurveyAnswer = {
  slug: string;
  value?: string;
}

export type SurveyAnswers = SurveyAnswer[];

export const reportTypes = [
  "Baseline",
  "Annual",
  "Carbon Reduction Plan"
];

export const outputTypes = [
  "PowerPoint",
  "PDF",
];

export const crpTypes = [
  "PPN 06/21",
];
