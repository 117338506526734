import './styles.scss';

import React from 'react';
import { NumberInput, TextArea } from '@flotilla/component-library';

import { DataPeriod } from '../../../../../../types/DataPeriod';
import { DC_FINANCIALS_ASSETS_TOOLTIP, DC_FINANCIALS_FTE_TOOLTIP } from '../../../../../../assets/content/Tooltips';

interface FinancialsTabContentProps {
  className?: string;
  data?: DataPeriod;
  onChange?: (data: Partial<DataPeriod>) => void;
  submitAttempt?: boolean;
  disabled?: boolean;
};

type Input = {
  key: keyof DataPeriod;
  id: string;
  label: string;
  prependChild?: string;
  financial?: boolean;
  decimal?: boolean;
  tooltipText?: string;
  max?: number;
}

const inputs: Input[] = [
  {
    key: "turnover",
    id: "turnover",
    label: "Turnover*",
    prependChild: "£",
    financial: true,
    decimal: true,
  },
  {
    key: "costOfSales",
    id: "cost-of-sales",
    label: "Cost of sales*",
    prependChild: "£",
    financial: true,
    decimal: true,
  },
  {
    key: "balanceSheet",
    id: "balance-sheet",
    label: "Balance sheet (assets)*",
    prependChild: "£",
    financial: true,
    decimal: true,
    tooltipText: DC_FINANCIALS_ASSETS_TOOLTIP
  },
  {
    key: "fte",
    id: "fte",
    label: "Total full time equivalents (FTE)*",
    financial: true,
    tooltipText: DC_FINANCIALS_FTE_TOOLTIP,
    max: 9999999
  }
];

const FinancialsTabContent: React.FC<FinancialsTabContentProps> = ({
  className,
  data,
  onChange = () => {},
  submitAttempt = false,
  disabled = false
}) => {
  const handleOnChange = (input: Input) => {
    return (initialValue: number | undefined) => {
      const value = typeof initialValue === "number" && !isNaN(initialValue) ? initialValue : undefined;
      if (data?.[input.key] !== value) {
        onChange({ 
          [input.key]: value && input.max && value > input.max ? input.max : value
        })
      }
    }
  }

  return (
    <section id="financials-tab-content" className={className}>
      { data && inputs.map((input) => {
        var alertMessage = undefined;

        if(submitAttempt) {
          if(Number(data[input.key]) < 0)
            alertMessage = 'Value must be greater than 0';
          else if(input.max && Number(data[input.key]) > input.max)
            alertMessage = `Value must be less than or equal to ${input.max}`;
        }

        return <NumberInput
          {...input}
          value={typeof data[input.key] === "number" ? data[input.key] as number : undefined}
          onChange={handleOnChange(input)}
          aria-required={true}
          required={data.createdAt.slice(0, 16) !== data.updatedAt.slice(0, 16) || (submitAttempt && !(Number(data[input.key]) > 0))}
          min={0}
          disabled={disabled}
          alertType={alertMessage ? 'error' : undefined}
          alertMessage={alertMessage}
        />
      })}
      <section id='additional-info-section'>
        <label>Please list any remaining costs exceeding 3% of total spend in the period not covered in previous tabs.</label>
        <label>Specify the nature of each expense and its total cost.</label>
        <TextArea
          id="additionalInfo"
          defaultValue={data?.additionalInfo}
          onChange={(evt) => onChange({ additionalInfo: evt.target.value })}
          disabled={disabled}
          expandToContent
          maxLength={5000}
        />
      </section>
    </section>
  );
}

export default FinancialsTabContent;
