import { API_REPORT } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { CompanyDocument, CompanyDocuments, CompanyReport, CompanyReports, CreateReport, EditorContent, Report, SurveyAnswers, YearEndsWithReportStatus, documentTypes } from "../../types/Report";
import { SurveysWithReportStatus } from "../../types/Survey";

export const getReports = (id: string): Promise<CompanyReports> => {
  return fetch(`${API_REPORT}/GetReportsForCompany?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res && res.payload ? res.payload.map((item: Partial<CompanyReport>) => ({
      ...item,
      reportTypeString: "Carbon Report"
    })) : [] as CompanyReports);
}

export const getReportDocuments = (id: string): Promise<CompanyDocuments> => {
  return fetch(`${API_REPORT}/GetCarbonReportDocuments?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res && res.payload ? res.payload.map((item: Partial<CompanyDocument>) => ({
      ...item,
      fileType: item.file?.type || item.carbonReportUrl?.split(".").pop(),
      reportTypeString: item.reportType || item.reportType === 0 ? documentTypes[item.reportType] ?? "Unknown" : "Unknown"
    })) : [] as CompanyDocuments);
}

export const getReport = (id: number, companyId: string | number): Promise<Report> => {
  return fetch(`${API_REPORT}/GetReport?reportId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      let report = {
        ...res.payload,
        surveyIds: res.payload.surveyId
      } as Report;

      // Split employee commuting page into several if required for multi-office
      var commuteByOfficeIdx = report.pages.findIndex(p => p.pageTitle === "Employee commuting by office" || p.pageTitle === "Employee commuting analysis")

      if(commuteByOfficeIdx > -1) {
        var page = report.pages[commuteByOfficeIdx];
        var wfhData = (page.charts.find(
          (chart) => chart.name === 'Employee commuting by office - WFH'
        ) || { data: [] }).data;

        const keys = Array.from(new Set(wfhData.map((obj) => obj.legend))).sort();
        
        const maxOfficesPerPage = 10;
        var requiredPages = Math.ceil(keys.length / maxOfficesPerPage);
        var itemsPerPage = keys.length / requiredPages;
        var extras = (itemsPerPage % 1) * requiredPages;
        itemsPerPage = Math.floor(itemsPerPage);

        const pages = [];

        while(requiredPages > 0) {
          pages.push({
            ...page,
            pageId: !pages.length ? page.pageId : Number(`100${requiredPages}`),
            pageNumber: 0, // Populate at the end
            details: [{
              id: 0,
              comments: { content: keys.splice(0, itemsPerPage + (extras > 0 ? 1 : 0)) },
              imageUrl: ""
            }],
            type: !pages.length ? page.type : "CustomGenerated"
          });
          requiredPages--;
          extras--;
        }

        report.pages.splice(commuteByOfficeIdx, 1, ...pages);

        // Correct page numbers throughout report
        for(var i = commuteByOfficeIdx; i < report.pages.length; i++) {
          report.pages[i].pageNumber = report.pages[i - 1].pageNumber + 1;
        }
      }
      return report;
    });
}

export const addReportPageDetail = (
  companyId: string | number,
  pageId: number,
  comments: EditorContent | undefined,
  image?: File
) => {
  const data = new FormData();
  data.append('CompanyId', companyId as unknown as string);
  data.append('PageId', pageId as unknown as string);
  if (comments) {
    data.append('Comments.Type', comments.type || 'text');
    data.append('Comments.Header', comments.header || '');
    comments.content?.forEach((commentContent) =>
      data.append('Comments.Content', commentContent || '')
    );
    data.append('Comments.BoxAccent', `${comments.boxAccent || false}`);
    data.append('Comments.BoxAccentColor', comments.boxAccentColor || '');
  }
  data.append('Image', image || '');
  return fetch(`${API_REPORT}/AddPageDetail`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as number);
}

export const setReportPageFooterDetail = (
  pageId: number,
  comments: EditorContent | undefined,
  companyId: string | number,
) => {
  return fetch(`${API_REPORT}/SetPageFooter`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      pageId,
      comments: comments,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as number);
}

export const updateReportPageComment = (pageDetailId: number, comments: EditorContent, companyId: string | number) => {
  return fetch(`${API_REPORT}/UpdatePageComments`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      pageDetailId,
      comments: comments,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean)
}

export const updateReportPageTitles = (companyId: string | number, pageId: number, title?: string, subTitle?: string) => {
  return fetch(`${API_REPORT}/UpdatePageTitles`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      pageId,
      title,
      subTitle,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}

export const updateReportPageImage = (
  companyId: string | number,
  detailId: number,
  image?: File
) => {
  const data = new FormData();
  data.append('CompanyId', companyId as unknown as string);
  data.append('DetailId', detailId as unknown as string);
  data.append('Image', image || '');

  return fetch(`${API_REPORT}/UpdatePageImage`, {
    headers: { "X-CSRF": "1" },
    method: 'PUT',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as number);
}

export const deleteReportPageDetail = (pageDetailId: number, companyId: string | number) => {
  return fetch(`${API_REPORT}/DeletePageDetail?pageDetailId=${pageDetailId}&companyId=${companyId}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}

export const updateReportPageOrder = (
  reportId: number,
  pageId: number,
  pageNumber: number,
  pageSection: string,
  companyId: string | number
) => {
  return fetch(`${API_REPORT}/ChangeReportPageOrder`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      reportId,
      pageId,
      pageNumber,
      pageSection,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as number);
}

export const deleteReportPage = (pageId: number, companyId: string | number) => {
  return fetch(`${API_REPORT}/DeleteReportPage?pageId=${pageId}&companyId=${companyId}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}

export const updateReportStatus = (
  reportId: number,
  status: number,
  companyId: string
) => {
  return fetch(`${API_REPORT}/UpdateReportStatus`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      reportId,
      status,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}

export const updateReportHidden = (
  reportId: number,
  hidden: boolean,
  companyId: string | number
) => {
  return fetch(`${API_REPORT}/UpdateReportHidden`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      reportId,
      hidden,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}

const pageTypesConversion: { [key: string]: number } = {
  "full-page": 6,
  "comments-image-left": 4,
  "comments-image-right": 5,
  "comments-comments": 11,
  "comments-graph-left": 15,
  "comments-graph-right": 16,
}

export const addReportPage = (
  reportId: number,
  pageNumber: number,
  title: string,
  subtitle: string,
  section: string,
  type = "full-page"
) => {
  return fetch(`${API_REPORT}/AddPageToReport`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      reportId,
      pageNumber,
      pageTitle: title,
      pageSubTitle: subtitle,
      pageSection: section,
      type: pageTypesConversion[type],
      active: true,
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as number);
}

export const getYearEndsWithReportStatus = (id: string) => {
  return fetch(`${API_REPORT}/GetYearEndsWithReportStatus?companyId=${id}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as YearEndsWithReportStatus[]);
}

export const getSurveysWithReportStatus = (id: string) => {
  return fetch(`${API_REPORT}/GetSurveysWithReportStatus?companyId=${id}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as SurveysWithReportStatus[]);
}

export const createReport = (report: CreateReport): Promise<string> => {
  return fetch(`${API_REPORT}/CreateReport`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify(report),
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then(({ payload }) => payload as string);
}

// This is for CRP Reports
export const generateReport = (report: CreateReport): Promise<string> => {
  return fetch(`${API_REPORT}/GenerateReport?CompanyId=${report.companyId}&OutputFormat=${report.outputType}&ReportTemplate=1`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then(({ payload }) => payload as string);
}

export const getReportDataCSV = async (id: number, companyId: string | number): Promise<Blob> => {
  return await fetch(`${API_REPORT}/GetReportDataAsCSV?reportId=${id}&companyId=${companyId}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
  .then((res) => res.blob());
};

export const getSurveyDataCSV = async (ids: number[], companyId: string | number): Promise<Blob> => {  
    const surveyIdsUrlParam = `surveyIds=${ids.join('&surveyIds=')}`;

    return await fetch(`${API_REPORT}/GetSurveyResultsAsCSV?${surveyIdsUrlParam}&companyId=${companyId}`, {
        headers: {
            "X-CSRF": "1",
            "Content-Type": "application/json"
        },
        method: 'GET',
    })
        .then((res) => res.blob());
};

export const getSurveyAnswers = async (id: string): Promise<SurveyAnswers> => {
  return await fetch(`${API_REPORT}/GetSurveyAnswers?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res && res.payload ? res.payload.map((item: { slug: string; answerText: string; }) => ({
      slug: item.slug,
      value: item.answerText
    })) : [] as SurveyAnswers);
};

export const uploadDocument = (id: string, document: CompanyDocument): Promise<string> => {
  const data = new FormData();
  data.append('companyId', id);
  document.file && data.append('file', document.file);
  data.append('title', document.title);
  data.append('reportType', document.reportType as unknown as string);
  return fetch(`${API_REPORT}/UploadCarbonReportDocument`, {
    headers: {
      "X-CSRF": "1",
    },
    method: 'POST',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as string);
}

export const hasReportData = async (id: string): Promise<boolean> => {
  return await fetch(`${API_REPORT}/HasReportData?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload as boolean);
}

export const updateDocument = (id: string, document: CompanyDocument): Promise<string> => {
  const data = new FormData();
  data.append('companyId', id);
  data.append('carbonReportDocumentId', document.id as unknown as string);
  document.file && data.append('file', document.file);
  data.append('title', document.title);
  data.append('reportType', document.reportType as unknown as string);
  return fetch(`${API_REPORT}/UpdateCarbonReportDocument`, {
    headers: {
      "X-CSRF": "1",
    },
    method: 'PUT',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as string);
}

export const deleteDocument = async (id: number, companyId: string | number): Promise<boolean> => {
  return await fetch(`${API_REPORT}/DeleteCarbonReportDocument?carbonReportDocumentId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload as boolean);
}

export const deleteReport = async (id: number, companyId: string | number): Promise<boolean> => {
  return await fetch(`${API_REPORT}/DeleteReport?reportId=${id}&companyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload as boolean);
}