const getInitialsFromName = (fullName: string) => {
  const names = fullName.split(' ');
  let initials = names[0][0];

  if (names.length > 1) {
    initials += names[names.length - 1][0];
  }
  return initials?.toUpperCase();
};

export default getInitialsFromName;
