import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { getDataPeriods as getDataPeriodsAPI, reopenDataPeriod as reopenDataPeriodAPI } from "../../api/DataPeriod";
import { useCompanyId } from "../../context";
import { DataPeriods, mapDataPeriods } from "../../types/DataPeriod";
import useCompany from "../Company/useCompany";

const useDataPeriods = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [dataPeriods, setDataPeriods] = useState<DataPeriods>();

  const fetchApi = useCallback(async () => {
    if (company?.id) {
      setLoading(true);
      await getDataPeriodsAPI(company?.id)
        .then((res) => {
          setDataPeriods(res);
        })
        .catch(() => {
          addAlert({ id: `Data Periods Get Failed ${company?.id}`, type: "error", title: "Get Data Periods Unsuccessful", content: "Failed to get Data Periods please try again." });
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const refreshDataPeriods = () => {
    fetchApi();
  }

  const reopenDataPeriod = async (dataPeriodId: string): Promise<void> => {
    setLoading(true);
    return reopenDataPeriodAPI(dataPeriodId, companyId)
      .then((res) => {
        const updatedDataPeriod = res ? mapDataPeriods([res])[0] : undefined;
        setDataPeriods(dataPeriods?.map((item) => (updatedDataPeriod && item.id === dataPeriodId) ? updatedDataPeriod : item));
        addAlert({ id: `Data Period Reopened ${dataPeriodId}`, type: "success", title: "Data Period Reopened" });
      })
      .catch(() => {
        addAlert({ id: `Data Period Unsubmission Failed ${dataPeriodId}`, type: "error", title: "Data Period Reopening Unsuccessful", content: "Data Period failed to reopen please try again." });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (company?.id) {
      fetchApi();
    }
  }, [company, dispatch, fetchApi]);

  return {
    dataPeriods,
    refreshDataPeriods,
    loading,
    reopenDataPeriod
  };
}

export default useDataPeriods;
