import './styles.scss';

import React, { HTMLAttributes, useState } from 'react';
import { Avatar, CrownIcon, Header, IconButton, Menu, MenuItem } from '@flotilla/component-library';

import { User } from '../../../../../types/User';
import { getInitials } from '../../../../../helpers/getInitials';
import StatusTag from '../../../../../components/StatusTag';
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/More.svg';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { addModal, removeModal } from '../../../../../reducers/modal';
import { EditUserModal } from '../../../../../components/Modal';
import useUser from '../../../../../hooks/User/useUser';
import { useUserAccess } from '../../../../../context';
import { useParams } from 'react-router-dom';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement>{
  data: User;
  headers?: Header[];
  onChange?: () => void;
};

const TableRow: React.FC<TableRowProps> = ({
  data,
  className = '',
  headers,
  onChange = () => ({}),
  ...props
}) => {
  const { userAccess } = useUserAccess();
  const { INVITE_USER } = userAccess;
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const {
    user,
    disableUser,
    enableUser,
    removeInvite,
    resendInvite
  } = useUser(data);
  const {
    id,
    fullName,
    email,
    department,
    roles,
    userRolesString,
    status,
    lastActiveDays
  } = user;

  const handleOnClickEdit = () => {
    dispatch(addModal({
      id: 'edit-user',
      children: (
        <EditUserModal
          data={data}
          onClose={() => {
            dispatch(removeModal());
            onChange();
          }}
        />
      )
    }));
  }

  const handleOnClickDisable = async () => {
    disableUser();
    setShowMenu(false);
  }

  const handleOnClickEnable = async () => {
    enableUser();
    setShowMenu(false);
  }

  const handleOnClickRevoke = async () => {
    await removeInvite();
    setShowMenu(false);
    onChange();
  }

  const handleOnClickResendInvite = async () => {
    resendInvite();
    setShowMenu(false);
  }

  return (
    <tr key={id} className={`user-list-item ${className}`} {...props}>
      <td id="name">
        <section id="name-container">
          <Avatar
            className="customer-avatar"
            imgSrcAlt={`${fullName} logo`}
            text={data.fullName ? getInitials(data) : undefined}
            badge={roles?.find((item) => item.name === "Admin") ? {
              children: <CrownIcon variant='dark' />,
              alignment: 'right',
              position: 'top',
              id: 'crown-container'
            } : undefined}
          />
          <p>{fullName}</p>
        </section>
      </td>
      <td>{email}</td>
      <td id="department" title={department?.name} >{department?.name}</td>
      <td id="role-string" title={userRolesString}>{userRolesString}</td>
      <td>{status === 'Active' ? '' : <StatusTag>{status}</StatusTag>}</td>
      <td id="last-active">
        <section>
          <p>{status === 'Invited' ? '' : `${lastActiveDays} ${lastActiveDays === 1 ? 'day ago' : 'days ago'}`}</p>
          {(INVITE_USER || !companyId) && (
            <>
              <IconButton
                id="more-button"
                icon={<MoreIcon/>}
                title="More"
                variant='ghost'
                onClick={() => setShowMenu(!showMenu)}
              />
              { showMenu && (
                <Menu id="more-menu" onClose={() => setShowMenu(false)}>
                  {status !== 'Invited' ? (
                    <>
                      <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
                      { status === 'Active' ? (
                        <MenuItem onClick={handleOnClickDisable}>Disable</MenuItem>
                      ) : (
                        <MenuItem onClick={handleOnClickEnable}>Enable</MenuItem>
                      )}
                    </>
                  ) : (
                    <>
                      <MenuItem onClick={handleOnClickResendInvite}>Resend invite</MenuItem>
                      <MenuItem onClick={handleOnClickRevoke}>Cancel invite</MenuItem>
                    </>
                  )}
                </Menu>
              )}
            </>
          )}
        </section>
      </td>
    </tr>
  );
}

export default TableRow;
