import './styles.scss';

import React, { ChangeEventHandler } from 'react';
import { Address, AddressInput, Select } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import useCountries from '../../../../../../hooks/Lookup/useCountries';

const LocationAddressSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { countries } = useCountries();

  const handleAddressChange = (updatedAddress: Address) => {
    const country = updatedAddress.country ? countries?.find((item) => item.label === updatedAddress.country || item.label.startsWith(updatedAddress?.country || '')) : undefined;
    onChange({
      address: {
        ...updatedAddress,
        countryId: country?.id || 0
      },
    });
  }

  const handleOnChangeCountry: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.currentTarget.value;
    const country = countries?.find((item) => item.id === Number(value));
    onChange({ address: {
      ...values?.["address"],
      country: country?.label,
      countryId: country?.id
    }});
  }

  return (
    <section id="location-address-section" className={className}>
      <AddressInput
        id="location-address"
        label={{children: "Location Address", subtitle: 'First line and Postcode required'}}
        value={values?.["address"]}
        onChange={handleAddressChange}
      />
      { countries && (
        <Select key={values?.['address']?.['countryId']} id="country-select" className="country-select" onChange={handleOnChangeCountry} value={values?.['address']?.['countryId'] || 0}>
          <option value={0} disabled>Choose a Country</option>
          { countries.map((item) => (
            <option value={item.id}>{item.label}</option>
          ))}
        </Select>
      )}
    </section>
  );
}

export default LocationAddressSection;
