import './styles.scss';

import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddIcon, IconButton, Menu, MenuItem, MoreIcon, useAlert } from '@flotilla/component-library';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import useReport from '../../../../hooks/Report/useReport';
import ReportPage, { ReportPageSkeleton } from './Page';
import { ContentsSideBar } from './components/ContentsSideBar';
import { deleteReportPage, getReportDataCSV, getSurveyDataCSV, updateReportPageOrder, updateReportStatus } from '../../../../api/Report';
import { PopUp as AddPagePopUp } from './components/PopUp';
import Header from '../../../../components/HeaderV2';
import { useCompanyId, useUserAccess } from '../../../../context';
import PDFDocument from '../../../../components/PDFReport/PDFDocument';

interface ReportDetailProps {
  className?: string;
};

const ReportDetail: React.FC<ReportDetailProps> = ({
  className = ""
}) => {
  const { addAlert } = useAlert();
  const { userAccess: USER_ACCESS } = useUserAccess();
  const companyId = useCompanyId();
  const { reportId } = useParams();
  const [report, , updateReport, isReportLoading] = useReport(Number(reportId));
  const [loading, setLoading] = useState(false);
  const [isAddPagePopUpOpen, setIsAddPagePopUpOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const container = document.getElementById("report-detail-page");
    if (container) {
      container.scrollTo({ top: scrollPosition });
    }
  }, [report, scrollPosition]);

  const handleContentUpdate = () => {
    const container = document.getElementById("report-detail-page");
    if (container) {
      setScrollPosition(container.scrollTop);
    }
    updateReport();
  }

  const handleOnClickPublish = (publish: boolean = true) => {
    return async () => {
      if (reportId) {
        setShowOptions(false);
        setLoading(true);
        await updateReportStatus(Number(reportId), publish ? 3 : 1, companyId)
          .then(() => {
            updateReport();
            addAlert({ id: `Report ${publish ? 'Publish' : 'Unpublish'} Success ${reportId}`, type: "success", title: `Report ${publish ? 'Publish' : 'Unpublish'} Success` });
          })
          .catch((error) => {
            addAlert({ id: `Report Update Status Failed ${companyId}`, type: "error", title: "Report Update Status Unsuccessful", content: "Report failed to update status please try again." });
            console.log('Error while updating report status: ', error);
          })
          .finally(() => setLoading(false));
      }
    }
  }

  const isEditable = () => {
    // Status = 1 is New
    // Status = 2 is Consulting
    // Status = 3 is Published
    return report?.status === 1;
  };

  const handleAddPageClick = () => {
    setIsAddPagePopUpOpen(true);
  };

  const handleClosePopUp = () => {
    setIsAddPagePopUpOpen(false);
  }

  const handleAddPagePopUp = () => {
    setIsAddPagePopUpOpen(false);
    updateReport();
  }

  const handleMoreClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setShowOptions(!showOptions);
  }

  const handleDownloadReportCSV = async () => {
    if (reportId) {
      setShowOptions(false);
      setLoading(true);
      await getReportDataCSV(Number(reportId), companyId)
        .then((blob) => {
          saveAs(blob, 'ReportData.csv');
        })
        .catch((error) => {
          addAlert({
            id: `Report Download CSV Failed ${reportId}`,
            type: 'error',
            title: 'Error Downloading Report Data CSV',
            content:
              'Something went wrong when downloading your Report Data, please try again',
          });
          console.log('Download Report Data CSV Error: ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleDownloadSurveyCSV = async () => {
    if (report.surveyIds) {
      setShowOptions(false);
      setLoading(true);
      await getSurveyDataCSV(report.surveyIds, companyId)
        .then((blob) => {
          saveAs(blob, 'SurveyData.csv');
        })
        .catch((error) => {
          addAlert({
            id: `Survey Download CSV Failed ${reportId}`,
            type: 'error',
            title: 'Error Downloading Survey Data CSV',
            content:
              'Something went wrong when downloading your Survey Data, please try again',
          });
          console.log('Download Survey Data CSV Error: ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleDownloadReportPDF = async () => {
    setShowOptions(false);
    setLoading(true);
    await pdf(
      <PDFDocument>
        {
          report.pages.map((page) => (
            <ReportPage
              key={page.pageId}
              report={report}
              page={page}
              onContentUpdate={handleContentUpdate}
              downloadVersion
            />
          ))
        }
      </PDFDocument>
    )
      .toBlob()
      .then(pdfBlob => {
        saveAs(pdfBlob, `${report.title.replaceAll(' ', '-')}.pdf`);
      })
      .catch((error) => {
        addAlert({
          id: `Report Download Failed ${reportId}`,
          type: 'error',
          title: 'Error Downloading Report',
          content: 'Something went wrong when downloading report',
        });
        console.log('Error while downloading report: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePageOrder = async (
    id: number,
    pageNumber: number,
    section: string
  ) => {
    updateReportPageOrder(report.id, id, pageNumber + 2, section, companyId)
      .then(() => {
        updateReport();
      })
      .catch((error) => {
        addAlert({
          id: `Report Change Page Failed ${reportId}`,
          type: 'error',
          title: 'Error Changing Page Order',
          content: 'Something went wrong when changing the order of the report page.',
        });
        console.log('Error while changing report page order: ', error);
      });
  };

  const deletePage = async (id: number) => {
    await deleteReportPage(id, companyId)
    .then(() => {
      updateReport();
    })
    .catch((error) => {
      addAlert({
        id: `Report Delete Page Failed ${reportId}`,
        type: 'error',
        title: 'Error Changing Page Order',
        content: 'Something went wrong when changing the order of the report page.',
      });
      console.log('Error while changing report page order: ', error);
    });
  };

  return (
    <section id="report-detail-page" className={className}>
      <Header
        showBackButton
        rightChildren={() =>
          <section id="report-actions">
            { isEditable() && (
              <IconButton
                id="add-page"
                title="Add page"
                isLoading={isReportLoading}
                icon={<AddIcon />}
                onClick={handleAddPageClick}
                small
              />
            )}
            <IconButton
              id="more"
              title="More"
              isLoading={isReportLoading || loading}
              onClick={handleMoreClick}
              icon={<MoreIcon variant="dark" />}
              variant='ghost'
              small
            />
            {
              showOptions &&
                <Menu id="download-options-menu" onClose={() => setShowOptions(false)}>
                  { USER_ACCESS.REPORT_CREATE && reportId ? (
                    <MenuItem onClick={handleOnClickPublish(report.status !== 3)}>{report.status !== 3 ? "Publish Document" : "Unpublish Document"}</MenuItem>
                  ) : <></>}
                  <MenuItem onClick={handleDownloadReportPDF}>Download Report PDF</MenuItem>
                  {report?.surveyIds?.length ? <MenuItem onClick={handleDownloadSurveyCSV}>Download Survey Data CSV</MenuItem> : <></>}
                  <MenuItem onClick={handleDownloadReportCSV}>Download Report Data CSV</MenuItem>
                </Menu>
            }
          </section>
        }
      >
        {report?.title || "Report Detail Page"}
      </Header>
      <main>
        <section>
          {
            isReportLoading ? (
              Array.from(Array(3))?.map((_, index) => (
                <ReportPageSkeleton
                  key={index}
                />
              ))
            ) : (
              report?.pages.map((page) => (
                <ReportPage
                  key={page.pageId}
                  report={report}
                  page={page}
                  onContentUpdate={handleContentUpdate}
                  editable={isEditable()}
                />
              ))
            )
          }
        </section>
      </main>

      {report?.pages && (
        <ContentsSideBar
          id="contents-side-bar"
          pages={report.pages.slice(2, report.pages.length - 1)}
          onChangePage={changePageOrder}
          onDeletePage={deletePage}
        />
      )}

      {
        (isAddPagePopUpOpen && report) &&
          <AddPagePopUp
            reportId={report.id}
            onClose={handleClosePopUp}
            onAddPage={handleAddPagePopUp}
            initialPageNumber={report?.pages?.length || 0}
          />
      }
    </section>
  );
}

export default ReportDetail;
