import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  getSupplyChainShades,
  getFacilitiesShades,
  getEnergyShades,
  getTravelShades,
  getPeopleShades,
  getBuildingsShades,
} from '../../../../../../helpers/colors';
import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Report';
import { Chart } from '../../../../../../types/Chart';
import ReportTag from '../../../../../../components/PDFReport/ReportTag';
import moneyFormatter from '../../../../../../helpers/moneyFormatter';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import sortChartYears from '../../../../../../helpers/sortChartYears';

const styles = StyleSheet.create({
  impactAreaBreakdownPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    height: '80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12
  },
  side: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  headerContainer: {
    width: '60%',
    minHeight: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 12,
  },
  headerTagContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    marginBottom: 15,
  },
  tagContainer: {
    flex: 1,
    minWidth: 50,
    minHeight: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subHeaderTagContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  note: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
});

interface ImpactAreaBreakdownProps extends ReportPageProps {
  impactArea?: string;
};

type ChartData = {
  year: string,
  legend: string,
  spendValue: number,
  tco2eValue: number,
}

type EmissionFactor = {
  legend: string;
  value: number;
}

const ImpactAreaColor: { [key: string]: string[]} = {
  travel: getTravelShades(),
  energy: getEnergyShades(),
  facilities: getFacilitiesShades(),
  'supply chain': getSupplyChainShades(),
  people: getPeopleShades(),
  buildings: getBuildingsShades(),
};

const convertData = (chart: Chart): [ChartData[], string[], (string | string[])[]] => {
  const {
    xLabels,
    data
  } = chart;
  const groups = [] as EmissionFactor[];
  let years = Array.from(new Set(xLabels.map((xLabel) => xLabel.split(' [')[0]))).sort();

  // Ensure only Baseline, Previous and Current years are shown
  if(years.length > 3)
    years = years.slice(0, 1).concat(years.slice(-2));

  // Group data on 'legend', using the highest emissions value as the value for ordering
  data.forEach(item => {
    const { legend, xLabel } = item;
    let value = 0;
    if (xLabel.includes("[tCO2e]") && (years.length < 3 || !xLabel.includes("(Baseline)"))) {
      // Only use the value if it represents a tCO2e value AND it is either the current or previous year
      value = item.value;
    }
    const existingGroup = groups.find(group => group.legend === legend);
    if (!existingGroup) {
      groups.push({ legend, value });
    } else if (value > existingGroup.value) {
      existingGroup.value = value;
    }
  });

  const legends: (string | string[])[] = groups.sort((a, b) => b.value - a.value).splice(0, 5).map((dataItem) => dataItem.legend);
  legends.push([...groups.map((dataItem) => dataItem.legend)]);
  const convertedData: ChartData[] = [];

  years.forEach((year) => {
    legends.forEach((legendValue) => {
      const dataItem: ChartData = {
        year: year,
        legend: Array.isArray(legendValue) ? "Others" : legendValue,
        spendValue: 0,
        tco2eValue: 0
      };
      const legendData = data.filter((dataItem) => dataItem.xLabel.includes(year) && (Array.isArray(legendValue) ? legendValue : [legendValue]).includes(dataItem.legend));
      legendData.forEach((legendDataItem) => {
        if (legendDataItem.xLabel.toLowerCase().includes("spend")) {
          dataItem.spendValue += legendDataItem.value || 0;
        } else if (legendDataItem.xLabel.toLowerCase().includes("tco2e")) {
          dataItem.tco2eValue += legendDataItem.value || 0;
        }
      })
      convertedData.push(dataItem);
    })
  })

  return [convertedData, years, legends];
}

export const ImpactAreaBreakdown: React.FC<ImpactAreaBreakdownProps> = ({
  page,
  className,
  impactArea = 'travel & logistics',
  downloadVersion = false,
  onContentUpdate,
  editable
}) => {
  const { details, charts } = page;
  const colors: string[] = ImpactAreaColor[impactArea] ?? getTravelShades();

  const [chartData, years, legends] = convertData(charts[0]);
  const statData: { legend: string, data: ChartData[] }[] = legends.map((legend) => {
    const legendValue = Array.isArray(legend) ? "Others" : legend;
    const data: ChartData[] = chartData.filter((value) => value.legend.toLowerCase() === legendValue.toLowerCase());
    return {
      legend: legendValue,
      data
    };
  })

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`impact-area-breakdown-page ${className}`}
      style={styles.impactAreaBreakdownPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="item-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportDiv className="header-container" style={styles.headerContainer} downloadVersion={downloadVersion}>
            {
              years.sort((a, b) => sortChartYears({ key: a }, { key: b })).map((year, index) => (
                <ReportDiv key={index} className="header-tag-container" style={styles.headerTagContainer} downloadVersion={downloadVersion}>
                  <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                    <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>{ year }</ReportTag>
                  </ReportDiv>
                  <ReportDiv className="sub-header-tag-container" style={styles.subHeaderTagContainer} downloadVersion={downloadVersion}>
                    <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                      <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>tCO2e</ReportTag>
                    </ReportDiv>
                    <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                      <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>Spend</ReportTag>
                    </ReportDiv>
                  </ReportDiv>
                </ReportDiv>
              ))
            }
              {years.length > 1 && <ReportDiv className="header-tag-container year-on-year-header" style={styles.headerTagContainer} downloadVersion={downloadVersion}>
                <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                  <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>Year on Year</ReportTag>
                </ReportDiv>
                <ReportDiv className="sub-header-tag-container" style={styles.subHeaderTagContainer} downloadVersion={downloadVersion}>
                  <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                    <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>tCO2e</ReportTag>
                  </ReportDiv>
                  <ReportDiv className="tag-container" style={styles.tagContainer} downloadVersion={downloadVersion}>
                    <ReportTag className='header-tag' size="small" downloadVersion={downloadVersion}>Spend</ReportTag>
                  </ReportDiv>
                </ReportDiv>
              </ReportDiv>}
          </ReportDiv>
          {statData
            .map((stat, index) => (
              <StatCard
                key={index}
                label={`${index + 1}. ${stat.legend}`}
                data={stat.data}
                color={colors[index]}
                downloadVersion={downloadVersion}
              />
          ))}
          <StatCard
            label="Total"
            data={
              years.map((year) => ({
                year: year,
                legend: "Total",
                spendValue: chartData.reduce((prev, curr) => prev + (curr.year === year ? curr.spendValue : 0), 0),
                tco2eValue: chartData.reduce((prev, curr) => prev + (curr.year === year ? curr.tco2eValue : 0), 0)
              }))
            }
            downloadVersion={downloadVersion}
          />
        </ReportDiv>
        <ReportP
          className="note"
          style={styles.note}
          downloadVersion={downloadVersion}
        >
          The expenditure displayed above is contingent on the availability of the relevant data points
        </ReportP>
        {details[1] ? (
            <ReportEditor
              className="comment-editor"
              contentId={details[1].id}
              editorContent={details[1].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          ) : (
            !downloadVersion && editable && (
              <ReportEditor
                pageId={page.pageId}
                editorContent={{
                  boxAccent: true,
                  boxAccentColor: 'dark',
                  header: 'Comments',
                  content: [],
                  type: 'text',
                }}
                newEditor
                onChange={() => onContentUpdate()}
              />
            )
          )}
      </ReportDiv>
    </ReportPage>
  );
};

type StatCardProps = {
  label: string;
  data: ChartData[];
  downloadVersion?: boolean;
  color?: string;
};

const StatCard: React.FC<StatCardProps> = ({
  label,
  data,
  downloadVersion,
  color = "#EFEFED"
}) => {
  const statCardStyles = StyleSheet.create({
    statCard: {
      width: '100%',
      height: 30,
      paddingLeft: 12,
      gap: 4,
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#EFEFED',
      borderRadius: 4,
      borderLeft: '4px solid #EFEFED',
      alignItems: 'center',
      marginBottom: 3
    },
    label: {
      width: '40%'
    },
    value: {
      flex: 1,
      textAlign: 'center'
    },
    values: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    valueContainer: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
      borderRight: '2px solid #FBFAF8',
    },
    lastChild: {
      borderRight: 'none'
    },
    yearOnYearValue: {
      top: -6
    },
    percentage: {
      top: 6,
      fontSize: 9
    }
  });

  const previous = data.length > 2 ? data[1] : data[0];
  const current = data.length > 2 ? data[2] : data[1];
  
  return (
    <ReportDiv
      className="stat-card"
      style={{
        ...statCardStyles.statCard,
        borderLeftColor: color
      }}
      divStyle={{ borderColor: color }}
      downloadVersion={downloadVersion}
    >
      <ReportP
        className="label"
        downloadVersion={downloadVersion}
        style={statCardStyles.label}
      >
        {label}
      </ReportP>
      <ReportDiv className='values' style={statCardStyles.values} downloadVersion={downloadVersion}>
        {
          data.map((dataItem, index) => (
            <ReportDiv
              key={index}
              className='value-container'
              style={statCardStyles.valueContainer} 
              downloadVersion={downloadVersion}
            >
              <ReportP
                className="tco2e value"
                style={statCardStyles.value}
                downloadVersion={downloadVersion}
                >
                {numberLocaleFormatter(dataItem.tco2eValue, 1)}
              </ReportP>
              <ReportP
                className="spend value"
                style={statCardStyles.value}
                downloadVersion={downloadVersion}
                >
                {moneyFormatter(dataItem.spendValue || 0, undefined, true)}
              </ReportP>
            </ReportDiv>
          ))
        }
        {previous && current && <ReportDiv
          className='value-container year-on-year'
          style={{...statCardStyles.valueContainer, ...statCardStyles.lastChild}} 
          downloadVersion={downloadVersion}
        >
          <ReportDiv
            className="tco2e value"
            style={statCardStyles.value}
            downloadVersion={downloadVersion}
            >
            <ReportP style={{...statCardStyles.value, ...statCardStyles.yearOnYearValue}}
              downloadVersion={downloadVersion}>
              {numberLocaleFormatter(current.tco2eValue - previous.tco2eValue, 1)}
            </ReportP>
            <ReportP className="percentage" 
              style={statCardStyles.percentage}
              downloadVersion={downloadVersion}>
              ({previous.tco2eValue > 0 ? numberLocaleFormatter(((current.tco2eValue - previous.tco2eValue) / previous.tco2eValue) * 100) + '%' : '-'})
            </ReportP>
          </ReportDiv>
          <ReportDiv
            className="spend value"
            style={statCardStyles.value}
            downloadVersion={downloadVersion}
            >
            <ReportP style={{...statCardStyles.value, ...statCardStyles.yearOnYearValue}}
              downloadVersion={downloadVersion}>
                {moneyFormatter(current.spendValue - previous.spendValue, undefined, true, false)}
            </ReportP>
            <ReportP className="percentage" 
              style={statCardStyles.percentage}
              downloadVersion={downloadVersion}>
              ({previous.spendValue > 0 ? numberLocaleFormatter(((current.spendValue - previous.spendValue) / previous.spendValue) * 100) + '%' : '-'})
            </ReportP>
          </ReportDiv>
        </ReportDiv>}
      </ReportDiv>
    </ReportDiv>
  );
};
