import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Alert, CheckboxButtonV2, Skeleton, useAlert } from '@flotilla/component-library';

import { Link } from 'react-router-dom';
import { NO_DATA_FOR_BASELINE_YEAR_MESSAGE } from '../../../../AlertMessages';
import { getAvailableBaselineYears, getValidateBaselineYearDataPeriods } from '../../../../../../api/DataPeriod';
import { useCompanyId } from '../../../../../../context';
import { SectionProps } from '../../../../../../types/CreateWizard';
import { DataPeriods } from '../../../../../../types/DataPeriod';

const ReportBaselineYearSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [years, setYears] = useState<string[]>();
  const [dataPeriods, setDataPeriods] = useState<DataPeriods>();

  useEffect(() => {
    getAvailableBaselineYears(companyId)
      .then(res => setYears(res))
      .catch(() => {
        addAlert({ id: `Get Available Baseline Years Failed`, type: "error", title: "Failed to retrieve available baseline years", content: "Something went wrong, please try again." });
        setYears([]);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleTypeChange = (year: string) => {
    return (checked: boolean) => {
      onChange({ baselineYear: checked ? year : 0 });
    }
  }

  const getAlertContent = () => {
    const content = NO_DATA_FOR_BASELINE_YEAR_MESSAGE.split('%%LINK%%')
    return (
      <p>{content[0]} <Link to='../../datacollection'>Click here</Link> {content[1]}</p>
    );
  };

  const checkValid = () => {
    const year = parseInt(values?.["baselineYear"]?.toString().split(' ')[0] ?? "");
    Number(year) && getValidateBaselineYearDataPeriods(companyId, Number(year))
      .then(res => {
        setDataPeriods(res);
        onChange({ baselineDataPeriodIds: (res?.length || 0) > 0 ? undefined : [] });
      });
  }

  useEffect(() => {
    checkValid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.['baselineYear']]);

  const handleDataPeriodChange = (id: number | string) => {
    return (checked: boolean) => {
      onChange({ baselineDataPeriodIds: checked ? [id] : [] });
    }
  }

  return (
    <section id="report-baseline-year-section" className={className}>
      { years && years.length === 0 && (
        <Alert
          type='info'
          title='No Data Submitted'
          content={getAlertContent()}
        />
      )}
      <article className="options">
        { years && years.map((year, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={String(year)}
            checked={year === values?.["baselineYear"]}
            onToggle={handleTypeChange(year)}
          />
        )}
        { !years && (
          <>
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
          </>
        )}
      </article>
      { ((dataPeriods?.length || 0) > 0) && (
        <article className="options" id="data-periods">
          <p>Select which Data Period you want to associate to this Baseline Year:</p>
          { dataPeriods && dataPeriods.map((dataPeriod, index) => 
            <CheckboxButtonV2
              className='checkbox'
              key={index}
              label={String(dataPeriod.name)}
              checked={dataPeriod.id === values?.["baselineDataPeriodIds"]?.[0]}
              onToggle={handleDataPeriodChange(dataPeriod.id)}
            />
          )}
        </article>
      )}
    </section>
  );
}

export default ReportBaselineYearSection;
