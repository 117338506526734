type GeneralSortType = {
  [key: string]: any;
}

const convertKeyToValue = (key: string): string => {
  const keySplit = key.split(' ');
  return `${keySplit[1]} ${keySplit[0]}`;
}

const sortChartYears = (a: GeneralSortType, b: GeneralSortType): number => {
  const aValue = convertKeyToValue(a.key || a.xLabel);
  const bValue = convertKeyToValue(b.key || b.xLabel);
  return bValue < aValue ? 1 : -1;
}

export default sortChartYears;
