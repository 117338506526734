import './styles.scss';

import { CircularProgress, DownloadIcon, IconButton, Tag, useAlert } from '@flotilla/component-library';
import { saveAs } from 'file-saver';

import { ReactComponent as EmailIcon } from '../../../../../assets/icons/Email.svg';
import { useCompanyId, useUserAccess } from '../../../../../context';
import CopyButton from '../../../../../components/CopyButton';
import { EMPLOYEE_SURVEY_EMAIL_BODY } from '../../../../../assets/content/EmailTemplates';
import { Survey } from '../../../../../types/Survey';
import { MouseEventHandler, useState } from 'react';
import { getSurveyDataCSV } from '../../../../../api/surveys';
import ActiveTag from '../../../../../components/ActiveTag';
import { numberLocaleFormatter } from '../../../../../helpers/numberFormatter';
import { useCompany } from '../../../../../hooks';

type SurveyListItemProps = {
  data: Survey;
  onClick?: () => void;
};

const SurveyListItem: React.FC<SurveyListItemProps> = ({
  data,
  onClick = () => ({}),
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const {
    engagementCompanySurveyId,
    pUrl: link,
    surveyType: type,
    fromDate,
    toDate,
    active,
    sentTo: employeesNumber,
    responses,
    responseRate,
  } = data;
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { userAccess } = useUserAccess();
  const { ADMIN, LICENSOR, SURVEY_EDIT } = userAccess;
  const hideLink = !ADMIN && !LICENSOR && companyId !== "0";
  const handleOnClick = () => {
    onClick();
  };

  const handleDownloadSurvey: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
      if (engagementCompanySurveyId && SURVEY_EDIT) {
      setIsDownloadLoading(true);
          await getSurveyDataCSV(engagementCompanySurveyId, company.identityCompanyId)
        .then((blob) => {
          saveAs(blob, 'SurveyData.csv');
        })
        .catch((error) => {
          addAlert({
            id: `Downloading Survey Data CSV ${engagementCompanySurveyId}`,
            type: 'error',
            title: 'Error Downloading Survey Data CSV',
            content:
              'Something went wrong when downloading your Survey Data, please try again',
          });
          console.log('Download Survey Data CSV Error: ', error);
        })
        .finally(() => {
          setIsDownloadLoading(false);
        });
    }
  }

  return (
    <tr className="survey-list-item" onClick={handleOnClick}>
      <td className="link-and-type-cell">
        <section id="link-container">
          <p className={`link${hideLink ? ' blur' : ''}`}>{link}</p>
          {hideLink ? <></> : <CopyButton text={link}/>}
        </section>
        <section className="type-container">
          {(responseRate === null && responses === null) && (
            <Tag size='small' className="type error">Error</Tag>
          )}
          {type && <Tag size='small' className="type">{type}</Tag>}
          <ActiveTag size='small'>{active? "Active" : "Inactive"}</ActiveTag>
        </section>
      </td>

      <td id="from-date">
        {fromDate ? new Date(fromDate).toLocaleDateString() : ''}
      </td>
      <td id="to-date">
        {toDate ? new Date(toDate).toLocaleDateString() : ''}
      </td>
      <td id="employee">
        {numberLocaleFormatter(employeesNumber || 0)}
      </td>
      <td id="response">
        {numberLocaleFormatter(responses || 0)}
      </td>
      <td id="response-rate">
        <section id="response-rate-container">
          <CircularProgress score={Number(responseRate?.toFixed() || 0)} />
          { !hideLink && (
            <a href={`mailto:?body=${encodeURIComponent(
              EMPLOYEE_SURVEY_EMAIL_BODY
                .replace("%%LINK%%", link || "")
                .replace("%%TODATE%%", toDate ? new Date(toDate).toLocaleDateString('en-GB', {weekday: 'short', day: 'numeric', month: 'long', year: 'numeric'}) : '{insert completion date - 2 weeks from launch}'))
            }`}>
              <IconButton
                id="email-button"
                title="Email Link"
                variant='ghost'
                icon={<EmailIcon />}
                onClick={(e) => e.stopPropagation()}
              />
            </a>
          )}
          { SURVEY_EDIT && (
            <IconButton
              id="download-button"
              title="Download Survey"
              variant='ghost'
              icon={<DownloadIcon variant='dark' />}
              onClick={handleDownloadSurvey}
              isLoading={isDownloadLoading}
            />
          )}
        </section>
      </td>
    </tr>
  );
};

export default SurveyListItem;
