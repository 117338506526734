import { useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { ActivityNote } from "../../types/DataPeriod";
import { updateActivityNote as updateActivityNoteAPI } from "../../api/DataPeriod";

const useActivityNote = (note: ActivityNote) => {
  const { addAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [activityNote, setActivityNote] = useState<ActivityNote>(note);
  
  const saveActivityNote = async (companyId : number | string): Promise<ActivityNote | void> => {
    if (activityNote && activityNote.dataPeriodId) {
      setLoading(true);
      return await updateActivityNoteAPI(activityNote.dataPeriodId, activityNote, companyId)
        .then((res) => {
          addAlert({ id: `Note Saved ${activityNote.dataPeriodId}`, type: "success", title: "Note Saved Successfully" });
          setUpdated(false);
          return res;
        })
        .catch(() => {
          addAlert({ id: `Note Save Failed ${activityNote.dataPeriodId}`, type: "error", title: "Note Save Unsuccessful", content: "Note failed to save please try again." });
        })
        .finally(() => setLoading(false));
    }
  }

  const updateActivityNote = (updatedNote: Partial<ActivityNote>) => {
    setActivityNote({
      ...activityNote,
      ...updatedNote
    });
    setUpdated(true);
  }
  
  useEffect(() => {
    setActivityNote(note);
  }, [note]);

  return {
    activityNote,
    saveActivityNote,
    updateActivityNote,
    loading,
    updated
  };
}

export default useActivityNote;
