import { mapNetZeroPlanAction } from "../api/netZeroPlan";
import { NetZeroPlanAction, StatusType } from "./NetZeroPlan";
import { Activity } from "./Activity";
import { Lookups, mapLookups } from "./Lookup";
import { Locations } from "./Location";

export const mapActions = (data: {[key: string]: unknown}[], userId?: number): Actions => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => {
    const netZeroPlanAction = ((item?.netZeroPlanActions as unknown[]).length || 0) > 0 ? mapNetZeroPlanAction(item.netZeroPlanActions as {[key: string]: unknown}[], item.netZeroPlanActionId) : undefined;
    const inPlan = netZeroPlanAction || false;
    const sdgItems = mapSDGs(item?.sustainableDevelopmentGoals);
    const carbonSavingsPotential = (item?.carbonSavingsPotential || 0) > 0 ? item.carbonSavingsPotential : 0;
    const coBenefitsPotential = (item?.coBenefitsPotential || 0) > 0 ? item.coBenefitsPotential : 0;
    const costSavingsPotential = (item?.costSavingsPotential || 0) > 0 ? item.costSavingsPotential : 0;
    const scopeCategoryIds = Array.from(new Set(item?.activities?.map((item: any) => item?.scopeCategory?.id))).filter((id) => id) as number[];
    const scopeCategories = scopeCategoryIds?.map((id) => item?.activities.find((activity: any) => activity.scopeCategory.id === id).scopeCategory) as ScopeCategories;
    const products = mapLookups(item.products || []);
    const impactAreaName = item?.impactArea ? (item?.impactArea as ImpactArea).name : '';

    return {
      ...item,
      ...(netZeroPlanAction ? {netZeroPlanAction: netZeroPlanAction} : {}),
      type: actionTypes[(item?.type || 1) as number - 1] as ActionType,
      carbonSavingsPotential: potentialType[carbonSavingsPotential] as PotentialType,
      coBenefitsPotential: potentialType[coBenefitsPotential] as PotentialType,
      costSavingsPotential: potentialType[costSavingsPotential] as PotentialType,
      departmentsString: (item?.departments as Departments || [] as Departments).map((department) => department.name).join(" | "),
      departmentNames: (item?.departments as Departments || [] as Departments).map((department) => department.name),
      impactAreaName,
      status: netZeroPlanAction?.status,
      tags: netZeroPlanAction?.tags ? netZeroPlanAction?.tags : [],
      startDate: netZeroPlanAction?.startDate ? netZeroPlanAction?.startDate as string : null,
      targetCompletionDate: netZeroPlanAction?.targetCompletionDate ? netZeroPlanAction?.targetCompletionDate as string : null,
      estReduction: netZeroPlanAction?.estimatedUptakePercent ? (netZeroPlanAction?.estimatedReductionPercent ?? item?.avoidancePercent ?? 0) * (netZeroPlanAction.estimatedUptakePercent / 100) : 0,
      estimatedCost: netZeroPlanAction?.estimatedCost ? netZeroPlanAction.estimatedCost : 0,
      estimatedEffort: netZeroPlanAction?.estimatedEffort ? netZeroPlanAction.estimatedEffort : 0,
      estimatedROI: netZeroPlanAction?.estimatedROI ? netZeroPlanAction.estimatedROI : 0,
      focusGroupName: item?.focusGroup?.name ? item.focusGroup.name : undefined,
      activityName: item?.activities && item.activities.length > 0 ? `${item.activities[0].name}${item.activities.length > 1 ? ` +${item.activities.length - 1}` : ''}` : undefined,
      activityNames: item?.activities.map((ng: Activity) => ng.name),
      activityIds: item?.activities.map((ng: Activity) => ng.id),
      inPlan: inPlan,
      notInPlan: !inPlan,
      assignedTo: netZeroPlanAction?.user?.fullName ? netZeroPlanAction?.user.fullName : "Unassigned",
      myAction: netZeroPlanAction?.user?.sub === userId,
      actionTags: item?.tags ? (item.tags as string).split("|") : [],
      actionTagsString: item?.tags,
      isActive: item?.isActive,
      isNotActive: !(item?.isActive),
      activeStatus: item?.isActive ? "Active" : "Inactive",
      sdgs: sdgItems,
      sdgNames: sdgItems.map((sdg) => `SDG ${sdg.goalNumber}`),
      employeeSuggested: item.employeeSurveyEnabled && item.suggestedCount > 0,
      scopeCategories,
      scopeCategoryName: scopeCategories.length > 0 ? `${scopeCategories[0].name}${scopeCategories.length > 1 ? ` +${scopeCategories.length - 1}` : ''}` : undefined,
      scopeCategoryNames: scopeCategories.map((item) => item.name),
      scopeId: scopeCategories[0]?.scopeId,
      scopeName: scopeCategories[0]?.scopeId ? `Scope ${scopeCategories[0]?.scopeId}` : undefined,
      scope: {
        id: scopeCategories[0]?.scopeId,
        name: `Scope ${scopeCategories[0]?.scopeId}`
      },
      childActions: item.childActions ? mapActions(item.childActions as {[key: string]: unknown}[]) : [],
      parentActions: item.parentActions ? mapActions(item.parentActions as {[key: string]: unknown}[]) : [],
      companiesUsingAction: item.companiesUsingAction,
      companiesUsingActionCount: item.companiesUsingAction?.length ?? 0,
      products: products,
      productNames: products.map((item) => item.label),
      locationName: netZeroPlanAction?.location?.name || 'No Location',
      locationCount: impactAreaName.toLowerCase() === 'buildings' ? item.netZeroPlanActions.length : 0,
    }
  }) as unknown[] as Actions;
}

export const mapSDGs = (data: {[key: string]: unknown}[]): SDGs => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => {
    const sdtItems = item?.sustainableDevelopmentTargets.map((sdtItem: { [key: string]: unknown }) => ({
      ...sdtItem,
      label: sdtItem.description,
    })) as SDTs;

    return {
      ...item,
      label: item.title,
      imgURL: item.logoUrl,
      sdts: sdtItems,
    }
  }) as unknown[] as SDGs;
}

export const actionTypes = ["Action", "Task"];
export type ActionType = typeof actionTypes[number];

export const potentialType = ["None", "Low", "Medium", "High"];
export type PotentialType = typeof potentialType[number];

export const impactAreaValues = ["Travel & Logistics", "Facilities", "Supply chain", "Governance", "Buildings", "People"];

export interface Action {
  id: number;
  impactAreaId?: number,
  focusGroupId?: number,
  focusGroupName?: string,
  activityIds?: number[],
  activityName?: string,
  activityNames?: string[],
  title: string,
  description: string,
  defaultPriority: number,
  supportNotes: string,
  flotillaWorldUrl: string,
  avoidancePercent: number,
  carbonSavingsPotential: PotentialType,
  coBenefitsPotential: PotentialType,
  costSavingsPotential: PotentialType,
  type: ActionType,
  createdAt: string,
  updatedAt: string,
  impactArea: ImpactArea | null,
  impactAreaName: string,
  scope?: Scope,
  scopeId?: number,
  scopeName?: string,
  departments: Departments,
  departmentNames?: string[],
  departmentsString: string,
  children?: Actions;
  netZeroPlanAction?: NetZeroPlanAction;
  netZeroPlanActionId?: number;
  netZeroPlanActions?: NetZeroPlanAction[];
  quickWin?: boolean;
  parentAction?: Action;
  estimatedtCO2eSavings?: number;
  estimatedUptakeRequired?: boolean;
  status?: StatusType;
  tags?: string[];
  actionTags?: string[];
  actionTagsString?: string;
  startDate?: string;
  targetCompletionDate?: string;
  estReduction?: number;
  inPlan?: boolean;
  notInPlan?: boolean;
  assignedTo?: string;
  myAction?: boolean;
  ranking?: number;
  isActive?: boolean;
  isNotActive?: boolean;
  activeStatus?: string;
  estimatedCost?: number;
  employeeSurveyEnabled?: boolean;
  sdgs: SDGs;
  sdts: SDTs;
  sdgNames?: string[],
  suggestedCount?: number,
  employeeSuggested: boolean;
  companyId?: string;
  scopeCategories?: ScopeCategories;
  scopeCategoryName?: string;
  scopeCategoryNames?: string[];
  parentActions?: Actions;
  childActions?: Actions;
  recommendedCompanyIdsCount?: number;
  recommendedCompanyIds?: number[];
  recommended?: boolean;
  companiesUsingAction?: string[];
  products?: Lookups;
  productNames?: string[];
  isRecurring?: boolean;
  locationName?: string;
  locationEmissions?: LocationEmissions;
  locationCount?: number;
}

export interface UpdateAction {
  id: number,
  netZeroPlanId: number,
  status: StatusType,
  targetDate?: string,
  completionDate?: string,
  currentUptake?: number,
  targetUptake?: number,
  startDate?: string,
  tags?: string[],
  userId?: number,
  notes?: string,
  estimatedCost?: number,
  estimatedROI?: number,
  estimatedEffort?: number,
  estimatedReductionPercent?: number
}

export interface AddAction {
  id: number,
  companyId: string | number,
  netZeroPlanId: number,
  uptake?: number,
  completionDate?: string,
  startDate?: string,
  priority?: number,
  estimatedCost?: number,
  estimatedROI?: number,
  estimatedEffort?: number,
  estimatedReductionPercent?: number
  locations?: Locations;
}

interface ImpactArea {
  id: number,
  name: string,
  category: string,
}

interface Department {
  id: number,
  name: string,
  createdAt?: string,
  updatedAt?: string,
}

export interface Scope {
  id: number,
  name: string,
  createdAt?: string,
  updatedAt?: string,
}

export interface ScopeCategory {
  id: number,
  name: string,
  scopeId: number
}

export interface SDG {
  id: number,
  goalNumber: number,
  label: string,
  imgURL?: string,
  sdts?: SDTs,
}

export interface SDT {
  id: number,
  label: string,
  targetNumber?: string;
}

export interface LocationEmission {
  locationId: number,
  locationName: string,
  estimatedtCO2eSavings: number;
}

export type LocationEmissions = LocationEmission[];

export type RecommendedActionType = "impactArea" | "activity" | "focusGroup" | "scope" | "scopeCategory";

export type Departments = Department[];

export type Scopes = Scope[];

export type ScopeCategories = ScopeCategory[];

export type Actions = Action[];

export type SDTs = SDT[];

export type SDGs = SDG[];

export type RecommendAction = {
  actionId: number | string;
  recommendingCompanyId: number | string;
  companyIds: number[];
};

export type RecommendSingleAction = {
  actionId: number | string;
  recommendingCompanyId: number | string;
  companyId: number;
  recommend?: boolean;
};

export const getInitialAction = (): Action => ({
  id: 0,
  impactAreaId: 0,
  focusGroupId: 0,
  activityIds: [],
  title: "",
  description: "",
  defaultPriority: 4,
  supportNotes: "",
  flotillaWorldUrl: "",
  avoidancePercent: 0,
  carbonSavingsPotential: "low",
  coBenefitsPotential: "low",
  costSavingsPotential: "low",
  type: "Action",
  createdAt: "",
  updatedAt: "",
  impactArea: null,
  departments: [],
  departmentsString: "",
  impactAreaName: "",
  isActive: false,
  employeeSurveyEnabled: false,
  sdgs: [],
  sdts: [],
  employeeSuggested: false,
});
