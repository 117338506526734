import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, NumberInput, SaveIcon, Select, TextInput } from '@flotilla/component-library';

import { ReactComponent as CopyIcon } from '../../../../assets/icons/Copy.svg';
import { useCompany } from '../../../../hooks';
import QuestionsList from '../QuestionsList';
import { FullCompanyQuestions } from '../../../../types/Company';
import { useUserAccess } from '../../../../context';
import Header from '../../../../components/HeaderV2';
import useSurvey from '../../../../hooks/Survey/useSurvey';

interface SurveyDetailProps {
  className?: string;
};

const SurveyDetail: React.FC<SurveyDetailProps> = ({
  className = ""
}) => {
  const { companyId = "" } = useParams();
  const [company] = useCompany(companyId);
  const { userAccess } = useUserAccess();
  const { CREATE_QUESTION, SURVEY_EDIT } = userAccess;
  const {
    survey,
    saveSurvey,
    updateSurvey,
    loading
  } = useSurvey();
  const [defaultQuestions, setDefaultQuestions] = useState<number[]>();
  const surveyUrl = survey?.pUrl || '';

  useEffect(() => {
    setDefaultQuestions(survey?.companySurveyQuestions?.map(q => q.companyQuestionId))
  }, [survey]);

  const handleOnClickSave = () => {
    saveSurvey();
  };

  const handleLinkClick = () => {
    surveyUrl && navigator.clipboard.writeText(surveyUrl);
  }

  const handleOnChangeNumber = (key: string) => {
    return (value: number | undefined) => {
      updateSurvey({ [key]: value || 0})
    }
  }

  const handleOnChangeDate = (key: string): React.FocusEventHandler<HTMLInputElement> => {
    return (event) => {
      updateSurvey({ [key]: event.currentTarget.value});
    }
  }

  const handleOnChangeQuestions = (updatedQuestions: FullCompanyQuestions) => {
    updateSurvey({ includedQuestions: updatedQuestions?.map(q => q.id) ?? (defaultQuestions || []) });    
  }

  return (
    <section id="survey-detail-page" className={className}>
      <Header
      showBackButton
        subtitle={surveyUrl && (
          <section id="link-container" onClick={handleLinkClick}>
            <p>{surveyUrl}</p>
            <IconButton
              variant='ghost'
              icon={<CopyIcon />}
              onClick={handleLinkClick}
              title="Copy Link"
              label="Copy Link"
            />
          </section>
        )}
        rightChildren={() => SURVEY_EDIT && (
          <IconButton
            id="save"
            isLoading={loading}
            onClick={handleOnClickSave}
            icon={<SaveIcon />}
            label="Save Survey"
            aria-label="Save Survey"
            small
          />
        )}
      >
        Edit Survey
      </Header>
      { survey && (
        <article className="main-content">
          <section id="survey-options">
            <section className="survey-options-row">
              <section className="input-container">
                <Select
                  id="survey-type"
                  label="Survey type"
                  disabled
                >
                  <option>{survey?.surveyType}</option>
                </Select>
              </section>
              <section className="input-container">
                <NumberInput
                  id="employees-number"
                  key="sent-to"
                  type="number"
                  min={0}
                  value={survey.sentTo}
                  disabled={!survey || !SURVEY_EDIT}
                  label="Number of employees"
                  onChange={handleOnChangeNumber('sentTo')}
                  financial
                />
              </section>
            </section>
            <section className="survey-options-row">
              <section className="input-container">
                <TextInput
                  id="from-date"
                  key="from-date"
                  type="date"
                  defaultValue={new Date(survey.fromDate).toISOString().substring(0, 10)}
                  disabled={!survey || !SURVEY_EDIT}
                  label="From date"
                  onBlur={handleOnChangeDate('fromDate')}
                />
              </section>
              <section className="input-container">
                <TextInput
                  id="to-date"
                  key="to-date"
                  type="date"
                  defaultValue={new Date(survey.toDate).toISOString().substring(0, 10)}
                  disabled={!survey || !SURVEY_EDIT}
                  label="To date"
                  onBlur={handleOnChangeDate('toDate')}
                  min={survey.fromDate}
                />
              </section>
            </section>
          </section>
          { CREATE_QUESTION && (
            <section id="company-questions-container">
              <header>
                <h3 className="title">Company questions</h3>
                <p className="subtitle">These are questions that are specific to your company and can help you get extra information from your employees.</p>
              </header>
              <QuestionsList
                company={company}
                selectedIds={defaultQuestions}
                onChange={handleOnChangeQuestions}
                isLoading={loading}
              />
            </section>
          )}
        </article>
      )}
    </section>
  );
}

export default SurveyDetail;
