import './styles.scss';

import React, { HTMLAttributes, MouseEventHandler, useState } from 'react';

import { Company } from '../../../../../types/Company';
import { IconButton, Menu, MenuItem, MoreIcon } from '@flotilla/component-library';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { addModal, removeModal } from '../../../../../reducers/modal';
import EditCustomerModal from '../../../../../components/Modal/EditCustomerModal';
import { CreateCustomerModal, InviteUserModal } from '../../../../../components/Modal';
import { useUserAccess } from '../../../../../context';
import { environment } from '../../../../../helpers/environment';
import isParentCompany from '../../../../../helpers/isParentCompany';
import { Link } from 'react-router-dom';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement>{
  data: Company;
  level?: number;
  ExpandButton?: React.ReactElement;
  onChange?: () => void;
};

const TableRow: React.FC<TableRowProps> = ({
  data,
  className = '',
  level = 0,
  ExpandButton,
  onClick,
  onChange = () => ({}),
  ...props
}) => {
  const dispatch = useAppDispatch();
  const env = environment();
  const { userAccess } = useUserAccess();
  const { EDIT_COMPANIES } = userAccess;
  const [showMenu, setShowMenu] = useState(false);
  const {
    id,
    identityCompanyId,
    name,
    mainContactName,
    mainContactEmail: email,
    companyType,
    product,
    consultantUsername
  } = data;

  const handleOnClickMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  const handleOnClickEdit: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'edit-customer',
      children: (
        <EditCustomerModal
          data={data}
          onClose={(change) => {
            change && onChange();
            dispatch(removeModal());
          }}
        />
      )
    }));
  }

  const handleOnClickAdd: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'add-customer',
      children: (
        <CreateCustomerModal
          parentCompany={{
            value: name,
            ...data
          }}
          onClose={(change) => {
            change && onChange();
            dispatch(removeModal());
          }}
        />
      )
    }));
  }

  const handleOnClickAddUser: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'create-user',
      children: (
        <InviteUserModal
          inviteCompanyId={identityCompanyId}
          onClose={() => dispatch(removeModal())}
          companyName={name}
        />
      )
    }));
  }

  const handleOnClickCopyLink: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    const supplierSurveyLink = new URL(`${env.employee.baseUrl}/supplier-survey`);
    supplierSurveyLink.searchParams.append('identityCompanyId', String(identityCompanyId));
    supplierSurveyLink.searchParams.append('companyName', name);
    navigator.clipboard.writeText(supplierSurveyLink.href);
    setShowMenu(false);
  }

  return (
    <tr key={id} className={`customer-list-item tree-level--${level} ${className}`} {...props}>
      <td id="name">
        {ExpandButton && ExpandButton}
        <Link className='title title-link' to={`/${id}`} onClick={(e) => e.stopPropagation()}>{name}</Link>
      </td>
      <td>{companyType?.label}</td>
      <td>{product?.label}</td>
      <td>{consultantUsername}</td>
      <td>{mainContactName}</td>
      <td id="email">
        <section>
          <a href={`mailto:${email}`} onClick={e => e.stopPropagation()} className="email" title={email}>{email}</a>
          { EDIT_COMPANIES && (
            <>
              <IconButton
                id="more-button"
                variant="ghost"
                aria-label="More"
                label="More"
                onClick={handleOnClickMore}
                icon={<MoreIcon variant='dark' />}
                title="More"
              />
              { showMenu && (
                <Menu id="more-menu" onClose={() => setShowMenu(false)}>
                  <MenuItem onClick={handleOnClickAddUser}>Invite user</MenuItem>
                  <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
                  { isParentCompany(companyType)  ? (
                    <MenuItem onClick={handleOnClickAdd}>Add Company</MenuItem>
                  ) : <></>}
                  { EDIT_COMPANIES && <MenuItem onClick={handleOnClickCopyLink}>Copy Supplier Survey link</MenuItem>}
                </Menu>
              )}
            </>
          )}
        </section>
      </td>
    </tr>
  );
}

export default TableRow;
