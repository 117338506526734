import './styles.scss';

import { Blocker } from 'react-router-dom';
import { ChangeEventHandler, MutableRefObject, useEffect, useRef, useState } from "react";
import { Accordion, AccordionContent, AccordionHeader, Card, IconButton, SaveIcon, Select, Spinner, Switch, TextInput, useAlert } from '@flotilla/component-library';

import { ReactComponent as CameraIcon } from '../../../assets/icons/Camera.svg';
import { ReactComponent as PersonIcon } from '../../../assets/icons/Person.svg';
import { COMPANY_SETTINGS_SUBTITLE } from '../../../assets/content/Subtitles';
import Header from "../../../components/HeaderV2";
import MultiCheckboxContainer, { CheckboxOption } from '../../../components/MultiCheckboxContainer';
import { useCompanyId, useUserAccess } from "../../../context";
import { useCompany, useCompanyLogo } from "../../../hooks";
import { CompanySettings, Sectors } from '../../../types/CompanySettings';
import { getCompanySettings, getCompliances, getDrivers, getSectors, getVehicleTypes, updateCompanyAndSettings } from '../../../api/CompanySettings';
import { POLICIES } from '../../../assets/content/Policies';
import SaveChangesModal from '../../../components/Modal/SaveChangesModal';
import { DataCenterOptions } from '../../../assets/content/DataCenter';
import {
    SETTINGS_BUSINESS_TRAVEL_TOOLTIP,
    SETTINGS_COMPANY_VEHICLES_TOOLTIP,
    SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP,
    SETTINGS_FINANCIAL_YEAR_TOOLTIP,
    SETTINGS_INBOUND_LOGISTICS_TOOLTIP,
    SETTINGS_INDUSTRY_TOOLTIP,
    SETTINGS_LOCATION_NAME_TOOLTIP,
    SETTINGS_PRIVATE_EQUITY_TOOLTIP,
    SETTINGS_SECTOR_TOOLTIP,
    SETTINGS_SUSTAINABILITY_TOOLTIP,
    SETTINGS_TENDER_TOOLTIP
} from '../../../assets/content/Tooltips';
import { Activities, FocusGroups } from '../../../types/DataPeriod';
import DayMonthInput from '../../../components/DayMonthInput';
import ActivityTooltip from '../Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';
import { Company } from '../../../types/Company';
import IntegrationCard from './IntegrationCard';

interface CompanySetupProps {};

const CompanySetup: React.FC<CompanySetupProps> = () => {
    const { addAlert } = useAlert();
    const { userAccess } = useUserAccess();
    const { EDIT, PE } = userAccess;
    const companyLogoInputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const companyId = useCompanyId();
    const [company, , updateCompany, , editCompany] = useCompany(companyId);
    const [companyLogo, setCompanyLogo] = useCompanyLogo(companyId);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [companySettings, setCompanySettings] = useState({
        dataCollectionPeriod: 1
    } as CompanySettings);
    const [hasCompanyVehicles, setHasCompanyVehicles] = useState(false);
    const [sectorId, setSectorId] = useState<number>();
    const [sectors, setSectors] = useState<Sectors>();
    const currentSector = sectorId ? sectors?.find(s => s.id === sectorId) : null;
    const [compliances, setCompliances] = useState<CheckboxOption[]>();
    const [drivers, setDrivers] = useState<CheckboxOption[]>();
    const [vehicleTypes, setVehicleTypes] = useState<FocusGroups>();
    const [updated, setUpdated] = useState(false);
    const canEdit = EDIT && !(PE && companyId !== "0");
    const canSave = currentSector && companySettings.industryId && companySettings.environmentalReportingPeriod?.length && canEdit;
    const [focusGroupIds, setFocusGroupIds] = useState<number[]>();
    const [activities, setActivities] = useState<Activities>();

    const biofuelFocusGroup = vehicleTypes?.find(vt => vt.shortName?.toLowerCase().includes('biofuel'));

    useEffect(() => {
        if(company && companySettings.companyId !== company.id) {
            Promise.all([
                // Load settings
                getCompanySettings(company.id)
                    .then(res => {
                        setCompanySettings({
                            ...res,
                            dataCollectionPeriod: 1
                        });

                        if(res.focusGroupIds?.length) {
                            setHasCompanyVehicles(true);
                            setFocusGroupIds(res.focusGroupIds);
                        }
                    })
                    .catch((e) => {
                        console.warn(e);
                        addAlert({ id: `Company Settings Get Failed`, type: "error", title: "Failed to retrieve company settings", content: "Failed to retrieve company settings, please refresh to try again." });
                    }),

                // Load sectors
                getSectors()
                    .then(res => {
                        setSectors(res);
                    })
                    .catch(() => {
                        addAlert({ id: `Sectors Get Failed`, type: "error", title: "Failed to retrieve sector options", content: "Failed to retrieve the available sector options, please refresh to try again." });
                    }),

                // Load compliance
                getCompliances()
                    .then(res => {
                        setCompliances(res.map(({ id, name }) => {
                            const policyInfo = POLICIES.find((item) => item.name === name);
                            return {
                                id,
                                label: name,
                                tooltipProps: {
                                    children: (
                                        <>
                                            <h4>{policyInfo?.fullName}</h4>
                                            <p>{policyInfo?.description}</p>
                                        </>
                                    )
                                }
                            }
                        }));
                    })
                    .catch(() => {
                        addAlert({ id: `Compliance Get Failed`, type: "error", title: "Failed to retrieve compliance options", content: "Failed to retrieve the available compliance options, please refresh to try again." });
                    }),
                
                // Load drivers
                getDrivers()
                    .then(res => {
                        setDrivers(res.map(({ id, name }) => { return { id, label: name } }));
                    })
                    .catch(() => {
                        addAlert({ id: `Company Drivers Get Failed`, type: "error", title: "Failed to retrieve company driver options", content: "Failed to retrieve the available company driver options, please refresh to try again." });
                    }),

                // Load Vehicle Types
                getVehicleTypes()
                    .then(res => {
                        setVehicleTypes(res.sort((a, b) => a.shortName === 'Other' ? 1 : (b.shortName === 'Other' ? -1 : 0)));
                        setActivities(res.flatMap(vt => vt.activities).sort((a, b) => (a.shortName ?? a.name).localeCompare((b.shortName ?? b.name))));
                    })
                    .catch(() => {
                        addAlert({ id: `Vehicle Types Get Failed`, type: "error", title: "Failed to retrieve available vehicle types", content: "Failed to retrieve the available vehicle type options, please refresh to try again." });
                    })
            ])
            .then(() => setIsLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    useEffect(() => {
        if(sectors && companySettings?.industryId) {
            setSectorId(sectors.find(s => s.industries.some(i => i.id === companySettings.industryId))?.id);
        }
    }, [companySettings, sectors, isLoading]);

    useEffect(() => {
        if(focusGroupIds === undefined && vehicleTypes && companySettings.activityIds) {
            var fullFocusGroups = vehicleTypes.filter(vt => vt.activities.some(a => companySettings.activityIds?.includes(a.id)));
            setFocusGroupIds(fullFocusGroups.map(fg => fg.id));
        }
    }, [vehicleTypes, companySettings, focusGroupIds]);

    const handleUpdateCompanySettings = (updatedSettings: CompanySettings) => {
        setCompanySettings(updatedSettings);
        setUpdated(true);
    }

    const handleLogoFileChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const fileUploaded = event.target.files && event.target.files[0];
        if (fileUploaded) {
            setCompanyLogo(fileUploaded);
        }
    };

    const handleEditAvatarClick = () => {
        if (canEdit) {
            companyLogoInputRef && companyLogoInputRef.current.click();
        }
    };

    const handleSave = async (navigateBlocker?: Blocker) => {
        if(!isLoading && !isSaving && canSave) {
            setIsSaving(true);

            var validActivityIds = companySettings.activityIds?.filter(a => focusGroupIds?.some(f => f === vehicleTypes?.find(v => v.activities?.some(c => c.id === a))?.id));

            await updateCompanyAndSettings(company, {...companySettings, activityIds: validActivityIds })
                .then(() => {
                    addAlert({ id: `Update Company Settings Success`, type: "success", title: "Successfully updated company settings" });
                    navigateBlocker?.proceed?.();
                    updateCompany();
                })
                .catch((error) => {
                    addAlert({ id: `Update Company Settings Failed`, type: "error", title: "Failed to update company settings", content: error.message ?? "Failed to update the company settings, please try again." });
                })
                .finally(() => {
                    setIsSaving(false);
                    setUpdated(false);
                });
        }
    };

    const handleCompanySettingsChange = <K extends keyof CompanySettings>(field: K, value: CompanySettings[K]) => {
        var newSettings = {...companySettings} as CompanySettings;
        newSettings[field] = value;

        if(field === 'conductBusinessTravel' && value === false)
            newSettings.employeeVehicles = false;

        if(field === 'produceProducts' && value === false)
            newSettings.intermediaryGoods = false;

        handleUpdateCompanySettings(newSettings);
    }

    const handleSectorChange = (id: number) => {
        setSectorId(id);
        handleCompanySettingsChange('industryId', undefined);
    }

    const handleMultiButtonChange = (collection: 'driverIds' | 'complianceIds' | 'activityIds' | 'dataCentreTypeIds', item: CheckboxOption, selected: boolean) => {
        var newSettings = {...companySettings};
        if(!newSettings[collection]) newSettings[collection] = [];
        var newIds = [...(newSettings[collection] || [])].filter((item) => item !== -1);

        if(selected) {
            newIds.push(item.id);

            if (collection === 'complianceIds' && item.id === -1 ) {
                newIds = [-1];
            }
        } else {
            newIds = newIds.filter(r => r !== item.id)
        }

        newSettings[collection] = newIds;
        handleUpdateCompanySettings(newSettings);
    }

    const handleSetFocusGroupsIds = (_focusGroupIds?: number[]) => {
        setFocusGroupIds(_focusGroupIds);
        handleUpdateCompanySettings({
            ...companySettings,
            focusGroupIds: _focusGroupIds
        });
    }

    const handleFocusGroupChange = (item: CheckboxOption, selected: boolean) => {
        if(selected) {
            handleSetFocusGroupsIds([...focusGroupIds ?? [], item.id]);
        } else {
            var newFocusGroups = focusGroupIds?.filter(fg => fg !== item.id);
            if(newFocusGroups?.length === 1 && newFocusGroups[0] === biofuelFocusGroup?.id) {
                newFocusGroups = [];
            }
            handleSetFocusGroupsIds(newFocusGroups);
        }
        setUpdated(true);
    }

    const handleActivitiesChange = (item: CheckboxOption, selected: boolean) => {
        if(item.label === "Biofuel") {
            handleFocusGroupChange(item, selected);
            return;
        }

        var matches = activities
            ?.filter(a => a?.shortName === item.label || (a?.shortName.split('(')[0].trim() === item.label))
            .map(a => a.id) ?? [];

        var newSettings = {...companySettings};
        newSettings.activityIds = [...(newSettings.activityIds ?? [])];

        if(selected) {
            // Add all matching Activities
            matches.forEach(m => {
                if(!newSettings.activityIds?.some(id => id === m))  {
                    newSettings.activityIds?.push(m);
                }
            })
        } else {
            // Remove all matching Activities
            newSettings.activityIds = newSettings.activityIds?.filter(id => !matches.some(m => m === id))
        }
        handleUpdateCompanySettings(newSettings);
    }

    

    const handleOnToggleCompanyVehicles = (checked: boolean = false) => {
        setHasCompanyVehicles(checked);
        !checked && handleSetFocusGroupsIds([]);
    }

    const handleOnChangeContactInformation = (key: keyof Company): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            editCompany({ [key]: event.currentTarget.value});
            setUpdated(true);
        }
    }
    
    return (
        <section id="customer-setup-page">
            <Header
                rightChildren={() => canEdit && (
                    <IconButton
                        icon={<SaveIcon />}
                        onClick={() => handleSave()}
                        isLoading={isLoading || isSaving}
                        disabled={!canSave}
                        small
                    />
                )}
                subtitle={COMPANY_SETTINGS_SUBTITLE}
            >
                Settings
            </Header>
            {isLoading || (companySettings?.industryId && !currentSector) ? <Spinner size='large' lightBackground/> :
            <main>
                <section id="column-1">
                    <Card id="basic-company-details">
                        <section id="edit-avatar" onClick={handleEditAvatarClick}>
                            { companyLogo ? (
                                <img
                                    id="company-logo"
                                    src={companyLogo}
                                    alt={`${company?.name} logo`}
                                />
                            ) : (
                                <PersonIcon
                                    aria-label="Person Icon"
                                    id="person-icon"
                                />
                            )}
                            <CameraIcon
                                aria-label="Camera Icon"
                                id="camera-icon"
                            />
                            <input
                                ref={companyLogoInputRef}
                                id="logo-file"
                                type="file"
                                onChange={handleLogoFileChange}
                                accept="image/*"
                                disabled={!canEdit}
                            />
                        </section>
                        <section id="name-container">
                            <p id="company-name">{company?.name}</p>
                            <TextInput
                                id="friendly-name-input"
                                label="Company friendly name"
                                tooltip={<ActivityTooltip alignment='left' tooltip={SETTINGS_LOCATION_NAME_TOOLTIP} />}
                                aria-label='Company friendly name'
                                value={companySettings.friendlyName || ""}
                                onChange={(e) => handleCompanySettingsChange('friendlyName', e.target.value)}
                                maxLength={50}
                                alertType={(companySettings.friendlyName?.length || 0) === 50 ? 'warning' : undefined}
                                alertMessage={(companySettings.friendlyName?.length || 0) === 50 ? 'The entered value is the maximum allowed for this input.' : undefined}
                                disabled={!canEdit}
                            />
                            <TextInput
                                id="company-url-input"
                                label="Company URL"
                                aria-label='Company url'
                                value={company.url || ""}
                                onChange={handleOnChangeContactInformation('url')}
                                maxLength={100}
                                disabled={!canEdit}
                            />
                        </section>
                    </Card>
                    <IntegrationCard />
                </section>
                <section id="column-2">
                    <Card className="section-container">
                        <Accordion className="accordion-container" open>
                            <AccordionHeader>
                                <h4>Main contact information</h4>
                            </AccordionHeader>
                            <AccordionContent>
                                <TextInput
                                    id="contact-name-input"
                                    label="Company contact name"
                                    aria-label='Company contact name'
                                    value={company.mainContactName || ""}
                                    maxLength={100}
                                    disabled={!canEdit}
                                    onChange={handleOnChangeContactInformation('mainContactName')}
                                />
                                <TextInput
                                    id="contact-email-input"
                                    label="Company contact email"
                                    aria-label='Company contact email'
                                    value={company.mainContactEmail || ""}
                                    maxLength={254}
                                    disabled={!canEdit}
                                    onChange={handleOnChangeContactInformation('mainContactEmail')}
                                    type="email"
                                    autoComplete='email'
                                />
                                <TextInput
                                    id="contact-number-input"
                                    label="Company contact number"
                                    aria-label='Company contact number'
                                    value={company.mainContactTel || ""}
                                    maxLength={30}
                                    disabled={!canEdit}
                                    onChange={handleOnChangeContactInformation('mainContactTel')}
                                    type="tel"
                                    autoComplete="tel"
                                />
                            </AccordionContent>
                        </Accordion>
                    </Card>
                    <Card className="section-container">
                        <Accordion className="accordion-container" open>
                            <AccordionHeader>
                                <h4>Key reporting information</h4>
                            </AccordionHeader>
                            <AccordionContent>
                                <section id="sector-operate">
                                    <label>
                                        What sector do you operate in?*
                                        <ActivityTooltip 
                                            alignment='left'
                                            position='bottom'
                                            tooltip={SETTINGS_SECTOR_TOOLTIP}
                                        />
                                    </label>
                                    <Select
                                        id="sectors"
                                        aria-label="What sector do you operate in?*"
                                        value={currentSector?.id || ""}
                                        onChange={(e) => handleSectorChange(parseInt(e.target.value))}
                                        disabled={!canEdit}
                                    >
                                        <option disabled value={undefined} />
                                        {sectors?.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                    </Select>
                                </section>
                                {currentSector && (
                                    <section>
                                        <label>
                                            What industry do you operate in?*
                                            <ActivityTooltip 
                                                alignment='left'
                                                position='bottom'
                                                tooltip={SETTINGS_INDUSTRY_TOOLTIP}
                                            />
                                        </label>
                                        <Select
                                            id="sub-sectors"
                                            aria-label="What industry do you operate in?*"
                                            value={companySettings.industryId || ""}
                                            onChange={(e) => handleCompanySettingsChange('industryId', parseInt(e.target.value))}
                                            disabled={!canEdit}
                                        >
                                            <option value={undefined} disabled={companySettings.industryId !== undefined} />
                                            {currentSector?.industries.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                        </Select>
                                    </section>
                                )}
                                <DayMonthInput
                                    id="year-end"
                                    label={{ 
                                        children: "What is your financial year end?",
                                        tooltip:
                                            <ActivityTooltip
                                                alignment='left'
                                                position='bottom'
                                                tooltip={SETTINGS_FINANCIAL_YEAR_TOOLTIP}
                                            />
                                    }}
                                    aria-label="What is your financial year end?"
                                    value={new Date(companySettings.financialYearEnd)}
                                    onChange={(date) => handleCompanySettingsChange('financialYearEnd', date || '')}
                                    disabled={!canEdit}
                                />
                                <DayMonthInput
                                    id="period-start-date"
                                    label={{
                                        children: "What is your environmental reporting period end?*",
                                        tooltip: 
                                            <ActivityTooltip
                                                alignment='left'
                                                position='bottom'
                                                tooltip={SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP}
                                            />
                                    }}
                                    aria-label="What is your environmental reporting period end?*"
                                    value={new Date(companySettings.environmentalReportingPeriod)}
                                    onChange={(date) => handleCompanySettingsChange('environmentalReportingPeriod', date || '')}
                                    disabled={!canEdit}
                                />
                            </AccordionContent>
                        </Accordion>
                    </Card>
                    <Card className="section-container">
                        <Accordion className="accordion-container" open>
                            <AccordionHeader>
                                <h4>Tell us more about the emissions relevant to your business</h4>
                            </AccordionHeader>
                            <AccordionContent>
                                <span className='produce-products-switch-container'>
                                    <label>Does your company manufacture, sell or produce products?</label>
                                    <Switch
                                        id="produces-products"
                                        checked={companySettings.produceProducts}
                                        aria-label='Does your company manufacture, sell or produce products?'
                                        onToggle={(value) => handleCompanySettingsChange('produceProducts', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                { companySettings.produceProducts && <span className='intermediary-goods-switch-container'>
                                    <label>Are any of the products intermediary goods?</label>
                                    <Switch
                                        id="produces-products"
                                        checked={companySettings.intermediaryGoods}
                                        aria-label='Are any of the products intermediary goods?'
                                        onToggle={(value) => handleCompanySettingsChange('intermediaryGoods', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>}
                                <span className='vehicles-switch-container'>
                                    <label>
                                        Do you have any company controlled or operated vehicles?
                                        <ActivityTooltip tooltip={SETTINGS_COMPANY_VEHICLES_TOOLTIP} position='bottom' alignment='left'/>
                                    </label>
                                    <Switch
                                        id="has-company-vehicles"
                                        checked={hasCompanyVehicles}
                                        aria-label='Do you have any company controlled or operated vehicles?'
                                        onToggle={handleOnToggleCompanyVehicles}
                                        disabled={!canEdit}
                                    />
                                </span>
                                {hasCompanyVehicles &&
                                    <MultiCheckboxContainer
                                        label="Which of the following types?"
                                        selected={focusGroupIds}
                                        options={vehicleTypes
                                            ?.filter(vt => vt !== biofuelFocusGroup)
                                            ?.map(({ id, name, shortName }) => { return { id, label: shortName ?? name } })}
                                        onChange={(item, selected) => handleFocusGroupChange(item, selected)}
                                        disabled={!canEdit}
                                    />}
                                {hasCompanyVehicles && (focusGroupIds?.length || 0) > 0 &&
                                    <MultiCheckboxContainer
                                        label="Which of the following fuel sources do these vehicles use?"
                                        selected={({id, label}) => ((label === "Biofuel" && focusGroupIds?.includes(id)) || (label !== "Biofuel" && companySettings.activityIds?.includes(id))) ?? false }
                                        options={
                                            [
                                                { id: biofuelFocusGroup?.id || -1, label: 'Biofuel' },
                                                ...activities
                                                    ?.filter((a, idx, list) =>
                                                        !a.name.startsWith('Well-to-tank') &&
                                                        a.focusGroupId !== biofuelFocusGroup?.id && 
                                                        focusGroupIds?.includes(a?.focusGroupId ?? 0))
                                                    .map(({ id, name, shortName }) => { return { id, label: shortName ?? name } })
                                                    .filter((a, idx, list) => list.findIndex(i => a.label.startsWith(i.label)) === idx) ?? []
                                            ]
                                        }
                                        onChange={(item, selected) => handleActivitiesChange(item, selected)}
                                        disabled={!canEdit}
                                    />}
                                {hasCompanyVehicles && focusGroupIds?.includes(biofuelFocusGroup?.id ?? -1) &&
                                    <MultiCheckboxContainer
                                        label="Which of the following Biofuels do these vehicles use?"
                                        selected={companySettings.activityIds}
                                        options={biofuelFocusGroup?.activities.map(({ id, shortName }) => { 
                                            return { id, label: shortName } 
                                        })
                                        .sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(item, selected) => handleActivitiesChange(item, selected)}
                                        disabled={!canEdit}
                                    />}
                                <span>
                                    <label>
                                        Do you conduct business travel?
                                        <ActivityTooltip tooltip={SETTINGS_BUSINESS_TRAVEL_TOOLTIP} position='bottom' />
                                    </label>
                                    <Switch
                                        id="business-travel"
                                        checked={companySettings.conductBusinessTravel}
                                        aria-label='Do you conduct business travel?'
                                        onToggle={(value) => handleCompanySettingsChange('conductBusinessTravel', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                {companySettings.conductBusinessTravel && 
                                <span>
                                    <label>Do employees use their own cars for business travel?</label>
                                    <Switch
                                        id="employee-cars"
                                        checked={companySettings.employeeVehicles}
                                        aria-label='Do employees use their own cars for business travel?'
                                        onToggle={(value) => handleCompanySettingsChange('employeeVehicles', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>}
                                <span>
                                    <label>Do you transport or post products to customers?</label>
                                    <Switch
                                        id="customer-shipping"
                                        checked={companySettings.shipProducts}
                                        aria-label='Do you transport or post products to customers?'
                                        onToggle={(value) => handleCompanySettingsChange('shipProducts', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                <span>
                                    <label>
                                        Do you have significant inbound logistics?
                                        <ActivityTooltip tooltip={SETTINGS_INBOUND_LOGISTICS_TOOLTIP} />
                                    </label>
                                    <Switch
                                        id="inbound-shipping"
                                        checked={companySettings.inboundLogistics}
                                        aria-label='Do you have significant inbound logistics?'
                                        onToggle={(value) => handleCompanySettingsChange('inboundLogistics', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                <MultiCheckboxContainer
                                    label="Where are your servers/data centres located?"
                                    selected={companySettings.dataCentreTypeIds}
                                    options={DataCenterOptions}
                                    onChange={(item, selected) => handleMultiButtonChange('dataCentreTypeIds', item, selected)}
                                    disabled={!canEdit}
                                />
                            </AccordionContent>
                        </Accordion>
                    </Card>
                    <Card className="section-container">
                        <Accordion className="accordion-container" open>
                            <AccordionHeader>
                                <h4>Tell us more about the nature of your business</h4>
                            </AccordionHeader>
                            <AccordionContent>
                                <MultiCheckboxContainer
                                    label="What are your drivers for taking sustainability action?"
                                    tooltip={
                                        <ActivityTooltip
                                            alignment='left'
                                            position='bottom'
                                            tooltip={SETTINGS_SUSTAINABILITY_TOOLTIP} />
                                    }
                                    selected={companySettings.driverIds}
                                    options={drivers}
                                    onChange={(item, selected) => handleMultiButtonChange("driverIds", item, selected)}
                                    disabled={!canEdit}
                                />
                                <MultiCheckboxContainer
                                    id="compliances"
                                    label="Which of the following regulations or frameworks would you like to comply with?*"
                                    aria-required={true}
                                    selected={companySettings.complianceIds}
                                    options={[
                                        ...(compliances || []),
                                        {
                                            id: -1,
                                            label: 'None'
                                        }
                                    ]}
                                    onChange={(item, selected) => handleMultiButtonChange("complianceIds", item, selected)}
                                    tooltip={<ActivityTooltip 
                                        alignment='left'
                                        tooltip={`Use our [policy checker](./supportguidance/policychecker) to understand any regulations relevant to your business. Other frameworks can be optionally selected`} />}
                                    disabled={!canEdit}
                                />
                                <span>
                                    <label>
                                        Are you backed by a Private Equity Investor?
                                        <ActivityTooltip tooltip={SETTINGS_PRIVATE_EQUITY_TOOLTIP} />
                                    </label>
                                    <Switch
                                        id="backed-by-pei"
                                        checked={companySettings.backedByPrivateEquityInvestor}
                                        aria-label='Are you backed by a Private Equity Investor?'
                                        onToggle={(value) => handleCompanySettingsChange('backedByPrivateEquityInvestor', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                {companySettings.backedByPrivateEquityInvestor && <TextInput
                                    id="private-equity-investor"
                                    label="What is the name of your Private Equity Investor?"
                                    aria-label='What is the name of your Private Equity Investor?'
                                    value={companySettings.privateEquityInvestorName || ""}
                                    maxLength={50}
                                    onChange={(e) => handleCompanySettingsChange('privateEquityInvestorName', e.target.value)}
                                    disabled={!canEdit}
                                />}
                                <span>
                                    <label>Are you listed on the London Stock Exchange?</label>
                                    <Switch
                                        id="tender-to-public"
                                        checked={companySettings.listedOnStockExchange}
                                        aria-label='Are you listed on the London Stock Exchange?'
                                        onToggle={(value) => handleCompanySettingsChange('listedOnStockExchange', value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                <span>
                                    <label>
                                        Do you tender to the public sector?
                                        <ActivityTooltip tooltip={SETTINGS_TENDER_TOOLTIP} />
                                    </label>
                                    <Switch
                                        id="tender-to-public"
                                        checked={companySettings.tenderToPublicSector}
                                        aria-label='Do you tender to the public sector?'
                                        onToggle={(value) => handleCompanySettingsChange("tenderToPublicSector", value ?? false)}
                                        disabled={!canEdit}
                                    />
                                </span>
                            </AccordionContent>
                        </Accordion>
                    </Card>
                </section>
            </main>}
            <SaveChangesModal
                condition={updated && canEdit}
                onSave={handleSave}
            />
        </section>
    );
};

export default CompanySetup;