import './styles.scss';

import React from 'react';
import { Accordion, AccordionContent, AccordionHeader, Label, Tooltip } from '@flotilla/component-library';

import { Activities, ActivityDataItem, ActivityNote, FocusGroup, FocusGroups } from '../../../../../../types/DataPeriod';
import DataPeriodActivity from './Activity';
import moneyFormatter from '../../../../../../helpers/moneyFormatter';
import RequiredMarker from '../../../../../../components/RequiredMarker';

interface DataPeriodTabContentProps {
  tabName?: string;
  className?: string;
  data?: FocusGroups;
  onChange?: (data: ActivityDataItem[]) => void;
  onChangeNote?: (data: ActivityNote) => void;
  openAccordions?: number[];
  onOpenAccordionsChange?: (ids: number[]) => void;
  secondLevel?: boolean;
  submitAttempt?: boolean;
  disabled?: boolean;
};

const DataPeriodTabContent: React.FC<DataPeriodTabContentProps> = ({
  tabName = '',
  className,
  data,
  onChange = () => {},
  onChangeNote = () => {},
  openAccordions,
  onOpenAccordionsChange = () => {},
  secondLevel = false,
  submitAttempt = false,
  disabled = false
}) => {
  const getTotalValue = (): string => {
    const value = data?.reduce((prev, item) => prev += item.activities.reduce((prev, value) => prev += value?.data?.find((item) => item.measure.name === '£')?.value || 0, 0), 0) || 0;
    return moneyFormatter(value, '£', true);
  }

  const getFocusGroupTotal = (focusGroup: FocusGroup): string => {
    const value = focusGroup.activities.reduce((prev, value) => prev += value?.data?.find((item) => item.measure.name === '£')?.value || 0, 0);
    return moneyFormatter(value, '£', true);
  }

  const getCompletedActivities = (activities: Activities): number => {
    return activities.filter((activity) => (activity.data?.find((item) => (item.value ?? -1) >= 0)?.value ?? -1) >= 0).length;
  }

  const isRequiredActivitiesCompleted = (activities: Activities): boolean => {
    const completed = activities.filter((activity) => activity.mandatory ? !activity.data?.some((item) => (item.value ?? -1) > -1) : false).length;
    return completed > 0;
  }

  const getCompleted = (): number => {
    let completed = 0;
    completed = data?.reduce((prev, item) => prev += getCompletedActivities(item.activities), 0) || 0;
    return completed;
  }

  const handleOnClickAccordionHeader = (id: number) => {
    return () => {
      let updatedOpenAccordions = [...openAccordions || []];
      if (updatedOpenAccordions.includes(id)) {
        updatedOpenAccordions = updatedOpenAccordions.filter((item) => item !== id);
      } else {
        updatedOpenAccordions.push(id);
      }
      onOpenAccordionsChange(updatedOpenAccordions);
    }
  }

  return (
    <section id="data-period-tab-content" className={`${secondLevel ? 'data-period-tab-content--second-level' : ''} ${className}`}>
      { !secondLevel && (
        <header>
          <section id="total">
            <h4>
              Total
            </h4>
            <p>{getCompleted()} of {data?.reduce((prev, value) => prev += value.activities.length, 0)} completed</p>
          </section>
          <section className="spend-total">
            <h4>Spend</h4>
            <p>{getTotalValue()}</p>
          </section>
        </header>
      )}
      { data?.map((item) => (
        <Accordion
          key={item.accordionId}
          className={`data-period-accordion data-period-accordion--${tabName.toLowerCase().replaceAll(" ", '-')}`}
          open={openAccordions?.includes(item.accordionId)}
        >
          <AccordionHeader id="accordion-header" onClick={handleOnClickAccordionHeader(item.accordionId)}>
            <section id="title">
              <section>
                <h4>
                  {item.name}
                </h4>
                {item.tooltip && <Tooltip tooltipText={item.tooltip}/>}
                { submitAttempt && isRequiredActivitiesCompleted(item.activities) &&
                  <RequiredMarker />
                }
              </section>
              <p>{getCompletedActivities(item.activities)} of {item.activities.length} completed</p>
            </section>
            <p>{getFocusGroupTotal(item)}</p>
            <Label id="estimate">Estimate</Label>
            <Label id="data-quality">Data Quality</Label>
          </AccordionHeader>
          <AccordionContent>
            {item.activities.map((activity) => (
              <DataPeriodActivity
                key={activity.id}
                tabName={tabName}
                data={activity}
                onChange={onChange}
                onChangeNote={onChangeNote}
                className='data-period-activity'
                submitAttempt={submitAttempt}
                disabled={disabled}
              />
            ))}
          </AccordionContent>
        </Accordion>
      ))}
    </section>
  );
}

export default DataPeriodTabContent;
