import './styles.scss';

import { FC, HTMLAttributes, useState } from 'react';
import { Avatar, Card, CrownIcon, IconButton, Menu, MenuItem } from '@flotilla/component-library';

import { User } from '../../../../../types/User';
import { getInitials } from '../../../../../helpers/getInitials';
import StatusTag from '../../../../../components/StatusTag';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { addModal, removeModal } from '../../../../../reducers/modal';
import { EditUserModal } from '../../../../../components/Modal';
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/More.svg';
import useUser from '../../../../../hooks/User/useUser';
import { useUserAccess } from '../../../../../context';
import { useParams } from 'react-router-dom';

interface UserCardProps extends HTMLAttributes<HTMLElement> {
  data: User;
  onChange?: () => void;
};

const UserCard: FC<UserCardProps> = ({
  className = "",
  data,
  onChange = () => ({}),
  ...props
}) => {
  const { userAccess } = useUserAccess();
  const { INVITE_USER } = userAccess;
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const {
    user,
    disableUser,
    removeInvite,
    resendInvite,
    enableUser
  } = useUser(data);
  const {
    fullName,
    email,
    department,
    roles,
    status
  } = user;

  const handleOnClickEdit = () => {
    dispatch(addModal({
      id: 'edit-user',
      children: (
        <EditUserModal
          data={data}
          onClose={() => {
            dispatch(removeModal());
            onChange();
          }}
        />
      )
    }));
  }

  const handleOnClickDisable = async () => {
    disableUser();
    setShowMenu(false);
  }

  const handleOnClickEnable = async () => {
    enableUser();
    setShowMenu(false);
  }

  const handleOnClickRevoke = async () => {
    await removeInvite();
    setShowMenu(false);
    onChange();
  }

  const handleOnClickResendInvite = () => {
    resendInvite();
    setShowMenu(false);
  }

  return (
    <Card className={`user-card ${className}`} {...props}>
      <header>
        <Avatar
          className="customer-avatar"
          imgSrcAlt={`${fullName} logo`}
          text={data.fullName ? getInitials(data) : undefined}
          badge={roles?.find((item) => item.name === "Admin") ? {
            children: <CrownIcon variant='dark' />,
            alignment: 'right',
            position: 'top',
            id: 'crown-container'
          } : undefined}
        />
        <section className="title-subtitle-container">
          <p className="title">{fullName || email}</p>
          <p className="subtitle">{department?.name}</p>
          {status === 'Active' ? '' : <StatusTag id="status" size='small'>{status}</StatusTag>}
        </section>
      </header>
      {(INVITE_USER || !companyId) && (
        <>
          <IconButton
            id="more-button"
            icon={<MoreIcon />}
            title="More"
            variant='ghost'
            onClick={() => setShowMenu(!showMenu)}
          />
          { showMenu && (
            <Menu id="more-menu" onClose={() => setShowMenu(false)}>
              {status !== 'Invited' ? (
                <>
                  <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
                  { status === 'Active' ? (
                    <MenuItem onClick={handleOnClickDisable}>Disable</MenuItem>
                  ) : (
                    <MenuItem onClick={handleOnClickEnable}>Enable</MenuItem>
                  )}
                </>
              ) : (
                <>
                  <MenuItem onClick={handleOnClickResendInvite}>Resend invite</MenuItem>
                  <MenuItem onClick={handleOnClickRevoke}>Cancel invite</MenuItem>
                </>
              )}
            </Menu>
          )}
        </>
      )}
    </Card>
  );
}

export default UserCard;
