import { Lookup, mapLookups } from "./Lookup";
import { mapUsers, User } from "./User";

export type Company = {
  heatingSourceDetails: string;
  sustainabilityTrainingDetails: string;
  isASubsiduaryDetails: string;
  isASubsiduary: string;
  travelManagementDetails: string;
  travelManagement: string;
  IsASubsidiariesDetails: string;
  addressCity: string;
  airCon: string;
  certificationsDetails: string;
  certifications: string;
  epcRatingDetails: string;
  financialYearEndDetails: string;
  cloudComputing: string;
  ethicalPensionDetails: string;
  carbonJourneyDistance: string;
  sustainabilityBoard: string;
  isASubsidiariesDetails: string;
  isASubsidiaries: string;
  renewTariffSwitch: string;
  elecUse: string;
  fuelUsage: string;
  SustainabilityTrainingDetails: string;
  SustainabilityTraining: string;
  sustainabilityProcurePolicy: string;
  sustainabilityPolicy: string;
  esosDetails: string;
  esos: string;
  secrDetails: string;
  secr: string;
  sustainabilityChanges: string;
  ethicalPension: string;
  dataServers: string;
  officeList: string;
  servicedOffices: string;
  rentedOffices: string;
  numberOffices: string;
  elecProvider: string;
  airConGasLeakDetails: string;
  airConGasLeak: string;
  AirCon: boolean;
  TravelManagementDetails: string;
  TravelManagement: string;
  changingFacilities: string;
  bikePark: string;
  cycleToWorkSchemeDetails: string;
  addresscity: string;
  generateOwnElecDetails: string;
  anythingElse: string;
  netZeroPlanYear: string;
  suppliersCertifiedDetails: string;
  suppliersCertified: string;
  netZeroPlanYearDetails: string;
  alreadySupportingEmployeesDetails: string;
  alreadyMitigateDetails: string;
  renewableTariffDetails: string;
  epcRating: string;
  takePartCarSchemeDetails: string;
  offerCarSchemeDetails: string;
  covidOfficeClosedDetails: string;
  covidOfficeClosed: boolean;
  haveEVChargersDetails: string;
  haveEVChargers: string;
  airConIssues: string;
  hasRecyclingPolicyDetails: string;
  hasRecyclingPolicy: string;
  generateOwnElec: string;
  renewableTariff: string;
  trackEnergyUsage: boolean;
  heatingSource: string;
  payOwnGasElec: string;
  movedProperties: string;
  movedPropertiesDetails: string;
  ownOrLeasePropertiesDetails: string;
  ownOrLeaseProperties: boolean;
  financialSoftwarePackage: string;
  externalProviderExpensesDetails: string;
  alreadyCompletingSteamlinedReportingDetails: string;
  meetsStreamlinedReportingDetails: string;
  id: number;
  name: string;
  parentID: number;
  parentId: number;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  mainContactName: string;
  mainContactTel: string;
  mainContactEmail: string;
  financialYearEnd: string;
  financeSoftwarePackage: string;
  ownsSubsiduaries: string;
  ownsSubsiduariesDetails: string;
  meetsStreamlinedReporting: string;
  alreadyCompletingSteamlinedReporting: string;
  externalProviderExpenses: string;
  offerCarScheme: string;
  takePartCarScheme: string;
  cycleToWorkScheme: string;
  nearestTrainStation: number;
  travelBreakdownPossible: boolean;
  haveTypeOfCarInfo: boolean;
  reason_CompetitiveAdv: number | null;
  reason_PR: number | null;
  reason_SaveMoney: number | null;
  reason_Morals: number | null;
  reason_EmpEngagement: number | null;
  reason_Mandatory: number | null;
  reason_FutureProof: number | null;
  reason_PressureSuppliers: number | null;
  reason_DebtFunding: number | null;
  reason_All: number | null;
  reason_NotSure: number | null;
  reason_Other?: any | null;
  carbonJourney: string;
  alreadyMitigate: string;
  alreadySupportingEmployees: boolean;
  dataCenterProvider: string;
  pensionProvider: string;
  bankName: string;
  companyGuid: string;
  fileID: number;
  updatedAt: string | null;
  createdAt: string;
  logoUrl: string | null;
  friendlyName: string | null;
  identityCompanyId: number;
  identityParentId: number;
  parentName?: string;
  companyClients?: CompanyClients;
  children?: Companies;
  companyType?: Lookup;
  product?: Lookup;
  companyTypeName?: string;
  productName?: string;
  url?: string;
  description?: string;
  consultantUserId?: number;
  consultantUser?: User;
  consultantUsername?: string;
};

export type CompanyClient = {
  id: number;
  allowAnonymous: boolean;
  client: Client;
  clientId: number;
  clientName: string;
  companyId: number;
}

export type Client = {
  id: number;
  clientId: string;
  clientName: string;
}

export type CompanyClients = CompanyClient[];

export type Companies = Company[];

export type CompanyQuestion = {
  id: number;
  question: string;
  answers: string;
};

export type FullCompanyQuestion = {
  id: number;
  companyId: number;
  slug: string;
  questionText: string;
  type: string;
  companyQuestionAnswers: CompanyQuestionAnswer[]
};

export type CompanyQuestionAnswer = {
  slug: string;
  answerText: string;
}

export type CompanyQuestions = CompanyQuestion[];
export type FullCompanyQuestions = FullCompanyQuestion[];

export const mapCompanies = (data: Partial<Company>[]): Companies => {
  return data.map((item) => {
    const consultantUser = item.consultantUser ? mapUsers([item.consultantUser as unknown as { [key: string]: unknown; }])[0] : undefined;
    return {
      ...item,
      companyType: item.companyType ? mapLookups([item.companyType as { [key: string]: unknown; }])[0] : undefined,
      companyTypeName: item.companyType?.name || '',
      product: item.product ? mapLookups([item.product as { [key: string]: unknown; }])[0] : undefined,
      productName: item.product?.name || '',
      parentID: item?.parentId || 0,
      consultantUser,
      consultantUsername: consultantUser?.fullName || consultantUser?.username || 'Unassigned',
    }
  }) as Companies;
}