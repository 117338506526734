import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Avatar, Menu, TextInput } from '@flotilla/component-library';
import { User, Users } from '../../types/User';
import { useCompanyId, useUserAccess } from '../../context';
import { getUsers } from '../../api/Company';
import { addModal, removeModal } from '../../reducers/modal';
import { useAppDispatch } from '../../helpers/hooks';
import { useCompany } from '../../hooks';
import { getInitials } from '../../helpers/getInitials';
import { InviteUserModal } from '../Modal';

export type Option = {
  value: string;
  label?: string;
  [key: string]: any;
}

export type Options = Option[];

interface AssignedToInputProps {
  id?: string;
  label?: string;
  selected?: User;
  className?: string;
  onChange?: (user: User) => void;
  disabled?: boolean;
};

const AssignedToInput: React.FC<AssignedToInputProps> = ({
  id,
  selected,
  label,
  className = "",
  onChange = () => ({}),
  disabled = false,
  ...props
}) => {
  const { userAccess } = useUserAccess();
  const { ADMIN, NET_ZERO_PLAN_EDIT, MANAGE_USER, CARBON_REDUCTION_PLAN_PRODUCT_ACCESS } = userAccess;
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(selected ? selected.fullName : undefined);
  const [showList, setShowList] = useState(false);
  const [selectedOption, setSelectedOption] = useState<User | undefined>(selected);
  const [initialOptions, setInitialOptions] = useState<Users>();
  const [options, setOptions] = useState<Users>();
  const [company] = useCompany(companyId);

  const handleGetUsers = () => {
    if (companyId) {
      setIsLoading(true);
      companyId && getUsers(companyId)
        .then((res) => setInitialOptions(res))
        .finally (() => setIsLoading(false));
    }
  }

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    if (searchTerm) {
      setOptions(initialOptions?.filter((item) => JSON.stringify(Object.values(item)).toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setOptions(initialOptions);
    }
  }, [initialOptions, searchTerm]);

  useEffect(() => {
    setSearchTerm(selectedOption ? selectedOption.fullName : undefined);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  useEffect(() => {
    setOptions([
      ...selectedOption ? [selectedOption] : [],
      ...initialOptions ? initialOptions.filter((option) => option.id !== selectedOption?.id) : []
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showList]);

  useEffect(() => {
    handleGetUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchTerm(event.currentTarget.value);
  }

  const handleOnClick = (user: User) => {
    return () => {
      setSelectedOption(user);
      onChange(user);
      setShowList(false);
    }
  }

  const handleInviteUserClick = () => {
    dispatch(addModal({
      id: 'create-user',
      children: (
        <InviteUserModal
          inviteCompanyId={company.identityCompanyId}
          onClose={() => dispatch(removeModal())}
          companyName={company.name}
        />
      )
    }));
  }

  return (
    <article
      id={id}
      className={`assigned-to-input ${className}`}
      {...props}
    >
      <section id="selected-user">
        <Avatar
          className="avatar"
          text={(selectedOption && ! showList) ? getInitials(selectedOption) : undefined}
        />
        <TextInput
          id={`${id}-search`}
          className="search"
          onChange={handleSearchChange}
          defaultValue={searchTerm}
          label={label}
          onFocus={() => setShowList(true)}
          disabled={isLoading || !NET_ZERO_PLAN_EDIT}
          placeholder='Assign to'
          autoComplete="off"
        />
      </section>
      <Menu
        id={`${id}-list`}
        className={`list-container list-container--${showList ? "show" : "hide"}`}
        onClose={() => setShowList(false)}
      >
        <>
          { options?.map((option, index) => (
            <section key={index} className="user-list-item" onClick={handleOnClick(option)}>
              <UserComponent {...option} />
            </section>
          ))}
          { (ADMIN || (MANAGE_USER && !CARBON_REDUCTION_PLAN_PRODUCT_ACCESS)) && (
            <section className="user-list-item" onClick={handleInviteUserClick}>
              <p>Invite user</p>
            </section>
          )}
        </>
      </Menu>
    </article>
  );
}

const UserComponent: React.FC<User> = (user) => {
  const {
    firstName,
    lastName,
    username
  } = user;
  return (
    <>
      <Avatar className="avatar" text={getInitials(user)}/>
      <section>
        <p>{firstName} {lastName}</p>
        <p>{username}</p>
      </section>
    </>
  )
}

export default AssignedToInput;
