import React, { useEffect, useState } from 'react';

import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@flotilla/component-library';

import Surveys from './Surveys';
import CarbonReports from './CarbonReports';
import NetZeroPlan from './NetZeroPlan';
import { CompanyIdContext, CompanyTypeContext, IdentityCompanyIdContext, useUserAccess } from '../../context';
import CompanyHome from './Home';
import SupportGuidance from './SupportGuidance';
import Training from './Training';
import Analytics from './Analytics';
import CompanySetup from './CompanySetup';
import AccountsMapping from './AccountsMapping';
import Locations from './Locations';
import Data from './Data';
import Onboarding from './Onboarding';
import Users from '../Users';
import { getCompanySettings } from '../../api/CompanySettings';
import { useCompany } from '../../hooks';
import useNetZeroPlanId from '../../hooks/NetZeroPlan/useNetZeroPlanId';
import { Lookup } from '../../types/Lookup';
import { getAdminChildUserRoleAccess, getChildUserRoleAccess } from '../../constants';

const Company: React.FC = () => {
  const navigate = useNavigate();
  const { companyId: initialCompanyId = "0" } = useParams();
  const [company] = useCompany(initialCompanyId);
  const [companyId, setCompanyId] = useState<string>(initialCompanyId);
  const [identityCompanyId, setIdentityCompanyId] = useState<string>("0");
  const [companyType, setCompanyType] = useState<Lookup>();
  const [industryId, setIndustryId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const { userAccess, initialUserAccess, updateUserAccess } = useUserAccess();
  const { ADMIN, EDIT, CREATE, CARBON_REDUCTION_PLAN_PRODUCT_ACCESS, INVITE_USER } = userAccess;
  const [netZeroPlanId, setNetZeroCompanyId] = useNetZeroPlanId(companyId as unknown as number);

  useEffect(() => {
    companyId && setNetZeroCompanyId(companyId as unknown as number);
  }, [companyId, setNetZeroCompanyId]);

  useEffect(() => {
    setLoading(true);
    setCompanyId(initialCompanyId);
    getCompanySettings(initialCompanyId)
      .then((res) => {
        setIndustryId(res.industryId);
        if (!res.industryId && !ADMIN && EDIT && initialCompanyId === "0") {
          navigate('onboarding', { replace: true });
        }
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCompanyId]);

  useEffect(() => {
    setCompanyType(company?.companyType);
    setIdentityCompanyId(String(company?.identityCompanyId || 0));

    if (ADMIN) {
      updateUserAccess(getAdminChildUserRoleAccess(initialUserAccess, company?.product?.label || ''));
    } else if (!ADMIN && (initialUserAccess.PE || initialUserAccess.LICENSOR_ACCESS) && companyId !== "0") {
      updateUserAccess(getChildUserRoleAccess(initialUserAccess, company?.product?.label || ''));
    } else {
      updateUserAccess(initialUserAccess);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  if (loading && company === undefined) {
    return (<Spinner />);
  }
  
  return (
    <CompanyIdContext.Provider value={companyId}>
      <IdentityCompanyIdContext.Provider value={identityCompanyId}>
        <CompanyTypeContext.Provider value={companyType || { id: 0, label: '' }}>
          { !industryId && !ADMIN && EDIT && initialCompanyId === "0" ? (
            <Onboarding />
          ) : (
            <Routes>
              <Route index element={<CompanyHome />} />
              <Route path="settings/*">
                <Route index element={<CompanySetup />} />
                <Route path="mappings/:integrationId" element={<AccountsMapping />} />
              </Route>
              { !CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && <Route path="surveys/*" element={<Surveys />} /> }
              <Route path="reportsandcertificates/*" element={<CarbonReports />}/>
              { !CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && <Route path="analytics" element={<Analytics />} /> }
              <Route path="netzeroplan/*" element={<NetZeroPlan />} />
              <Route path="analytics" element={<Analytics />} />
              { (netZeroPlanId || CREATE) && (
                <Route path="netzeroplan/*" element={<NetZeroPlan />} />
              )}
              <Route path="data/*" element={<Data />} />
              <Route path="locations/*" element={<Locations />} />
              <Route
                path="reports/*"
                element={<Navigate to={window.location.pathname.replace("/reports", "/reportsandcertificates")} replace />}
              />
              <Route path="training/*" element={<Training />} />
              <Route path="supportguidance/*" element={<SupportGuidance />} />
              { INVITE_USER ? (
                <>
                  <Route path="manageusers/*" element={<Users />} />
                  <Route path="*" element={<Navigate to={`/${companyId}`} />} />
                </>
              ) : (
                <>
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              )}
            </Routes>
          )}
        </CompanyTypeContext.Provider>
      </IdentityCompanyIdContext.Provider>
    </CompanyIdContext.Provider>
  );
}

export default Company;
