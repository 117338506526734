import './styles.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { Card, IconButton, SaveIcon, Stat, StatLabel, StatValue, Tag, TextArea, Tooltip, useAlert } from '@flotilla/component-library';

import { Action } from '../../../../../../types/Action';
import getRatingIcon from '../../../../../../helpers/getRatingsIcon';
import { updateAction } from '../../../../../../api/netZeroPlan';
import { useAppSelector } from '../../../../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../../../../reducers/user';
import { convertMarkdown } from '../../../../../../helpers/convertMarkdown';
import { CO_BENEFITS_TOOLTIP } from '../../../../../../assets/content/Tooltips';
import { useCompanyId, useUserAccess } from '../../../../../../context';

interface DetailsTabProps {
  data?: Action;
  onChange?: (action: Action) => void;
};

const DetailsTab: React.FC<DetailsTabProps> = ({ data, onChange = () => ({}) }) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const { userAccess } = useUserAccess();
  const { NET_ZERO_PLAN_EDIT } = userAccess;
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [notes, setNotes] = useState('');
  const [savingNotes, setSavingNotes] = useState(false);
  const [allTags, setAllTags] = useState<string[]>([]);

  useEffect(() => {
    const updatedNotes = data?.netZeroPlanAction?.notes || '';
    let updatedTags = [];
    data?.impactAreaName && updatedTags.push(data.impactAreaName);
    data?.focusGroupName && updatedTags.push(data.focusGroupName);
    data?.activityNames && updatedTags.push(...data.activityNames);
    data?.departments && updatedTags.push(...data.departments.map((value) => value.name));
    data?.actionTags && updatedTags.push(...data.actionTags);
    data?.tags && updatedTags.push(...data.tags);
    data?.scopeId && updatedTags.push(`Scope ${data.scopeId}`);
    data?.scopeCategoryNames && updatedTags.push(...data.scopeCategoryNames);
    setNotes(updatedNotes);
    setAllTags(updatedTags);
  }, [data]);

  if (!data) {
    return null
  };

  const {
    description,
    costSavingsPotential,
    carbonSavingsPotential,
    coBenefitsPotential,
    netZeroPlanAction,
    sdgs
  } = data;

  const handleSaveClick = async () => {
    if (netZeroPlanId && netZeroPlanAction) {
      setSavingNotes(true);
      await updateAction({
          ...netZeroPlanAction,
          notes
      }, companyId)
      .then((res) => {
        const updatedData = {...data};
        updatedData.netZeroPlanAction = res;
        onChange(updatedData);
      })
      .catch((error) => {
        addAlert({
          id: `Updating Action Notes ${companyId}`,
          type: 'error',
          title: 'Error Updating Action Notes',
          content: error.toString(),
        });
        console.log("Error, when updating Action Notes", error);
      })
      .finally(() => setSavingNotes(false)); 
    }
  }

  const getDescription = (text: string) => {
    const description = text.split('\n');
    return (
      <p id="description">
        {description.map((item, index) =>
          <Fragment key={index}>
            {convertMarkdown(item)}
            <br/>
          </Fragment>
        )}
      </p>
    )
  }

  return (
    <article id="details-tab-content">
      {description && getDescription(description)}
      <section id="savings-stats">
        <h4>Potential Benefits</h4>
        <Stat className={`savings-stat savings-stat--${costSavingsPotential}`}>
          <StatValue>{getRatingIcon(costSavingsPotential, "money")}</StatValue>
          <StatLabel>Cost Savings</StatLabel>
        </Stat>
        <Stat className={`savings-stat savings-stat--${carbonSavingsPotential}`}>
          <StatValue>{getRatingIcon(carbonSavingsPotential, "cloud")}</StatValue>
          <StatLabel>Carbon Savings</StatLabel>
        </Stat>
        <Stat className={`savings-stat savings-stat--${coBenefitsPotential}`}>
          <StatValue>{getRatingIcon(coBenefitsPotential, "hand")}</StatValue>
          <StatLabel>Corporate Co-Benefits</StatLabel>
          <Tooltip
            id="co-benefits-tooltip"
            tooltipText={CO_BENEFITS_TOOLTIP}
          />
        </Stat>
      </section>
      { sdgs && sdgs.length ? (
        <section id="sdgs">
          <h4>Sustainable Development Goals</h4>
          { sdgs.map((sdg, index) => (
            <Card
              key={index}
              className="sdg"
            >
              <header>
                <img
                  className="sdg-icon"
                  alt={sdg.label}
                  src={(sdg?.imgURL || '') as string}
                />
                <p>{sdg.label}</p>
              </header>
              { sdg.sdts?.map((sdt) => (
                <p><b>{sdt.targetNumber}</b> {sdt.label}</p>
              ))}
            </Card>
          ))}
        </section>
      ) : null}
      { netZeroPlanAction &&
        <section id="notes">
          <TextArea
            id="notes"
            label='Notes'
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            placeholder='Use this box to enter any notes you have on this action'
            disabled={!NET_ZERO_PLAN_EDIT}
            maxLength={5000}
          />
          { NET_ZERO_PLAN_EDIT && (netZeroPlanAction.notes || '') !== notes &&
            <IconButton
              id="save-notes"
              icon={<SaveIcon />}
              onClick={handleSaveClick}
              isLoading={savingNotes}
              label='Save'
              small
            />
          }
        </section>
      }
      <section id="all-tags">
        <h4>Tags</h4>
        <section id="tags">
          {allTags.map((value, index) => (
            <Tag key={index} className="tag">{value}</Tag>
          ))}
        </section>
      </section>
    </article>
  )
}
export default DetailsTab;
