import { Chart } from "../types/Chart";
import groupBy from "./groupBy";
import sortChartYears from "./sortChartYears";

const mapImpactAreaChartData = (chart: Chart) => {
  let prevTotal = 0;

  const data = groupBy(
    chart.data.sort((a, b) => (b.xLabel < a.xLabel ? 1 : -1)),
    'xLabel'
  )
    .map((dataItem) => {
      let dataObject: { [key: string]: string | number } = {
        key: dataItem[0].xLabel,
      };
      let total = 0;

      chart.legend.forEach((item) => {
        const value = dataItem.find((value) => value.legend === item)?.value || 0.0000001;
        dataObject = {
          ...dataObject,
          [item]: value
        };
        total += value;
      });

      dataObject = {
        ...dataObject,
        tCO2eTotal: total,
        difference: prevTotal > 0 ? Math.round(((total - prevTotal) / prevTotal) * 100) : 0,
      }

      prevTotal = total;
      return dataObject;
    })
    .sort(sortChartYears);
  
  return data;
}

export default mapImpactAreaChartData;