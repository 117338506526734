import { useCallback, useEffect, useState } from "react";

import { getCountries } from "../../api/Lookup";
import { Lookups } from "../../types/Lookup";

const useCountries = () => {
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState<Lookups>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    getCountries()
      .then(setCountries)
      .finally(() => setLoading(false));;
  }, []);

  const refresh = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countries,
    refresh,
    loading
  };
}

export default useCountries;
