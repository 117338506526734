import './styles.scss';

import React, { ChangeEventHandler } from 'react';
import { Address, Select } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../types/CreateWizard';
import useCountries from '../../../../../../hooks/Lookup/useCountries';

const LocationCountrySection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const { countries } = useCountries();

  const handleOnChangeCountry: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.currentTarget.value;
    const country = countries?.find((item) => item.id === Number(value));
    onChange({ address: {
      ...values?.["address"],
      country: country?.label,
      countryId: country?.id
    }});
  }

  return (
    <section id="location-country-section" className={className}>
      { countries && (
        <Select key={values?.['address']?.['countryId']} id="country-select" className="country-select" onChange={handleOnChangeCountry} value={values?.['address']?.['countryId'] || 0}>
          <option value={0} disabled>Choose a Country</option>
          { countries.map((item) => (
            <option value={item.id}>{item.label}</option>
          ))}
        </Select>
      )}
    </section>
  );
}

export default LocationCountrySection;
