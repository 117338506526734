import './styles.scss';

import { FC } from 'react';
import { LocationPinIcon, Tag, TagProps } from '@flotilla/component-library';

import { Location } from '../../types/Location';
import { colorIsDark } from '../../helpers/colorIsDark';

interface LocationTagProps extends Omit<TagProps, "children"> {
  location: Location;
};

const LocationTag: FC<LocationTagProps> = ({className, location, ...props}) => {
  const {
    name,
    colorHex = '#E2E4E3'
  } = location;

  return (
    <Tag
      {...props}
      className={`tag location-tag ${className}`}
      style={{ backgroundColor: colorHex, color: colorIsDark(colorHex) ? '#FFF' : '#000' }}
      title={name}
      icon={<LocationPinIcon id="location-pin" variant={colorIsDark(colorHex) ? 'light' : 'dark'} />}
    >
      {name}
    </Tag>
  )
};

export default LocationTag;
