import './styles.scss';

import React, { ReactElement, useState } from 'react';
import { Avatar, IconButton } from '@flotilla/component-library';

import { ReactComponent as BurgerIcon } from '../../assets/icons/Burger.svg';
import Logo from '../../assets/images/Logo.png';
import { SideNavItemProps } from './SideNavItem';
import { classNames } from '../../helpers/classNames';
import getInitialsFromName from '../../helpers/getInitialsFromName';

interface SideNavProps {
  id?: string;
  className?: string;
  children?: (ReactElement<SideNavItemProps> | boolean)[] | ReactElement<SideNavItemProps> | boolean;
  companyName?: string;
  username?: string;
  onLogoClick?: () => void;
  onUserIconClick?: () => void;
  bottomChildren?: (ReactElement<SideNavItemProps> | boolean)[] | ReactElement<SideNavItemProps> | boolean;
}

export const SideNav: React.FC<SideNavProps> = ({
  id,
  className = "",
  children,
  username,
  onUserIconClick,
  onLogoClick,
  bottomChildren
}) => {
  const [isClosed, setIsClosed] = useState(false);

  const toggleClosed = () => {
    setIsClosed(!isClosed);
  };

  return (
    <nav
      id={id}
      className={classNames([
        'side-nav',
        `side-nav--${isClosed ? 'closed' : 'open'}`,
        className
      ])}
    >
      <section id="logo-toggle">
        <IconButton
          id="toggle-button"
          variant='ghost'
          icon={<BurgerIcon />}
          onClick={toggleClosed}
          small
        />
        <img
          src={Logo}
          className="flotilla-logo"
          alt="Flotilla Logo"
          onClick={onLogoClick}
        />
      </section>

      <section id="main-items" className="nav-items">
        {children}
      </section>

      <section id="bottom-items" className="nav-items">
        {bottomChildren}
        <section className="avatar-user-name">
          <Avatar
            id="avatar"
            imgSrcAlt="User Image"
            text={getInitialsFromName(username || '')}
            onClick={onUserIconClick}
          />
          <span className="user-name">{username}</span>
        </section>
      </section>
    </nav>
  );
};
