import './styles.scss';
import { ReactComponent as TickIcon } from '../../../../assets/icons/Tick.svg';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/More.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/Error.svg';
import { AddIcon, Card, IconButton, Label, Menu, MenuItem, Spinner, useAlert } from '@flotilla/component-library';
import ActivityTooltip from '../../Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';
import { SETTINGS_DATA_INTEGRATION_TOOLTIP } from '../../../../assets/content/Tooltips';
import { useEffect, useRef, useState } from 'react';
import { DataIntegrations, DataIntegrationType } from '../../../../types/DataIntegrations';
import { useRootfiLink } from 'rootfi-react-sdk';
import { createDataIntegration, getDataIntegrations } from '../../../../api/DataIntegration';
import { useCompanyId, useUserAccess } from '../../../../context';
import { useNavigate } from 'react-router-dom';
import IntegrationSuccessModal from '../IntegrationSuccessModal/IntegrationSuccessModal';
import ConfirmIntegrationDeleteModal from '../ConfirmIntegrationDeleteModal/ConfirmIntegrationDeleteModal';
import { addModal, removeModal } from '../../../../reducers/modal';
import { useAppDispatch } from '../../../../helpers/hooks';
import { useCompany } from '../../../../hooks';

export type IntegrationCardProps = {}

const IntegrationCard: React.FC<IntegrationCardProps> = () => {
    const { addAlert } = useAlert();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userAccess } = useUserAccess();
    const { EDIT, PE } = userAccess;
    const companyId = useCompanyId();
    const [company] = useCompany(companyId);
    const canEdit = EDIT && !(PE && companyId !== "0");
    const [dataIntegrations, setDataIntegrations] = useState<DataIntegrations>([]);
    const [showIntegrationMenu, setShowIntegrationMenu] = useState(-1);
    const [inviteLinkId, setInviteLinkId] = useState<string>();
    const [pendingInvite, setPendingInvite] = useState(false);
    const inviteLinkProcessedRef = useRef(false); 

    const { isReady, closeLink, openLink } = useRootfiLink({
        environment: "global",
        inviteLinkId: inviteLinkId,
        onSuccess: () => handleRootFiComplete(),
        onExit: () => handleRootFiComplete(),
    });    

    useEffect(() => {
        companyId && reloadDataIntegrations();
    }, [companyId]);

    useEffect(() => {
        if(isReady && inviteLinkId) {
            inviteLinkProcessedRef.current = false;
            openLink(inviteLinkId);
        }
    }, [isReady, inviteLinkId]);

    const handleAccountingLink = () => {
        setPendingInvite(true);
        createDataIntegration(company.id, 2)
            .then((res) => openAuthFlow(res))
            .catch(() => {
                addAlert({ id: `Create Data Integration Post Failed`, type: "error", title: "Failed to create data integration", content: "Failed to add a new data integration, please refresh to try again." });
            });
    }

    const handleIntegrationMenuClick = (id: number) => {
        if(showIntegrationMenu === id) {
            setShowIntegrationMenu(-1);
        } else {
            setShowIntegrationMenu(id);
        }
    }

    const openAuthFlow = (url: string, existingId?: number) => {
        existingId && setDataIntegrations(dataIntegrations.filter(dI => dI.id !== existingId));
        setInviteLinkId(url.split("=")[1]);
        setPendingInvite(false);
    }

    const handleRootFiComplete = () => {
        if (!inviteLinkProcessedRef.current) {
            inviteLinkProcessedRef.current = true;
            reloadDataIntegrations(true);
            closeLink();
        }
    }

    const reloadDataIntegrations = (configure: boolean = false) => {
        return getDataIntegrations(parseInt(companyId))
            .then((res) => {
                if(configure) {
                    var newIntegration = res.find(i => !dataIntegrations.some(dI => dI.id === i.id));
                    
                    if(newIntegration && !newIntegration.requiresAuthorization) {
                        dispatch(addModal({
                            id: "integration-confirm-modal",
                            children: <IntegrationSuccessModal 
                                integration={newIntegration}
                                onClose={handleModalClose} />
                        }))
                    } else {
                        addAlert({ 
                            id: `Data Integrations Create Failed`,
                            type: "error",
                            title: "Data connection incomplete",
                            content: `Failed to create the data connection, ${newIntegration ? "please click the warning icon to retry." : "please try again."}`
                        });
                    }
                }

                setDataIntegrations(res);
                if(res.some(r => r.syncing)) {
                    setTimeout(() => { reloadDataIntegrations(); }, 10000);
                }
            })
            .catch(() => {
                addAlert({ id: `Data Integrations Get Failed`, type: "error", title: "Failed to retrieve data integrations", content: "Failed to retrieve the existing company data integrations, please refresh to try again." });
            })
            .finally(() => {
                setInviteLinkId(undefined);
            })
    }

    const handleModalClose = () => { 
        dispatch(removeModal());
    }

    const handleDeleteClick = (id: number) => {
        dispatch(addModal({
            id: "delete-integration-modal",
            children: <ConfirmIntegrationDeleteModal 
                dataIntegrationId={id}
                companyId={company.id}
                onClose={handleModalClose}
                onDelete={() => { handleModalClose(); reloadDataIntegrations(); }}
            />
        }))
    }

    return <Card id="integration-details">
        <section className='data-integration-section'>
            <Label 
                subtitle='Pop ups might be blocked by your browser, watch for the warning appearing in your navigation bar.'
                tooltip={
                    <ActivityTooltip 
                        alignment='left'
                        tooltip={SETTINGS_DATA_INTEGRATION_TOOLTIP}
                    />
                }
            >
                <h4>Data Integrations</h4>
            </Label>
            {
                inviteLinkId || pendingInvite ? <section className='pending-auth-section'>
                    <Spinner
                        className="loading-spinner"
                        lightBackground
                    />
                    <span>Authorization in progress...</span>
                </section> :
            <>
                {dataIntegrations.map((dI, index) => (
                    <span key={index} className='data-integration-row'>
                        <label>{dI.name}</label>
                        {!dI.requiresAuthorization && dI.syncing ?
                            <p className="sync-text">Syncing in progress...</p> :
                            <IconButton
                                variant='ghost'
                                icon={dI.requiresAuthorization ? 
                                    <ErrorIcon stroke='#ff4040' fill='#ff4040' /> 
                                    : <TickIcon />}
                                onClick={() => { EDIT && openAuthFlow(dI.authorizationUrl, dI.id) }}
                                disabled={!canEdit}
                                small
                            />
                        }
                        {EDIT ? <IconButton 
                            variant='ghost'
                            icon={<MoreIcon />}
                            onClick={() => handleIntegrationMenuClick(dI.id)}
                            disabled={!canEdit}
                            small
                        /> : null}
                        { EDIT && showIntegrationMenu === dI.id && (
                            <Menu
                                id="list-item-menu"
                                onClose={() => setShowIntegrationMenu(-1)}>
                                {dI.requiresAuthorization || dI.syncing ? <></> : <MenuItem onClick={() => { navigate(`mappings/${dI.id}`, { state: { dataIntegration: dI } }) }}>Mappings</MenuItem>}
                                <MenuItem onClick={() => { openAuthFlow(dI.authorizationUrl, dI.id) }}>Reconnect</MenuItem>
                                <MenuItem onClick={() => handleDeleteClick(dI.id)}>Remove</MenuItem>
                            </Menu>
                        )}
                    </span>
                ))}
                {dataIntegrations.length < Object.keys(DataIntegrationType).length / 2 && <span className='data-integration-row'>
                    <label>Link accounting software</label>
                    <IconButton 
                        icon={<AddIcon small />} 
                        onClick={handleAccountingLink}
                        isLoading={inviteLinkId !== undefined || pendingInvite}
                        disabled={!canEdit}
                        small
                    />
                </span>}
            </>}
        </section>
    </Card>;
}

export default IntegrationCard;