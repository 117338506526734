import './styles.scss';

import React, { MouseEventHandler } from 'react';
import { TreeTable, TreeTableItem, type Header as HeaderType } from '@flotilla/component-library';

import TableRow from './TableRow';
import { Companies, Company } from '../../../../types/Company';
import { useUserAccess } from '../../../../context';

const headers = [
  {
    key: "name",
    label: "Name",
    fixed: true
  },
  {
    key: "companyTypeName",
    label: "Type",
  },
  {
    key: "productName",
    label: "Product",
  },
  {
    key: "consultantUsername",
    label: "Consultant",
    optional: true
  },
  {
    key: "mainContactName",
    label: "Contact Name",
    optional: true
  },
  {
    key: "mainContactEmail",
    label: "Contact Email",
    optional: true
  },
] as HeaderType[];

interface TableViewProps {
  className?: string;
  data?: Companies;
  loading?: boolean;
  onClick?: (company: Company) => void;
  onChange?: () => void;
};

const TableView: React.FC<TableViewProps> = ({
  className = "",
  data,
  onClick = () => ({}),
  onChange = () => ({}),
  loading = false,
}) => {
  const { userAccess } = useUserAccess();
  const { ADMIN } = userAccess;

  const handleClickAction = (company: Company): MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(company);
    }
  }

  const getData = (): TreeTableItem[] => {
    if (ADMIN) {
      const treeTableData: TreeTableItem[] = [];
      const parentData = data?.filter((item) => !item.parentID);
      let childData = data?.filter((item) => item.parentID);
      
      parentData?.forEach((item) => {
        const itemChildData = childData?.filter((childItem) => childItem.parentID === item.id);
        treeTableData.push({
          ...item,
          children: (itemChildData || []) as unknown as TreeTableItem[]
        } as unknown as TreeTableItem);
      });
      
      return treeTableData;
    }

    return data as unknown as TreeTableItem[];
  }

  return (
    <section className={className}>
      <TreeTable
        id="customer-list-table"
        isLoading={loading}
        stickyHeader
        headers={headers}
        data={getData()}
        rowComponent={(item, level, ExpandButton) => {
          return (
            <TableRow
              key={(item as unknown as Company).id}
              data={item as unknown as Company}
              onClick={handleClickAction(item as unknown as Company)}
              level={level}
              ExpandButton={ExpandButton}
              onChange={onChange}
            />
          )
        }}
      />
    </section>
  );
}

export default TableView;
